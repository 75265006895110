import './PaginatedTable.css';
import '../../styles/Shared.css';
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';

import IsNullOrEmpty from '../../utilities/IsNullOrEmpty';

function PaginatedTable(props) {

  const options = {
    noRowsPerPage: true,
  };

  function getButtonStyle() {
    return IsNullOrEmpty(props.buttonLabel) ?
      'InvisibleCollapse' :
      'neo_qv_btn';
  }

  const conditionalRowStyles = [
    {
      when: row => row.status && row.status !== 'active',
      style: {
        //backgroundColor: 'rgba(230, 230, 230, 0.5)',  // Light green background
        color: 'gray',
      },
    },
    {
      when: row => row.status === 'active',
      style: {
        //backgroundColor: 'rgba(255, 255, 255, 1)',  
        color: 'black'
      },
    },
  ];

  return (
    <div>
      <div className="PaginatedTableHeaderBar">
        {props.icon && <div className='PaginatedTableHeaderIcon'>
          {props.icon}
        </div>
        }
        {props.name && <label className='PaginatedTableHeaderLabel'>
          {props.name}
        </label>
        }
        <div className='Spacer'></div>
        {
          props.specialButton
        }

        {props.buttonLabel && !props.specialButton && <a
          className={getButtonStyle()}
          onClick={props.onButtonClick}>
          {props.buttonLabel}
        </a>}

      </div>
      <DataTable
        theme="default" // create a new theme
        pagination
        paginationComponentOptions={options}
        //onRowClicked={ props.onRowClicked}
        columns={props.columns}
        data={props.data}
        pointerOnHover={false} 
        // defaultSortField="Active"  // Optional: set default sorting field
        // defaultSortAsc={true} 
        conditionalRowStyles={conditionalRowStyles}/>
    </div>
  );
}

function DateSort(aField, bField) {
  const dateA = new Date(aField);
  const dateB = new Date(bField);

  if (dateA > dateB) { return 1; }
  if (dateB > dateA) { return -1; }
  return 0;
}

function EmptyIsZeroSort(aField, bField) {
  if (IsNullOrEmpty(aField)) { return -1; }
  if (IsNullOrEmpty(bField)) { return 1; }

  if (aField > bField) { return 1; }
  if (bField > aField) { return -1; }
  return 0;
}

export { PaginatedTable, DateSort, EmptyIsZeroSort };

