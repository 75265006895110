import { FiSearch } from 'react-icons/fi';
import {PaginatedTable, DateSort} from '../PaginatedTable/PaginatedTable';
import {MdErrorOutline} from "react-icons/md";

function AlertsTable(props)
{
  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        return DateSort(rowA.date, rowB.date);
      },
      width: '200px'
    },
    {
      name: 'Type',
      selector: row => row.type,
      sortable: true,
      width: '150px'
    },
    {
      name: 'Issue',
      selector: row => row.issue,
      sortable: true,
      grow: 2
    },
    {
      name: 'User(s)',
      selector: row => getUsers(row),
      sortable: true,
      width: '150px'
    },
    {
      name: '',
      selector: (row) => <a className="neo-icon icon-link icon-link-hover" 
                            onClick={ () => props.onRowClicked(row) }
                            href='#'>
                            <FiSearch  size={18} />
                          </a> ,
      width: '50px'
    },
  ];

  function getUsers(row)
  {
    return row.type === "Conflict" ?
      row.original_user_name + ", " + row.user_name:
      row.user_name;
  }

  return (
    <PaginatedTable
      name="Alerts"
      icon={<MdErrorOutline size={25}/>}
      data={props.alerts}
      columns={columns}
      onRowClicked={props.onRowClicked}/>
  )
}

export default AlertsTable;