import { json, useParams } from 'react-router-dom';
import { LuBaby } from "react-icons/lu";

import { FeedingSessionCard } from '../../components/Cards/FeedingSessionCard';
import { CommentsCard } from '../../components/Cards/CommentsCard';
import { useGetPatient } from '../../components/CustomHooks/useGetPatient';
import { Loading } from '../../components/Loading/Loading';
import { AuthContext } from '../../auth';
import { useContext, useEffect, useState } from 'react';
import { Roles } from '../../data_layer/repositories/Users/UserRoles';
import FeedingsTable from '../../components/Tables/FeedingsTable';
import { useGetBiomarkersListByPatientId } from '../../components/CustomHooks/useGetBiomarkersListByPatientId';
import { Button } from 'react-bootstrap';
import { elements } from 'chart.js';
import { BiomarkersCard } from '../../components/Cards/BiomarkersCard';
import { PatientFullDetailsCard } from '../../components/Cards/PatientFullDetailsCard';
import { PatientContext } from '../../data_layer/context/Patient/PatientContext';

export const PatientDetails = () => {

    const {patient_id: patientIdFromParams} = useParams();

    const { patients } = useContext(PatientContext) || [];

    const patientId = patients?.[0]?.id || patientIdFromParams;

    const { user } = useContext(AuthContext);

    const { patient, isLoading } = useGetPatient(patientId);

    const { biomarkers, getBiomarkers } = useGetBiomarkersListByPatientId(patientId);

    const [feedingTimestampsForComparison, setFeedingTimestampsForComparison] = useState([]);

    const [currentFeedingTimestamp, setCurrentFeedingTimestamp] = useState();

    useEffect(() => {
        getBiomarkers();
    }, []);

    return (
        <div className="neo_container">
            {
                isLoading ? <Loading isLoading={isLoading} />
                    : <>
                        {patient && user.role === Roles.Clinician && <>

                            {/* <MaturationCurveCard patient={patient}/> */}

                            {
                                patients.length === 1 && <BiomarkersCard
                                    patient={patient}
                                    biomarkers={biomarkers}
                                    setCurrentFeedingSelected={setCurrentFeedingTimestamp}
                                    feedingTimestampsForComparison={feedingTimestampsForComparison}
                                    setFeedingTimestampsForComparison={setFeedingTimestampsForComparison} />
                            }


                            {
                                patients.length < 2 && <PatientFullDetailsCard
                                    index={0}
                                    biomarkers={biomarkers}
                                    setCurrentFeedingTimestamp={setCurrentFeedingTimestamp}
                                    isComparingFeedings={feedingTimestampsForComparison?.length > 0}
                                    isComparingPatients={patients.length > 1} />
                            }


                            {
                                feedingTimestampsForComparison.map((timestamp) => (
                                    <PatientFullDetailsCard
                                        index={0}
                                        key={timestamp}
                                        feedingTimestamp={timestamp}
                                        biomarkers={biomarkers}
                                        setCurrentFeedingTimestamp={() => { }}
                                        isComparingFeedings={true} />
                                ))
                            }

                            {
                                patients.length > 1 && patients.map((patient, index) => (
                                    <PatientFullDetailsCard
                                        index={index}
                                        biomarkers={biomarkers}
                                        setCurrentFeedingTimestamp={() => { }}
                                        isComparingFeedings={false}
                                        isComparingPatients={true} />
                                ))
                            }
                        </>}




                        {patient && user.role === Roles.NeoneurAdmin && <>

                            <div className='neo_card_container neo_ut_mt_24_mob'>

                                <FeedingsTable
                                    data={patient.feedings}
                                    facility={patient.facility_name}
                                    patient={patient} />
                            </div>

                        </>}
                    </>
            }
        </div>
    )
}
