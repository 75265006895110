import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaRegUserCircle } from 'react-icons/fa';  // Using react-icons for profile icon

export const ProfileDropdown = ({ user, facility, logout }) => {

  const [show, setShow] = useState(false);

  const handleToggle = (isOpen, event, metadata) => {
    setShow(!show);
  };

  return (
    <div className="navbar-profile-dropdown">
      {/* Profile Icon */}
      <Dropdown show={show} onToggle={handleToggle} align="end">
        <Dropdown.Toggle as="span" className="profile-icon">
          <FaRegUserCircle size={28} style={{ color: 'var(--neo-shades-purple)' }} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/* Profile Header */}
          <div className="profile-header">
            <div className="profile-placeholder">
              <FaRegUserCircle size={70} style={{ color: 'var(--neo-shades-purple)' }} />
            </div>
          </div>

          {/* Profile Body */}
          <div className="profile-body">
            <h6 className="text-capitalize">{`${user.user_name.toLowerCase()}`}</h6>
            <p>{`${user.email}`}</p>
            <p className="text-capitalize">{`${user.role.replace("-", " ").toLowerCase()}`}</p>
            <p>{facility.name}</p>
          </div>

          <Dropdown.Divider />

          {/* Dropdown Links */}
          <Dropdown.Item href="#">
            About
          </Dropdown.Item>
          <Dropdown.Item href="#">
            Help Center
          </Dropdown.Item>

          <Dropdown.Divider />


          {/* Footer Links */}
          <Dropdown.Item href="https://neoneur.com/privacy/" target="_blank">
            Privacy Policy
          </Dropdown.Item>
          <Dropdown.Item href="https://neoneur.com/terms/" target="_blank">
            Terms of Use
          </Dropdown.Item>


          <Dropdown.Divider />


          <Dropdown.Item href="#" onClick={logout}>
            Logout
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
