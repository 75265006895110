import { useEffect, useState } from "react";
import GetFacilities from "../../data_layer/repositories/Facilities/GetFacilities";
import GetUsers from "../../data_layer/repositories/Users/GetUsers";
import GetUsersByFacility from "../../data_layer/repositories/Users/GetUsersByFacility";

export const useGetUsers = (facility_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers();
    }, [facility_id])
    
    const getUsers = async () => {
        setIsLoading(true);
        let response = [];
        if (facility_id)
            response = await GetUsersByFacility(facility_id);
        else
            response = await GetUsers();
        setUsers(response);
        setIsLoading(false);
    }

    const reloadData = () => {
        getUsers();
    }

    return {
        isLoading,
        users,
        reloadData,
    }
}
