import { useEffect, useReducer } from "react";
import { AuthContext } from "./AuthContext"
import { authReducer } from "./authReducer";

import { types } from '../types/types';
import { Auth } from "aws-amplify";
import { MillisOnMinute } from "../../utilities/Constants";
import LocalStorage from "../../utilities/Storage";

const init = () => {
    const user = JSON.parse(LocalStorage.getItem('user'));
    const facility = JSON.parse(LocalStorage.getItem('facility'));
    // const lastInteractionTime = 100;//LocalStorage.getItem('lastInteractionTime') ;

    return {
        logged: !!user,
        user: user,
        facility: facility,
    }
}



export const AuthProvider = ({ children }) => {

    const [authState, dispatch] = useReducer(authReducer, {}, init);

    const login = (user = {}, facility = {}) => {

        const action = { type: types.login, payload: { user, facility } };

        LocalStorage.setItem('user', JSON.stringify(user));
        LocalStorage.setItem('facility', JSON.stringify(facility));
        LocalStorage.setItem('lastInteractionTime', Date.now());
        
        LocalStorage.setItem('lastTimeoutSession', Date.now() + facility.timeout_session * MillisOnMinute);

        dispatch(action)
    }

    const logout = async () => {

        // Cognito logout
        try {
            Auth.signOut();
        }
        catch (error) {
            //
        }

        // delete LocalStorage
        LocalStorage.removeItem('user');
        LocalStorage.removeItem('facility');
        LocalStorage.clear()
        const action = { type: types.logout };
        dispatch(action);
    }

    const saveFacility = (facility) => {
        // Remove previuos values
        LocalStorage.removeItem('facility');

        // Save the current facility
        LocalStorage.setItem('facility', JSON.stringify(facility));
    }

    const getFacility = () => {
        const facility = JSON.parse(LocalStorage.getItem('facility'));
    }


    return <AuthContext.Provider value={{
        ...authState,

        // Methods
        login,
        logout,
        saveFacility,

    }}>
        {children}
    </AuthContext.Provider>
}