import React from 'react'
import { Button, Modal } from 'react-bootstrap';

export const TimeOutModal = ({showModal, handleContinue, handleLogout, remainingTime}) => {
    return (
        <Modal show={showModal} onHide={handleContinue}>
            <Modal.Header closeButton>
                <Modal.Title>Your session is about to expire</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Your session will time out due to inactivity. 
            </Modal.Body>

            <Modal.Footer>
                <Button className='ModalSecondaryButton' onClick={ handleLogout }>Log out</Button>
                <Button className='ModalPrimaryButton' onClick={ handleContinue }>Continue session</Button>
            </Modal.Footer>

        </Modal>
        
    );
}
