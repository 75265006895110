import { PostApi, ResetUserPasswordPath } from "../../apis/Api";

async function ResetUserPassword(user_id){

  const payload = {
    user_id
  }

  const response = await PostApi(ResetUserPasswordPath, payload)
    .then(response => response.json())
    .then(output => {
      return output.message === "SUCCESS"
    });


  return response;

}

export default ResetUserPassword;