import IsNullOrEmpty from "../../../utilities/IsNullOrEmpty";

export const InputValidationErrorStates = {
  NoError: 'noError',
  RequiredAndMissing: 'requiredAndMissing',
  NeedsToBeNumeric: 'needsToBeNumeric',
  NeedsToBeDecimal: 'needsToBeDecimal',
  NotAvailable: 'notAvailable',
  AlreadyRegistered: 'alreadyRegistered',
  MustBeDifferent: 'mustBeDifferent',
  IncorrectEmailFormat: 'incorrectEmailFormat',
  IncorrectUsernameFormat: 'incorrectUsernameFormat',
  MustBeEqual: 'mustBeEqual',
  IncorrectPasswordFormat: 'incorrectPasswordFormat',
  PasswordMustBeDifferent: 'passwordMustBeDifferent',
  IncorrectMinLength: 'incorrectMinLength',
  IncorrectMaxLength: 'incorrectMaxLength',
  IncorrectMinValue: 'incorrectMinValue',
  IncorrectMaxValue: 'incorrectMaxValue',
  NotAlphaNumeric: 'notAlphanumeric',
  InvalidPhoneNumber: 'InvalidPhoneNumber'
}

export function GetInputValidationErrorText(errorState, parameterName, minValue, maxValue, minLength, maxLength) {

  switch (errorState) {
    case InputValidationErrorStates.RequiredAndMissing:
      return parameterName + " is required.";
    case InputValidationErrorStates.NeedsToBeNumeric:
      return parameterName + " can only contain numbers.";
    case InputValidationErrorStates.NeedsToBeDecimal:
      return parameterName + " can only contain a number with one decimal. Format ##.#";
    case InputValidationErrorStates.NotAvailable:
      return "This " + parameterName + " is not available.";
    case InputValidationErrorStates.AlreadyRegistered:
      return "This " + parameterName + " is already registered.";
    case InputValidationErrorStates.MustBeDifferent:
      return parameterName + " must be different.";
    case InputValidationErrorStates.IncorrectEmailFormat:
      return "Email format is: example@domain.com";
    case InputValidationErrorStates.IncorrectUsernameFormat:
      return "This username is not found";
    case InputValidationErrorStates.MustBeEqual:
      return parameterName + " must be equal to NEW PASSWORD."
    case InputValidationErrorStates.IncorrectPasswordFormat:
      return "Incorrect password format"
    case InputValidationErrorStates.PasswordMustBeDifferent:
      return "Your password must be different from your temporary password."
    case InputValidationErrorStates.IncorrectMinLength:
      return `${parameterName} min length is ${minLength} characters`;
    case InputValidationErrorStates.IncorrectMaxLength:
      return `${parameterName} max length is ${maxLength} characters`;
    case InputValidationErrorStates.NotAlphaNumeric:
      return parameterName + " must be alphanumeric";
    case InputValidationErrorStates.IncorrectMinValue:
      return `Minimum value is ${minValue}`;
    case InputValidationErrorStates.IncorrectMaxValue:
      return `Max value is ${maxValue}`;
    case InputValidationErrorStates.InvalidPhoneNumber:
      return `Phone number format is: (123) 456-7890`
    case InputValidationErrorStates.NoError:
    default:
      return "";
  }
}

export function IsErrorState(errorState) {

  if (IsNullOrEmpty(errorState)) {
    return false;
  }

  return errorState !== InputValidationErrorStates.NoError;
}

export function CheckIsMissing(input) {
  return IsNullOrEmpty(input) ?
    InputValidationErrorStates.RequiredAndMissing :
    InputValidationErrorStates.NoError;
}

export function CheckIsMissingAndNumeric(input) {
  if (IsNullOrEmpty(input)) {
    return InputValidationErrorStates.RequiredAndMissing;
  }

  if (/^\d+$/.test(input) === false) {
    return InputValidationErrorStates.NeedsToBeNumeric;
  }

  return InputValidationErrorStates.NoError;
}

export function CheckIsMissingNumericMinMaxValue(input, minValue, maxValue) {
  if (IsNullOrEmpty(input)) {
    return InputValidationErrorStates.RequiredAndMissing;
  }

  if (/^\d+$/.test(input) === false) {
    return InputValidationErrorStates.NeedsToBeNumeric;
  }

  if (minValue && input < minValue) {
    return InputValidationErrorStates.IncorrectMinValue;
  }

  if (maxValue && input > maxValue) {
    return InputValidationErrorStates.IncorrectMaxValue;
  }

  return InputValidationErrorStates.NoError;
}


export function CheckIsMissingDecimalMinMaxValue(input, minValue, maxValue) {
  if (IsNullOrEmpty(input)) {
    return InputValidationErrorStates.RequiredAndMissing;
  }

  if (/^-?\d+(\.\d{1})?$/.test(input) === false) {
    return InputValidationErrorStates.NeedsToBeDecimal;
  }

  if (minValue && input < minValue) {
    return InputValidationErrorStates.IncorrectMinValue;
  }

  if (maxValue && input > maxValue) {
    return InputValidationErrorStates.IncorrectMaxValue;
  }

  return InputValidationErrorStates.NoError;
}

export function CheckIsMissingAndLength(input, minLength, maxLength) {
  if (IsNullOrEmpty(input))
    return InputValidationErrorStates.RequiredAndMissing;

  if (minLength && input?.length < minLength) {
    return InputValidationErrorStates.IncorrectMinLength;
  }

  if (maxLength && input?.length > maxLength) {
    return InputValidationErrorStates.IncorrectMaxLength;
  }

  return InputValidationErrorStates.NoError;
}

export function CheckIsNumeric(input) {
  // if (IsNullOrEmpty(input)){
  //   return InputValidationErrorStates.NoError; 
  // }


  if (/^\d+$/.test(input) === false) {
    return InputValidationErrorStates.NeedsToBeNumeric;
  }

  return InputValidationErrorStates.NoError;
}

export function CheckIsRealPhoneNumber(phoneNumber) {
  if (IsNullOrEmpty(phoneNumber))
    return InputValidationErrorStates.RequiredAndMissing;
  if (/^\(\d{3}\) \d{3}-\d{4}$/.test(phoneNumber) === false)
    return InputValidationErrorStates.InvalidPhoneNumber;

  return InputValidationErrorStates.NoError;
}

export function CheckIsNumericAndMinMaxValue(input, minValue, maxValue) {

  if (/^\d+$/.test(input) === false) {
    return InputValidationErrorStates.NeedsToBeNumeric;
  }

  if (minValue && input < minValue) {
    return InputValidationErrorStates.IncorrectMinValue;
  }

  if (maxValue && input > maxValue) {
    return InputValidationErrorStates.IncorrectMaxValue;
  }

  return InputValidationErrorStates.NoError;
}

export function CheckHasMinLength(input, minLength) {
  return (input?.length < minLength) ? InputValidationErrorStates.IncorrectMinLength : InputValidationErrorStates.NoError
}

export function CheckHasMaxLength(input, maxLength) {
  return (input && input?.length > maxLength) ? InputValidationErrorStates.IncorrectMaxLength : InputValidationErrorStates.NoError
}

export function CheckIsRealEmail(input) {

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(input) ? InputValidationErrorStates.NoError : InputValidationErrorStates.IncorrectEmailFormat
}

export function CheckIsValidPassword(input, minLength, maxLength) {

  // Regular expression patterns to match an uppercase, lowercase letter, a digit, and a special character
  const uppercasePattern = /[A-Z]/;
  const lowercasePattern = /[a-z]/;
  const digitPattern = /[0-9]/;
  const specialPattern = /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/;

  // Check if the input meets all the criteria
  const hasUppercase = uppercasePattern.test(input);
  const hasLowercase = lowercasePattern.test(input);
  const hasDigit = digitPattern.test(input);
  const hasSpecial = specialPattern.test(input);
  const isLongEnough = input?.length >= minLength && input?.length <= maxLength


  return hasUppercase && hasLowercase && hasDigit && hasSpecial && isLongEnough
    ? InputValidationErrorStates.NoError
    : InputValidationErrorStates.IncorrectPasswordFormat;
}

export function CheckIsAlphaNumeric(input) {
  const alphaNumericPattern = /^[a-zA-Z0-9]+$/;
  return alphaNumericPattern.test(input)
    ? InputValidationErrorStates.NoError
    : InputValidationErrorStates.NotAlphaNumeric
}

export function CheckNameIsCorrect(input, minLength, maxLength) {
  if (!input)
    return InputValidationErrorStates.RequiredAndMissing;

  if (input?.length < minLength)
    return InputValidationErrorStates.IncorrectMinLength;

  if (input?.length > maxLength)
    return InputValidationErrorStates.IncorrectMaxLength;

  return InputValidationErrorStates.NoError
}

export function CheckLoginIdIsCorrect(input, minLength, maxLength) {

  if (input?.length < minLength)
    return InputValidationErrorStates.IncorrectMinLength;

  if (input?.length > maxLength)
    return InputValidationErrorStates.IncorrectMaxLength;

  return CheckIsAlphaNumeric(input);
}

export function CheckIsValidPinCode(input, minLength, maxLength) {

  if (!input)
    return InputValidationErrorStates.RequiredAndMissing;

  if (input?.length < minLength)
    return InputValidationErrorStates.IncorrectMinLength;

  if (input?.length > maxLength)
    return InputValidationErrorStates.IncorrectMaxLength;

  return CheckIsNumeric(input);
}

export function HasErrors(errorStates) {
  return errorStates.some((errorState) => errorState !== InputValidationErrorStates.NoError);
}