import { PostApi, CreateANewUser } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";
import EncryptPasscode from "../../../utilities/EncryptPasscode";

async function AddUser(
  editedUser,
  setResultMessage,
  refreshAction)
{

  const passcode = EncryptPasscode(
    editedUser.passcode_before_encrypt,
    editedUser.login_id.toUpperCase());

  const payload = 
  {
    user: {
      name: editedUser.name,
      login_id: editedUser.login_id,
      passcode: passcode,
      password: editedUser.password,
      phone: editedUser.phone,
      user_role: editedUser.role,
      email: editedUser.email,
      facility_id: editedUser.facility_id
    }
  }

  await PostApi(CreateANewUser, payload)
  .then(response => response.json())
  .then(output => WriteResultMessage(output, setResultMessage))
  .then(refreshAction);
}
export default AddUser;