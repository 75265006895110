import './ValidatedFieldEntry.css';
import '../../styles/Shared.css'
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";

import {useState} from 'react';

import { IsErrorState, GetInputValidationErrorText } from '../Modals/UpdateEntityModals/InputValidation';
import CheckIcon from '../Icons/CheckIcon';
import DashIcon from '../Icons/DashIcon';


function ValidatedFieldEntryWithRules(props) {

  const [hidePassword, setHidePassword] = useState(props.isPassword === true);
  
  // Requirements
  const [minLength, setMinLength] = useState(false);
  const [oneUpperCase, setOneUpperCase] = useState(false);
  const [oneLowerCase, setOneLowerCase] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneSpecialChar, setOneSpecialChar] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);


  
  function toggleHidePassword()
  {
    setHidePassword(!hidePassword);
  }

  function showPasswordIcon(targetHidePasswordState)
  {
    if (props.isPassword !== true)
    {
      return 'InvisibleCollapse';
    }

    if (hidePassword === targetHidePasswordState)
    {
      return 'ShowPasswordIcon'
    }
    return 'InvisibleCollapse';
  }

  function handleOnChange(event) {
    const value = event.target.value;

    const uppercasePattern = /[A-Z]/;
    const lowercasePattern = /[a-z]/;
    const digitPattern = /[0-9]/;
    const specialPattern = /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/;

    const hasUppercase = uppercasePattern.test(value);
    const hasLowercase = lowercasePattern.test(value);
    const hasNumber = digitPattern.test(value);
    const hasSpecialChar = specialPattern.test(value);
    const hasMinLength = value.length >= 8;

    setOneUpperCase(hasUppercase);
    setOneLowerCase(hasLowercase);
    setOneNumber(hasNumber);
    setOneSpecialChar(hasSpecialChar);
    setMinLength(hasMinLength);

    
    const isValid = (hasUppercase && hasLowercase && hasNumber && hasSpecialChar && hasMinLength);

    props.inputChanged(value);
    props.isValidPassword(isValid);
  }

  return (
    <div>
      <Row className='AddEntityModalContainer'>
        <div>
          <label className="ValidatedFieldLabel">{props.labelName}</label>
          <label className={props.required === true ? 'RequiredStar' : 'InvisibleCollapse'}>*</label>
        </div>
        <span>
          <input className={IsErrorState(props.errorState) ? 'FieldEntryError' : 'FieldEntry'}
            // disabled={() => props.getTextEntryDisabled()}
            placeholder={props.placeholder}
            type={hidePassword === true ? "password": "text"}
            maxLength={props.entryMaxLength}
            value={props.value ?? ""}
            onChange={handleOnChange}/>

          <AiOutlineEye
            className={showPasswordIcon(true)}
            onClick={() => toggleHidePassword()}
            size={20}/>

          <AiOutlineEyeInvisible
            className={showPasswordIcon(false)}
            onClick={() => toggleHidePassword()}
            size={20}/>
        </span>
        
        <label className={IsErrorState(props.errorState) ? 'InputValidationErrorMessage' : 'InvisibleCollapse'}>
          {GetInputValidationErrorText(props.errorState, props.labelName)}
        </label>
      </Row>
      <Row>
        <Alert variant='light'>
          <p><small><strong>The password must follow the next requirements:</strong></small></p>
          <Row>
            <p>
              <small>
                { minLength? <CheckIcon/> : <DashIcon/> } 8-character minimum length <br/>
                { oneNumber? <CheckIcon/> : <DashIcon/> } Contains at least 1 number <br />
                { oneLowerCase? <CheckIcon/> : <DashIcon/> } Contains at least 1 lowercase letter<br />
                { oneUpperCase? <CheckIcon/> : <DashIcon/> } Contains at least 1 uppercase letter<br />
                { oneSpecialChar? <CheckIcon/> : <DashIcon/> } Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.<br />
                ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , {'>'} {'<'} ' : ; | _ ~  + = ` 
              </small>
            </p>
          </Row>

        </Alert>
      </Row>
    </div>
  );
}

export default ValidatedFieldEntryWithRules;