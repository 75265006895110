import { FormatPhoneNumber } from "../../../utilities/FormatPhoneNumber";
import { GetApi, GetAllUsers } from "../../apis/Api";
import { Roles } from "./UserRoles";

async function GetUsers() {

  var users = await GetApi(GetAllUsers)
  .then(response => response.json())
  .then(data => convert(data)); 
 
  return users;
};

function convert(response) {
  return response.result?.map((item)=>convertEach(item));
}

function convertEach(item) {

  return {
    ...item,
    name: item.username,
    user_id: item.user_id,
    login_id: item.login_id,
    role: item.role,
    email: item.email,
    facility_name: item.facility_name,
    facility_id: item.facility_id,
    created_at: item.created_at ?? 0,
    status: item.status,
    last_login: item.last_login,
    phone: FormatPhoneNumber( item.phone )
  };
}

export default GetUsers;