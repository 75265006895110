import React, { useState } from 'react'
import { Loading } from './GenericModals/Loading'
import { ResultModal } from './GenericModals/ResultModal'
import { useResetPassword } from '../CustomHooks/useResetPassword'
import ResetUserPassword from '../../data_layer/repositories/Users/ResetUserPassword'
import { BasicModal } from './GenericModals/BasicModal'

export const ResetPasswordModal = ({ user }) => {

    //const {isLoading, response} = useResetPassword(user_id);

    const [showResultModal, setShowResultModal] = useState(ModalStates.INITIAL);

    
    const [response, setResponse] = useState();

    const onHide = () => {
        setShowResultModal(ModalStates.DISMISS);
    }

    const resetPassword = async () => {
        setShowResultModal(ModalStates.LOADING)
        const resetResponse = await ResetUserPassword(user.user_id);
        setResponse(resetResponse);
        setShowResultModal(ModalStates.RESULT)
    }

    const getMessage = () => {
        return <center>

            <p>Are you sure you want to reset the password to this user?
        
            <br />
            <strong>{`${user.name} (${user.email})`}</strong>
            <br />
            This user will receive an email with more instructions
            </p>
        </center>
    }


    return (
        <>
            {showResultModal === ModalStates.INITIAL &&
                <BasicModal
                    title={'Reset password'}
                    message={ getMessage() }
                    showModal={showResultModal === ModalStates.INITIAL}
                    successFunction={resetPassword} 
                    onHide={onHide}/>
            }

            {showResultModal === ModalStates.LOADING &&
                <Loading isLoading={showResultModal === ModalStates.LOADING} />
            }

            {showResultModal === ModalStates.RESULT && 
                <ResultModal isSuccess={response}
                    title={response ? 'Success' : 'Error'}
                    showModal={showResultModal === ModalStates.RESULT}
                    onHide={onHide} />
            }
        </>
    )
}

const ModalStates = {
    INITIAL: 'initial',
    LOADING: 'loading',
    RESULT: 'result',
    DISMISS: 'dismiss',
}
