import  '../../../styles/Modals.css'
import Modal from 'react-bootstrap/Modal';
import IsNullOrEmpty from '../../../utilities/IsNullOrEmpty';


function UpdateEntityModalHeader(props) {

  return (
      <Modal.Header
        closeButton='true'
        onHide={() => props.close()}>
        <div className='Column'>
          <div className='Row'>
            <Modal.Title>
              <div className='ModalHeader'>
                <label className='ModalHeaderLabel'>{props.headerLabel}</label>
              </div>
            </Modal.Title>
            <div>
              <label className={IsNullOrEmpty(props.identifier) ? 'InvisibleCollapse' : 'ModalSubheaderLabel'}>{props.subheaderLabel}{props.identifier}</label>
            </div>
          </div>
        </div>
      </Modal.Header>
  );

}

export default UpdateEntityModalHeader;