import { GetAllHandheldsPath, GetApi } from "../../apis/Api";


async function GetAllHandhelds() {

    const handhelds = await GetApi(GetAllHandheldsPath())
        .then(response => response.json())
        .then(data => data.result);

    return handhelds;

}

export default GetAllHandhelds;