import React, { useEffect, useRef, useState } from 'react'



import { useGetMeasurements } from '../CustomHooks/useGetMeasurements';
import { Loading } from '../Loading/Loading';
import { LinearChart } from './LinearChart';

export const FeedingChart = ({ feeding, flags, isLightGraph = true, granularity }) => {

    const [timestamp, setTimestamp] = useState('');
    const [patientId, setPatientId] = useState('');

    const [breathingData, setBreathingData] = useState([]);
    const [pressureData, setPressureData] = useState([]);
    const [timeData, setTimeData] = useState([]);

    const { measurements, isLoading, getData:getMeasurementsData } = useGetMeasurements(timestamp, patientId, isLightGraph);

    useEffect(() => {

        setTimestamp(feeding?.timestamp);
        setPatientId(feeding?.patient_id);

    }, [feeding]);

    useEffect(() => {
        if (feeding?.timestamp && feeding?.patient_id)
            getMeasurementsData();
    }, [timestamp, patientId]);
    

    useEffect(() => {
        if (measurements.length > 0) {
            setTimeData(measurements?.map((measurement) => measurement.timetick));
            setPressureData(measurements?.map((measurement) => measurement.pressure));
            setBreathingData(measurements?.map((measurement) => measurement.breathing));
        }

    }, [measurements]);




    return (
        <>
            {isLoading && <Loading isLoading={isLoading} />}

            

            {measurements.length > 0 && !isLoading && <LinearChart
                breathingData={breathingData}
                pressureData={pressureData}
                timeData={timeData} 
                flags={flags}
                isLiteVersion = {isLightGraph}
                granularity={granularity}/>}

        </>

    )
}
