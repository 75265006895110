import {PaginatedTable, EmptyIsZeroSort} from '../PaginatedTable/PaginatedTable';
import {FaMobileAlt} from "react-icons/fa";
import {FiEdit2} from "react-icons/fi";
import FormatDate from '../../utilities/FormatDate';


function MobileDevicesTable(props)
{
  const columns = [
    {
      name: 'Serial Number',
      selector: row => row.mobile_serial_number,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Name',
      selector: row => row.mobile_device_name,
      sortable: true,
    },
    {
      name: 'Model',
      selector: row => row.mobile_manufacture_model,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        return EmptyIsZeroSort(rowA.mobile_manufacture_model, rowB.mobile_manufacture_model);
      }
    },
    {
      name: 'Asset Tag',
      selector: row => row.client_facility_asset_tag,
      sortable: true,
    },
    {
      name: 'Date created',
      selector: row => FormatDate(row.created_at) ,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Date updated',
      selector: row => FormatDate(row.updated_at) ,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Last Login',
      selector: row => FormatDate(row.last_login) ,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Active',
      selector: row => row.status === 'active' ? 'Yes': 'No', // change this for real property
      sortable: true,
      width: '100px',
    },
    {
      name: '',
      selector: (row) => <a className="neo-icon icon-link icon-link-hover" 
                            onClick={() => props.onRowClicked(row)}
                            href='#'>
                            <FiEdit2  size={18} />
                          </a> ,
      width: '50px'
    },
  ];

  const sortedData = props.data.sort((a, b) => {
    // Sort by status first ('active' before 'inactive')
    if (a.status === b.status) {
      // If statuses are the same, sort by name alphabetically
      return a.mobile_serial_number.localeCompare(b.mobile_serial_number);
    }
    // If statuses are different, sort 'active' before 'inactive'
    return a.status === 'active' ? -1 : 1;
  });
  
  return (
    <PaginatedTable
      name="Mobile devices"
      icon={<FaMobileAlt size={25}/>}
      data={sortedData}
      columns={columns}
      onRowClicked={props.onRowClicked}
      buttonLabel="Register mobile"
      onButtonClick={props.addEntityClicked}/>
  )
}

export default MobileDevicesTable;