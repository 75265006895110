import { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useGetAllHandhelds } from '../../components/CustomHooks/useGetAllHandhelds'
import { Loading } from '../../components/Loading/Loading'
import HandheldsTable from '../../components/Tables/HandheldsTable'
import UpdateHandheldModal from '../../components/Modals/UpdateEntityModals/UpdateHandheldModal'
import { AuthContext } from '../../auth'
import { Roles } from '../../data_layer/repositories/Users/UserRoles'
import { useGetFacilities } from '../../components/CustomHooks/useGetFacilities'
import { useGetHandheldsByFacility } from '../../components/CustomHooks/useGetHandheldsByFacility';
import { useLocation } from 'react-router-dom';

export const HandheldsPage = () => {

    //const [facilities, setFacilities] = useState([]);
    const [showHandheldModal, setShowHandheldModal] = useState(false);
    const [labelPrefix, setLabelPrefix] = useState("");
    const [handheldToEdit, setHandheldToEdit] = useState({});
    const [facilityId, setFacilityId] = useState();

    const { facilities } = useGetFacilities()

    const { user } = useContext(AuthContext);

    const { handhelds, isLoading, reloadData } = useGetAllHandhelds(user.role, facilityId);

    useEffect(() => {
        if (user.role === Roles.ItAdmin) {
            setFacilityId(user.facility_id);
        }
    }, []);

    const refreshHandhelds = () => {
        reloadData()
    }

    function getFacilitiesAsOptions() {
        const options = []
        facilities.filter((facility) => facility.status === 'active')
            .forEach((facility) => options.push({ "name": facility.name, "value": facility.id }));
        return options
    }


    const onRowClicked = (handheld) => {
        setLabelPrefix("Edit ");
        setHandheldToEdit(handheld);
        setShowHandheldModal(true);
    }

    const addHandheldClicked = () => {

        const env = process.env.REACT_APP_ENV;
        const uuid = uuidv4();
        const code = env[0].toLowerCase() + uuid.slice(1 - uuid.length);

        setLabelPrefix("Add ");
        setHandheldToEdit({
            serial_number: "",
            friendly_name: "",
            model: "",
            software_version: "",
            pairing_code: code,
            facility_id: facilities.find((f) => f.name === 'Neoneur')?.id,
            status: 'active'
        });
        setShowHandheldModal(true);
    }

    const closeHandheldModal = () => {
        refreshHandhelds();
        setLabelPrefix("");
        setHandheldToEdit({});
        setShowHandheldModal(false);
    }

    return (
        <>

            <div className='neo_card_container neo_ut_mt_24_mob'>
                {
                    isLoading && <Loading isLoading={isLoading} />
                }


                {
                    handhelds && !isLoading &&
                    <HandheldsTable
                        data={handhelds}
                        onRowClicked={onRowClicked}
                        addEntityClicked={addHandheldClicked}
                        isNeoneurAdmin={user.role === Roles.NeoneurAdmin} />
                }

                <UpdateHandheldModal
                    show={showHandheldModal}
                    labelPrefix={labelPrefix}
                    close={closeHandheldModal}
                    facilities={getFacilitiesAsOptions()}
                    entityToUpdate={handheldToEdit}
                    refresh={refreshHandhelds}
                    handhelds={handhelds} />
            </div>
        </>
    )
}
