import '../../../styles/Shared.css'

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { useState, useEffect } from 'react'
import { TbBabyBottle } from "react-icons/tb";

import {
  IsAddState,
  IsConfirmState,
  ModalStates
} from './UpdateEntityModalStates';

import UpdateEntityModalDataLine from './UpdateEntityModalDataLine';
import UpdateEntityModalEditConfirmDataLine from './UpdateEntityModalEditConfirmDataLine';
import UpdateEntityModalHeader from './UpdateEntityModalHeader';
import UpdateEntityModalFooter from './UpdateEntityModalFooter';

import {
  InputValidationErrorStates,
  CheckIsMissing,
  HasErrors,
  CheckIsMissingAndLength
} from './InputValidation';

import UpdateEntityModalDataPicker from './UpdateEntityModalDataPicker';
import {
  HandheldSerialNumberMaxLength,
  HandheldSoftwareVersionMaxLength,
  HandheldFriendlyNameLength,
  HandheldModelMaxLength,
  HandheldPairingCodeMaxLength
} from '../../../utilities/Constants';
import UpdateHandheld from '../../../data_layer/repositories/Handhelds/UpdateHandheld';
import RegisterHandheld from '../../../data_layer/repositories/Handhelds/RegisterHandheld';
import { Button, Row } from 'react-bootstrap';
import UpdateEntityModalDataLineUUIDGenerator from './UpdateEntityModalDataLineUUIDGenerator';
import UpdateEntityModalDataSwitch from './UpdateEntityModalDataSwitch';


function UpdateHandheldModal(props) {

  const [modalState, setModalState] = useState(ModalStates.Edit);
  const [editedEntity, setEditedEntity] = useState([]);
  const [resultMessage, setResultMessage] = useState("Writing...");
  const [errorStates, setErrorStates] = useState([]);

  const [formEdited, setFormEdited] = useState(false);

  const [temporaryPairingCode, setTemporaryPairingCode] = useState('');
  const [tempFriendlyName, setTempFriendlyName] = useState('');

  useEffect(() => {
    clearErrorStates();
    setEditedEntity({ ...props.entityToUpdate });
  }, [props.entityToUpdate]);

  useEffect(() => {
    if (props.labelPrefix === "Edit ") {
      setModalState(ModalStates.Edit);
      setTemporaryPairingCode('****' + getLastDigits(props.entityToUpdate.pairing_code, 10))
      setTempFriendlyName(props.entityToUpdate.friendly_name)
    }

    if (props.labelPrefix === "Add ") {
      setModalState(ModalStates.Add);
      setTemporaryPairingCode(props.entityToUpdate.pairing_code)
    }
  }, [props.labelPrefix]);

  function clearErrorStates() {
    setErrorStates(
      {
        serial_number: InputValidationErrorStates.NoError,
        friendly_name: InputValidationErrorStates.NoError,
        model: InputValidationErrorStates.NoError,
        software_version: InputValidationErrorStates.NoError,
        pairing_code: InputValidationErrorStates.NoError,
        facility_id: InputValidationErrorStates.NoError,
      }
    )
  }

  function validateInputs() {
    const serialNumberError = CheckIsMissingAndLength(editedEntity.serial_number, null, HandheldSerialNumberMaxLength);
    const friendlyNameError = CheckIsMissingAndLength(tempFriendlyName, null, HandheldFriendlyNameLength);
    const modelError = CheckIsMissingAndLength(editedEntity.model, null, HandheldModelMaxLength);
    const softwareVersionError = CheckIsMissingAndLength(editedEntity.software_version, null, HandheldSoftwareVersionMaxLength)
    let pairingCodeError = CheckIsMissingAndLength(editedEntity.pairing_code, null, HandheldPairingCodeMaxLength);

    if (pairingCodeError === InputValidationErrorStates.NoError) {
      pairingCodeError = isPairingCodeUnique(editedEntity.pairing_code, editedEntity.serial_number)
    }


    const facilityIdError = CheckIsMissing(editedEntity.facility_id);


    setErrorStates({
      ...errorStates,
      serial_number: serialNumberError,
      friendly_name: friendlyNameError,
      model: modelError,
      software_version: softwareVersionError,
      pairing_code: pairingCodeError,
      facility_id: facilityIdError,
    })

    return !HasErrors([
      serialNumberError,
      friendlyNameError,
      modelError,
      softwareVersionError,
      pairingCodeError,
      facilityIdError]);
  }

  function updateEntity() {
    if (!editedEntity.friendly_name) {
      editedEntity.friendly_name = tempFriendlyName
    }
    UpdateHandheld(editedEntity, setResultMessage, props.refresh);
  }

  function addEntity() {
    if (!editedEntity.friendly_name) {
      editedEntity.friendly_name = tempFriendlyName
    }
    RegisterHandheld(editedEntity, setResultMessage, props.refresh);
  }

  function updateEditedParameter(newValue, parameter) {
    setFormEdited(true);
    setErrorStates((error) => ({ ...error, [parameter]: InputValidationErrorStates.NoError }));
    setEditedEntity(editedEntity => ({ ...editedEntity, [parameter]: newValue }));

    if (parameter === 'pairing_code') {
      setTemporaryPairingCode(newValue)
    }

    if (parameter === 'serial_number' && !editedEntity.friendly_name) {

      setTempFriendlyName(getLastDigits(newValue, 5))
    }

    if (parameter === 'friendly_name') {
      setTempFriendlyName(newValue);
    }
  }

  function closeModal() {
    setFormEdited(false);
    setResultMessage("Writing...");
    setEditedEntity([]);
    setTempFriendlyName('');
    props.close();
  }

  function getFacilityName(facility_id) {
    return props.facilities.find((facility) => facility.value === facility_id)?.name
  }

  function getLastDigits(value, length) {
    return value?.length > length
      ? value.substring(value.length - length)
      : value
  }

  function isPairingCodeUnique(pairing_code, serial_number) {
    const result = props.handhelds.find((hh) => hh.pairing_code === pairing_code && hh.serial_number !== serial_number)
    return !result ? InputValidationErrorStates.NoError : InputValidationErrorStates.AlreadyRegistered
  }

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}>
      <Modal
        show={props.show}
        backdrop="static"
        centered
        size="xl">
        <UpdateEntityModalHeader
          icon={<TbBabyBottle className='ModalHeaderIcon' size={20} />}
          headerLabel={props.labelPrefix + "handheld"}
          close={closeModal} />
        <Modal.Body>
          <label className={modalState === ModalStates.ResultMessage ? 'ResultMessage' : 'InvisibleCollapse'}>{resultMessage}</label>
          <Container className={modalState === ModalStates.ResultMessage ? 'InvisibleCollapse' : 'null'}>

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="SERIAL NUMBER"
              parameterName='serial_number'
              value={editedEntity.serial_number}
              inputChanged={updateEditedParameter}
              required
              disabled={!IsAddState(modalState)}
              errorState={errorStates.serial_number}
              entryMaxLength={HandheldSerialNumberMaxLength} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="FRIENDLY NAME"
              required
              parameterName='friendly_name'
              value={tempFriendlyName}
              inputChanged={updateEditedParameter}
              entryMaxLength={HandheldFriendlyNameLength}
              errorState={errorStates.friendly_name} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="MODEL"
              required
              parameterName='model'
              value={editedEntity.model}
              inputChanged={updateEditedParameter}
              entryMaxLength={HandheldModelMaxLength}
              errorState={errorStates.model} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="FIRMWARE VERSION"
              required
              parameterName='software_version'
              value={editedEntity.software_version}
              inputChanged={updateEditedParameter}
              entryMaxLength={HandheldSoftwareVersionMaxLength}
              errorState={errorStates.software_version} />

            <UpdateEntityModalDataLineUUIDGenerator
              modalState={modalState}
              labelName="PAIRING CODE"
              required
              parameterName='pairing_code'
              value={temporaryPairingCode}
              inputChanged={updateEditedParameter}
              entryMaxLength={HandheldPairingCodeMaxLength}
              errorState={errorStates.pairing_code} />

            <UpdateEntityModalDataPicker
              modalState={modalState}
              labelName="FACILITY"
              choices={props.facilities}
              parameterName='facility_id'
              value={editedEntity.facility_id}
              inputChanged={updateEditedParameter}
              required
              errorState={errorStates.facility_id} />

            <div className={!IsAddState(modalState) ? 'null' : 'InvisibleCollapse'}>
              <UpdateEntityModalDataSwitch
                modalState={modalState}
                labelName="ACTIVE"
                parameterName='status'
                value={editedEntity.status}
                inputChanged={updateEditedParameter} />
            </div>

          </Container>
          <div className={IsConfirmState(modalState) ? 'null' : 'InvisibleCollapse'}>
            <hr className='ThickLine'></hr>
            <label className="ConfirmHeader">Are you sure you want to save your changes?</label>
            <div className={modalState === ModalStates.ConfirmAdd ? 'InvisibleCollapse' : 'null'}>

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"SERIAL NUMBER"}
                originalParameter={props.entityToUpdate.serial_number}
                updatedParameter={editedEntity.serial_number} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"FRIENDLY NAME"}
                originalParameter={props.entityToUpdate.friendly_name}
                updatedParameter={editedEntity.friendly_name} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"MODEL"}
                originalParameter={props.entityToUpdate.model}
                updatedParameter={editedEntity.model} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"SOFTWARE VERSION"}
                originalParameter={props.entityToUpdate.software_version}
                updatedParameter={editedEntity.software_version} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"PAIRING CODE"}
                originalParameter={props.entityToUpdate.pairing_code}
                updatedParameter={editedEntity.pairing_code} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"FACILITY"}
                originalParameter={getFacilityName(props.entityToUpdate.facility_id)}
                updatedParameter={getFacilityName(editedEntity.facility_id)} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"STATUS"}
                originalParameter={props.entityToUpdate.status}
                updatedParameter={editedEntity.status} />

            </div>
          </div>
        </Modal.Body>
        <UpdateEntityModalFooter
          modalState={modalState}
          setState={setModalState}
          updateEntity={updateEntity}
          addEntity={addEntity}
          validateInputs={validateInputs}
          close={closeModal}
          refresh={props.refresh}
          active={formEdited} />
      </Modal>
    </div>
  );
};

export default UpdateHandheldModal;