export function ConvertToGrams(weight, unit){
    switch (unit) {
        case 'kg':
            return weight * 1000; // 1 kilogram = 1000 grams
        case 'oz':
            return weight * 28.3495; // 1 ounce = 28.3495 grams
        case 'lb':
            return weight * 453.592; // 1 pound = 453.592 grams
        case 'g':
            return weight; // already in grams
        default:
            return weight; // in case of unknown unit, return the input
    }
}