import { PostApi, CalculateBiomarkersByPatientPath } from "../../apis/Api";

async function CalculateBiomarkers(patient_id, feedings){

    let response = "";
    for (let index = 0; index < feedings.length; index++) {
      response = await PostApi(CalculateBiomarkersByPatientPath(), {
          feeding_session_timestamp: feedings[index].timestamp,
          patient_id: patient_id
        })
        .then(response => response.json())
        .then(response => {
            console.log(response)
            return response.message;
        });
    }  

    return response;
}
export default CalculateBiomarkers;