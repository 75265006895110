import { useEffect, useState } from "react";
import GetMobileDevicesByFacility from "../../data_layer/repositories/Mobile/GetMobileDevicesByFacility";
import GetMobileDevices from "../../data_layer/repositories/Mobile/GetMobileDevices";

export const useGetMobiles = (facility_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [mobiles, setMobiles] = useState([]);

    useEffect(() => {
        getMobiles();
    }, [facility_id])
    
    const getMobiles = async () => {
        setIsLoading(true);
        const response = facility_id
            ? await GetMobileDevicesByFacility(facility_id)
            : await GetMobileDevices();

        setMobiles(response);
        setIsLoading(false);
    }

    const reloadData = () => {
        getMobiles();
    }

    return {
        isLoading,
        mobiles,
        reloadData,
    }
}
