import '../../../styles/Shared.css'

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useContext } from 'react'
import { FiUser } from "react-icons/fi";

import { IsAddState, IsConfirmState, ModalStates } from './UpdateEntityModalStates';
import { GetRoleName, Roles, UserRoles, UserRolesNeoneurAdmin } from '../../../data_layer/repositories/Users/UserRoles'

import UpdateEntityModalDataLine from './UpdateEntityModalDataLine';
import UpdateEntityModalDataPicker from './UpdateEntityModalDataPicker';
import UpdateEntityModalEditConfirmDataLine from './UpdateEntityModalEditConfirmDataLine';
import UpdateEntityModalHeader from './UpdateEntityModalHeader';
import UpdateEntityModalFooter from './UpdateEntityModalFooter';

import AddUser from '../../../data_layer/repositories/Users/AddUser'
import UpdateUser from '../../../data_layer/repositories/Users/UpdateUser'
import { InputValidationErrorStates, CheckIsMissing, HasErrors, CheckIsRealEmail, CheckNameIsCorrect, CheckLoginIdIsCorrect, CheckIsValidPinCode, CheckIsMissingAndNumeric, CheckIsRealPhoneNumber } from './InputValidation';
import UpdateEntityModalDataSwitch from './UpdateEntityModalDataSwitch';
import { AuthContext } from '../../../auth';
import { UserLoginIDMaxLength, UserLoginIDMinLength, UserNameMaxLength, UserNameMinLength, UserPinCodeMaxLength, UserPinCodeMinLength } from '../../../utilities/Constants';
import { FormatPhoneNumber } from '../../../utilities/FormatPhoneNumber';
import { useGetUsers } from '../../CustomHooks/useGetUsers';



function UpdateUserModal(props) {

  const [modalState, setModalState] = useState(ModalStates.Edit);
  const [editedEntity, setEditedEntity] = useState([]);
  const [resultMessage, setResultMessage] = useState("Writing...");
  const [errorStates, setErrorStates] = useState([]);

  const [formEdited, setFormEdited] = useState(false)

  const { user } = useContext(AuthContext);

  const [facilities, setFacilities] = useState([])

  useEffect(() => {
    clearErrorStates();
    setEditedEntity({ ...props.entityToUpdate });
  }, [props.entityToUpdate]);

  useEffect(() => {
    if (props.labelPrefix === "Edit ") {
      setModalState(ModalStates.Edit);
    }

    if (props.labelPrefix === "Add ") {
      setModalState(ModalStates.Add);
    }
  }, [props.labelPrefix]);

  useEffect(() => {
    if (props.labelPrefix === "Add " && user.role === Roles.NeoneurAdmin) {
      if (editedEntity.role === 'nn-admin') {
        const values = props.facilities.filter((f) => f.name === 'Neoneur')
        setFacilities(values)
        editedEntity.facility_id = values[0].value
      }
      else {
        setFacilities(props.facilities.filter((f) => f.name !== 'Neoneur'))
        editedEntity.facility_id = ''
      }
    }
    else {
      setFacilities(props.facilities)
    }
  }, [editedEntity.role])


  function clearErrorStates() {
    setErrorStates(
      {
        name: InputValidationErrorStates.NoError,
        login_id: InputValidationErrorStates.NoError,
        //password: InputValidationErrorStates.NoError,
        email: InputValidationErrorStates.NoError,
        phone: InputValidationErrorStates.NoError,
        passcode_before_encrypt: InputValidationErrorStates.NoError,
        role: InputValidationErrorStates.NoError,
        facility_id: InputValidationErrorStates.NoError,
      }
    )
  }

  function validateInputs() {
    const nameErrorState = CheckNameIsCorrect(editedEntity.name, UserNameMinLength, UserNameMaxLength);

    let emailErrorState = CheckIsRealEmail(editedEntity.email)

    // Validate email is unique in the facility
    if (emailErrorState === InputValidationErrorStates.NoError) {
      emailErrorState = isEmailUnique();
    }

    const phoneErrorState = CheckIsRealPhoneNumber(editedEntity.phone)

    let loginIdErrorState = CheckLoginIdIsCorrect(editedEntity.login_id, UserLoginIDMinLength, UserLoginIDMaxLength);

    // Validate username is unique in the facility
    if (loginIdErrorState === InputValidationErrorStates.NoError) {
      loginIdErrorState = isLoginIDUnique();
    }

    const roleErrorState = CheckIsMissing(editedEntity.role);

    const facilityErrorState = user.role === Roles.NeoneurAdmin ? CheckIsMissing(editedEntity.facility_id) : InputValidationErrorStates.NoError;


    const passcodeErrorState = (modalState === ModalStates.Add && (editedEntity.role === Roles.ClinicalAdmin || editedEntity.Clinician)) ?
      CheckIsValidPinCode(editedEntity.passcode_before_encrypt, UserPinCodeMinLength, UserPinCodeMaxLength) :
      errorStates.passcode_before_encrypt;

    setErrorStates({
      ...errorStates,
      name: nameErrorState,
      login_id: loginIdErrorState,
      email: emailErrorState,
      phone: phoneErrorState,
      //password: passwordErrorState,
      passcode_before_encrypt: passcodeErrorState,
      role: roleErrorState,
      facility_id: facilityErrorState,
    })

    return !HasErrors([nameErrorState, loginIdErrorState, passcodeErrorState, emailErrorState, phoneErrorState, roleErrorState, facilityErrorState]);
  }

  function isEmailUnique() {
    const result = props.users.filter((u) =>
      u.user_id !== editedEntity.user_id &&
      u.facility_id === editedEntity.facility_id &&
      u.email === editedEntity.email
    )
    if (result.length > 0) {
      return InputValidationErrorStates.AlreadyRegistered
    }
    else {
      return InputValidationErrorStates.NoError
    }
  }



  function isLoginIDUnique() {
    const result = props.users.filter((u) =>
      u.user_id !== editedEntity.user_id &&
      u.facility_id === editedEntity.facility_id &&
      u.login_id === editedEntity.login_id.toUpperCase()
    )
    if (result.length > 0) {
      return InputValidationErrorStates.AlreadyRegistered
    }
    else {
      return InputValidationErrorStates.NoError
    }
  }

  function updateEntity() {
    editedEntity.phone = "+1" + editedEntity.phone.replace(/\D/g, '');
    UpdateUser(editedEntity, setResultMessage, props.refresh);
  }

  function addEntity() {
    editedEntity.phone = "+1" + editedEntity.phone.replace(/\D/g, '');
    AddUser(editedEntity, setResultMessage, props.refresh);
  }

  function updateEditedParameter(newValue, parameter) {
    setFormEdited(true);
    setErrorStates((error) => ({ ...error, [parameter]: InputValidationErrorStates.NoError }));
    if (parameter === 'phone')
      newValue = FormatPhoneNumber(newValue)
    setEditedEntity(editedEntity => ({ ...editedEntity, [parameter]: newValue }));
  }

  function closeModal() {
    setFormEdited(false);
    setResultMessage("Writing...");
    clearErrorStates();
    setEditedEntity([]);
    props.close();
  }

  function getRoles() {
    if (user.role === Roles.NeoneurAdmin)
      return UserRolesNeoneurAdmin
    else
      return UserRoles.filter((role) => role.value !== Roles.NeoneurAdmin);
  }

  function getFacilityName(facility_id) {
    return props.facilities.find((facility) => facility.value === facility_id)?.name
  }

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}>
      <Modal
        show={props.show}
        backdrop="static"
        centered
        size="xl">
        <UpdateEntityModalHeader
          icon={<FiUser className='ModalHeaderIcon' size={20} />}
          headerLabel={props.labelPrefix + "user"}
          close={closeModal} />

        <Modal.Body>
          <label className={modalState === ModalStates.ResultMessage ? 'ResultMessage' : 'InvisibleCollapse'}>{resultMessage}</label>
          <Container className={modalState === ModalStates.ResultMessage ? 'InvisibleCollapse' : 'null'}>

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="FULL NAME"
              parameterName='name'
              value={editedEntity.name}
              inputChanged={updateEditedParameter}
              required
              errorState={errorStates.name}
              entryMaxLength={UserNameMaxLength}
              entryMinLength={UserNameMinLength} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="EMAIL"
              parameterName='email'
              value={editedEntity.email}
              required
              placeholder='someone@example.com'
              inputChanged={updateEditedParameter}
              errorState={errorStates.email} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="PHONE"
              parameterName='phone'
              value={editedEntity.phone}
              required
              entryMaxLength={14}
              placeholder='(###) ###-####'
              inputChanged={updateEditedParameter}
              errorState={errorStates.phone} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="USERNAME"
              parameterName='login_id'
              value={editedEntity.login_id?.toLowerCase()}
              inputChanged={updateEditedParameter}
              required
              disabled={IsAddState(modalState) ? false : true} // Disable in edit case
              errorState={errorStates.login_id}
              entryMaxLength={UserLoginIDMaxLength}
              entryMinLength={UserLoginIDMinLength} />

            <UpdateEntityModalDataPicker
              modalState={modalState}
              labelName="ROLE"
              parameterName='role'
              required
              choices={getRoles()}
              value={editedEntity.role}
              inputChanged={updateEditedParameter}
              errorState={errorStates.role} />

            <div className={IsAddState(modalState) ? 'null' : 'InvisibleCollapse'}>
              {/* <UpdateEntityModalDataLine
                modalState={modalState}
                labelName = "PASSWORD"
                parameterName = 'password'
                value = {editedEntity.password}
                inputChanged={updateEditedParameter}
                required
                errorState={errorStates.password}
                entryMaxLength={ UserPasswordMaxLength }/> */}
              {(editedEntity.role === Roles.ClinicalAdmin || editedEntity.role === Roles.Clinician) &&
                <UpdateEntityModalDataLine
                  modalState={modalState}
                  labelName="PIN CODE"
                  parameterName='passcode_before_encrypt'
                  value={editedEntity.passcode_before_encrypt}
                  inputChanged={updateEditedParameter}
                  required
                  placeholder='######'
                  errorState={errorStates.passcode_before_encrypt}
                  entryMaxLength={UserPinCodeMaxLength}
                  entryMinLength={UserPinCodeMinLength} />
              }
            </div>

            {user.role === Roles.NeoneurAdmin &&
              <UpdateEntityModalDataPicker
                modalState={modalState}
                labelName="FACILITY"
                choices={facilities}
                parameterName='facility_id'
                value={editedEntity.facility_id}
                inputChanged={updateEditedParameter}
                required
                disabled={!IsAddState(modalState)}
                errorState={errorStates.facility_id} />
            }

            <div className={!IsAddState(modalState) ? 'null' : 'InvisibleCollapse'}>
              <UpdateEntityModalDataSwitch
                modalState={modalState}
                labelName="ACTIVE"
                parameterName='status'
                value={editedEntity.status}
                inputChanged={updateEditedParameter} />
            </div>



          </Container>
          <div className={IsConfirmState(modalState) ? 'null' : 'InvisibleCollapse'}>
            <hr className='ThickLine'></hr>
            <label className="ConfirmHeader">Are you sure you want to save your changes?</label>
            <div className={modalState === ModalStates.ConfirmAdd ? 'InvisibleCollapse' : 'null'}>

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"NAME"}
                originalParameter={props.entityToUpdate.name}
                updatedParameter={editedEntity.name} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"LOGIN ID"}
                originalParameter={props.entityToUpdate.login_id}
                updatedParameter={editedEntity.login_id} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"EMAIL"}
                originalParameter={props.entityToUpdate.email}
                updatedParameter={editedEntity.email} />


              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"PHONE"}
                originalParameter={props.entityToUpdate.phone}
                updatedParameter={editedEntity.phone} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"ROLE"}
                originalParameter={GetRoleName(props.entityToUpdate.role)}
                updatedParameter={GetRoleName(editedEntity.role)} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"FACILITY"}
                originalParameter={getFacilityName(props.entityToUpdate.facility_id)}
                updatedParameter={getFacilityName(editedEntity.facility_id)} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"STATUS"}
                originalParameter={props.entityToUpdate.status}
                updatedParameter={editedEntity.status} />
            </div>
          </div>
        </Modal.Body>

        <UpdateEntityModalFooter
          modalState={modalState}
          setState={setModalState}
          updateEntity={updateEntity}
          addEntity={addEntity}
          validateInputs={validateInputs}
          close={closeModal}
          refresh={props.refresh}
          active={formEdited} />
      </Modal>
    </div>
  );

};

export default UpdateUserModal;