import { GetApi, GetAllFacilities } from "../../apis/Api";
import { MillisOnMinute } from "../../../utilities/Constants";

async function GetFacilities() {

  var facilities = await GetApi(GetAllFacilities)
    .then(response => response.json())
    .then(data => convert(data)); 
 
  return facilities;
};

function convert(response) {
  return response.result?.map((item)=>convertEach(item))
}

function convertEach(item) {
  const facility_type = item.type;
  delete item['type'];
  return {
    ...item,
    web_timeout_session: item.web_timeout_session / MillisOnMinute,
    mobile_timeout_session: item.mobile_timeout_session / MillisOnMinute,
    facility_type : facility_type, 
    city: item.city ?? "",
    state: item.state ?? "",
  };
}

export default GetFacilities;