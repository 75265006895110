import { MaxNumberOfMeasurements } from "../../../utilities/Constants";
import { GetApi, GetMeasurementsCountPath, GetMeasurementsPath } from "../../apis/Api";

async function GetMeasurements(feeding_session_timestamp, patient_id) {

    // How many measurements has the feeding session
    const numberOfMeasurements = await GetApi(GetMeasurementsCountPath + "/" + feeding_session_timestamp + "/" + patient_id)
        .then(response => response.json())
        .then(data => data.result);

    const pages = Math.ceil(numberOfMeasurements / MaxNumberOfMeasurements);

    const measurements = [];

    for (let page = 0; page < pages; page++) {
        const response = await GetApi(GetMeasurementsPath + "/" + feeding_session_timestamp + "/" + patient_id + "/" + page)
            .then(response => response.json())
            .then(data => convertData(data.result));
        measurements.push(...response);
    }

    return measurements;
};

function convertData(elements){
    return elements?.map((item) => convertEachElement(item))
}

function convertEachElement(item){
    return {
        ...item,
        timetick : (item.timetick * 5) / 1000,
        pressure : (item.pressure) / 1000,
        breathing : (item.breathing) / 1000,
        pressure_array : item.pressure_text ? item.pressure_text.split(',') : []
    }
}

export default GetMeasurements;