import { useState } from "react";
import GetAllBiomarkersByPatientId from "../../data_layer/repositories/Biomarkers/GetAllBiomarkersByPatient";

export const useGetBiomarkersListByPatientId = (patient_id) => {


    const [isLoading, setIsLoading] = useState(false);
    const [biomarkers, setBiomarkers] = useState([]);

    const getBiomarkersData = async () => {
        setIsLoading(true);
        const response = await GetAllBiomarkersByPatientId(patient_id);

        setBiomarkers(response);
        setIsLoading(false);
    }

    const getBiomarkers = () => {
        getBiomarkersData();
    }

    return {
        isLoading,
        biomarkers,
        getBiomarkers
    }
}
