import './Footer.css';
import '../../styles/Shared.css';
import '../../styles/Neo.css';

import {VersionNumber} from '../../data_layer/data/VersionNumber';

function Footer() {
  return (
    <div >
      <footer className="FooterContainer">
          <img className='FooterLogo'
            src={require('../../assets/Neoneuro-FF.png')}
            alt="Neoneur Logo"
          />
          <a className='FooterButton' href='https://neoneur.com/privacy/'>Privacy policy</a>
          <a className='FooterButton' href='https://neoneur.com/terms/'>Terms</a>
          <div className='Spacer'></div>
          <label className='FooterLabel'>Version {VersionNumber}</label>
          <label className='FooterLabel'>©2023, Neoneur. All rights reserved.</label>
      </footer>
    </div>
  );
}

export default Footer;