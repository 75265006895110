import { useState } from "react";
import JSZip from "jszip";
import GetMeasurements from "../../data_layer/repositories/Measurements/GetMeasurements";
import FormatDate, { FormatDateForFileName } from "../../utilities/FormatDate";
import ConvertMeasurementsToString from "../../utilities/ConvertMeasurementsToString";
import { saveAs } from "file-saver";
import { LoadingButton } from "../Loading/LoadingButton";


export const ExportAllFeedingsZip = (props) => {

    const [loading, setLoading] = useState(false);

    const onButtonClick = (event) => {
        event.preventDefault();

        downloadAllMeasurements();
    }

    const downloadAllMeasurements = async () => {
        const zip = new JSZip();

        setLoading(true);
        for (let index = 0; index < props.feedings.length; index++) {
            const measurements = await GetMeasurements(props.feedings[index].timestamp, props.patient.patient_id);
            const csvString = ConvertMeasurementsToString(props.feedings[index], measurements);

            let fileName = `${props.patient.ehr_patient_ref}_${props.patient.name.replace(", ", "-").trim()}_`;
            fileName += `${FormatDateForFileName(props.feedings[index].timestamp)}.csv`;

            zip.file(fileName, csvString);
        }

        const content = await zip.generateAsync({ type: "blob" });

        saveAs(content, `${props.patient.ehr_patient_ref}_feeding_data.zip`);

        setLoading(false);
    }


    return (
        <LoadingButton
            title="Download All Feedings"
            onClick={ onButtonClick }
            loading={ loading }
            />
        // <>
        //     {loading &&
        //         <div className="p-1 spinner-border" role="status">
        //             <span className="visually-hidden">Loading...</span>
        //         </div>
        //     }
        //     {!loading &&<a
        //         className="neo_qv_btn"
        //         onClick={onButtonClick}>
        //         Download All Feedings
        //     </a>}
        // </>

    )
}
