export const FirstNameMaxLength = 1;
export const LastNameMaxLength = 3;

// General Values

export const MillisOnMinute = 60000;

export const DefaultTimeoutLimit = 180000; // 3 minutes
export const DefauilWaitTimeAfterTimeout = 20000; // 20 secs

export const isTimeoutActive = process.env.REACT_APP_IS_TIMEOUT_ACTIVE === 'true';

// User values

export const UserNameMinLength = 4;
export const UserNameMaxLength = 35;
export const UserLoginIDMinLength = 6;
export const UserLoginIDMaxLength = 16;
export const UserPasswordMinLength = 8;
export const UserPasswordMaxLength = 16;
export const UserPinCodeMinLength = 4;
export const UserPinCodeMaxLength = 6;


// Facility Values

export const MinTimeoutSession = 3;
export const MaxTimeoutSession = 15;

export const FacilityNameMaxLength = 100;

export const FacilityAddressMaxLength = 100;

export const FacilityZipCodeMaxLength = 10;
export const FacilityZipCodeMinLength = 5;


// Mobiles

export const MobileDeviceNameMaxLength = 50;
export const MobileSerialNumberMaxLength = 40;
export const MobileSerialNumberMinLength = 10;
export const MobileModelMaxLength = 100;
export const MobileAssetTagMaxLength = 20;


// Patient
export const PatientGestationalAgeMinValue = 25;
export const PatientGestationalAgeMaxValue = 45;
export const PatientEhrMaxLength = 16;
export const PatientFirstNameMaxLength = 1;
export const PatientLastNameMaxLength = 3;
export const PatientBirthWeightMinValue = 0;
export const PatientBirthWeightMaxValue = 999;
export const PatientConceptualAgeMinValue = 25;
export const PatientConceptualAgeMaxValue = 150;

export const PatientsPerPage = 12;

// Measurements
export const MaxNumberOfMeasurements = 30000;

// Feeding graph 
export const MinValuePressureGraph = 0;
export const MaxValuePressureGraph = 2;
export const MinValueBreathingGraph = 0.5;
export const MaxValueBreathingGraph = 2.7;


// HANDHELD
export const HandheldSerialNumberMaxLength = 20;
export const HandheldFriendlyNameLength = 20;
export const HandheldModelMaxLength = 20;
export const HandheldSoftwareVersionMaxLength = 20;
export const HandheldPairingCodeMaxLength = 40;

// Patient Dashboard
export const PatientCardMaxSessions = 4;
