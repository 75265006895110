import '../../../styles/Shared.css'

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import {useState, useEffect, useContext} from 'react'
import { LuBaby } from "react-icons/lu";

import { IsConfirmState, ModalStates } from './UpdateEntityModalStates';

import UpdateEntityModalDataLine from './UpdateEntityModalDataLine';
import UpdateEntityModalEditConfirmDataLine from './UpdateEntityModalEditConfirmDataLine';
import UpdateEntityModalHeader from './UpdateEntityModalHeader';
import UpdateEntityModalFooter from './UpdateEntityModalFooter';

import { InputValidationErrorStates, HasErrors, CheckIsMissingAndLength, CheckIsMissingDecimalMinMaxValue } from './InputValidation';
import UpdateEntityModalDataSwitch from './UpdateEntityModalDataSwitch';
import { PatientBirthWeightMaxValue, PatientBirthWeightMinValue, PatientConceptualAgeMaxValue, PatientConceptualAgeMinValue, PatientFirstNameMaxLength, PatientGestationalAgeMaxValue, PatientGestationalAgeMinValue, PatientLastNameMaxLength } from '../../../utilities/Constants';
import UpdateEntityModalDataLineAndPicker from './UpdateEntityModalDataLineAndPicker';
import { WeightUnits } from '../../../utilities/WeightUnits';
import UpdatePatient from '../../../data_layer/repositories/Patients/UpdatePatient';



function UpdatePatientModal(props) {

  const [modalState, setModalState] = useState(ModalStates.Edit);
  const [editedEntity, setEditedEntity]= useState([]);
  const [resultMessage, setResultMessage]= useState("Writing...");
  const [errorStates, setErrorStates] = useState([]);
  
  const [formEdited, setFormEdited] = useState(false);

  useEffect(() => {
    clearErrorStates();
    setEditedEntity({...props.entityToUpdate});
  },[props.entityToUpdate]);

  useEffect(() => {
      setModalState(ModalStates.Edit);
    },[props.labelPrefix]);

  function clearErrorStates()
  {
    setErrorStates(
      {
        first_name: InputValidationErrorStates.NoError,
        last_name: InputValidationErrorStates.NoError,
        gestational_age: InputValidationErrorStates.NoError,
        conceptual_age: InputValidationErrorStates.NoError,
        birth_weight: InputValidationErrorStates.NoError,
      }
    )
  }

  function validateInputs()
  {
    const firstNameError = CheckIsMissingAndLength(editedEntity.first_name, 0, PatientFirstNameMaxLength);

    const lastNameError = CheckIsMissingAndLength(editedEntity.last_name, 0, PatientLastNameMaxLength); 

    const gestationalAgeError = CheckIsMissingDecimalMinMaxValue(editedEntity.gestational_age, PatientGestationalAgeMinValue, PatientGestationalAgeMaxValue);

    const conceptualAgeError = CheckIsMissingDecimalMinMaxValue(editedEntity.conceptual_age, PatientConceptualAgeMinValue, PatientConceptualAgeMaxValue);

    const birthWeightError = CheckIsMissingDecimalMinMaxValue(editedEntity.birth_weight, PatientBirthWeightMinValue, PatientBirthWeightMaxValue);

    setErrorStates({...errorStates, 
      first_name: firstNameError,
      last_name: lastNameError,
      gestational_age: gestationalAgeError,
      conceptual_age: conceptualAgeError,
      birth_weight: birthWeightError
    })

    return !HasErrors([firstNameError, lastNameError, gestationalAgeError, conceptualAgeError, birthWeightError]);
  }
  
  async function updateEntity()
  {    
    await UpdatePatient(editedEntity, setResultMessage, props.refresh);
  }


  function updateEditedParameter(newValue, parameter)
  {
    setFormEdited(true);

    if (parameter === 'last_name' || parameter === 'first_name'){
      newValue = newValue.toUpperCase()
    }
    setErrorStates( ( error ) => ({...error, [parameter]: InputValidationErrorStates.NoError }));
    setEditedEntity(editedEntity => ({...editedEntity, [parameter]:newValue}));
  }

  function closeModal()
  {
    setFormEdited(false);
    setResultMessage("Writing...");
    clearErrorStates();
    setEditedEntity([]);
    props.close();
  }

  function onSelectChanged(value, parameter){
    setEditedEntity(editedEntity => ({ ...editedEntity, [parameter]:value }));
  }

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}>
      <Modal 
        show={props.show}
        backdrop="static"
        centered
        size="xl">
        <UpdateEntityModalHeader
          icon={<LuBaby className='ModalHeaderIcon' size={20}/>}
          headerLabel={props.labelPrefix + " patient"}
          close={ closeModal }/>
        
        <Modal.Body>
          <label className={modalState === ModalStates.ResultMessage ? 'ResultMessage' : 'InvisibleCollapse'}>{resultMessage}</label>
          <Container className={modalState === ModalStates.ResultMessage ? 'InvisibleCollapse' : 'null'}>


            <UpdateEntityModalDataLine
                modalState={modalState}
                labelName = "PATIENT ID"
                parameterName = 'patient_id'
                value = {editedEntity.ehr_patient_ref}
                disabled/>

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName = "LAST NAME"
              parameterName = 'last_name'
              value = {editedEntity.last_name}
              inputChanged={updateEditedParameter}
              required
              entryMaxLength={ PatientLastNameMaxLength }
              errorState={errorStates.last_name}/>
              

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName = "FIRST INITIAL"
              parameterName = 'first_name'
              value = {editedEntity.first_name}
              inputChanged={updateEditedParameter}
              required
              entryMaxLength={PatientFirstNameMaxLength}
              errorState={errorStates.first_name}/>

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName = "GESTATIONAL AGE"
              parameterName = 'gestational_age'
              value = {editedEntity.gestational_age}
              required
              type='number'
              step='0.1'
              max={ PatientGestationalAgeMaxValue }
              min={ PatientGestationalAgeMinValue }
              inputChanged={updateEditedParameter}
              errorState={errorStates.gestational_age}/>

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName = "CONCEPTUAL AGE"
              parameterName = 'conceptual_age'
              value = { editedEntity.conceptual_age }
              required
              type='number'
              step='0.1'
              min={ PatientConceptualAgeMinValue }
              max={ PatientConceptualAgeMaxValue }
              inputChanged={updateEditedParameter}
              errorState={errorStates.conceptual_age}/>

            <UpdateEntityModalDataLineAndPicker
              modalState={modalState}
              labelName = "BIRTH WEIGHT"
              parameterName = 'birth_weight'
              selectName = 'weight_uom'
              value = {editedEntity.birth_weight}
              required
              type='number'
              step='0.1'
              min= { PatientBirthWeightMinValue }
              max={ PatientBirthWeightMaxValue }
              inputChanged={updateEditedParameter}
              valueForSelect={editedEntity.weight_uom}
              choices={ WeightUnits }
              selectChange={onSelectChanged}
              errorState={errorStates.birth_weight}/>

            <UpdateEntityModalDataSwitch
              modalState={modalState}
              labelName = "ACTIVE"
              parameterName = 'patient_status'
              value = {editedEntity.patient_status}
              inputChanged={updateEditedParameter}/>
          
            

          </Container>
          <div className={IsConfirmState(modalState) ? 'null': 'InvisibleCollapse'}>
            <hr className='ThickLine'></hr>
            <label className="ConfirmHeader">Are you sure you want to save your changes?</label>
            <div className={modalState === ModalStates.ConfirmAdd ? 'InvisibleCollapse' : 'null'}>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"LAST NAME"}
                originalParameter={props.entityToUpdate.last_name}
                updatedParameter={editedEntity.last_name}/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"FIRST INITIAL"}
                originalParameter={props.entityToUpdate.first_name}
                updatedParameter={editedEntity.first_name}/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"GESTATIONAL AGE"}
                originalParameter={props.entityToUpdate.gestational_age}
                updatedParameter={editedEntity.gestational_age}/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"CONCEPTUAL AGE"}
                originalParameter={ props.entityToUpdate.conceptual_age }
                updatedParameter={ editedEntity.conceptual_age }/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"BIRTH WEIGHT"}
                // originalParameter={ `${props.entityToUpdate.birth_weight} ${props.entityToUpdate.weight_uom}` }
                // updatedParameter={ `${editedEntity.birth_weight} ${editedEntity.weight_uom}` }/>
                originalParameter={props.entityToUpdate.birth_weight }
                updatedParameter={ editedEntity.birth_weight}/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"WEIGHT UNIT"}
                originalParameter={ props.entityToUpdate.weight_uom }
                updatedParameter={ editedEntity.weight_uom }/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"STATUS"}
                originalParameter={props.entityToUpdate.patient_status}
                updatedParameter={editedEntity.patient_status}/>
            </div>
          </div>
        </Modal.Body>

        <UpdateEntityModalFooter
          modalState={modalState}
          setState={setModalState}
          updateEntity={updateEntity}
          validateInputs={validateInputs}
          close={closeModal}
          refresh={props.refresh}
          active = { formEdited }/>
      </Modal>
    </div>
  );

};

export default UpdatePatientModal;