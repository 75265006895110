import { MillisOnMinute } from "../../../utilities/Constants";
import { PutApi, UpdateAFacility } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";

async function UpdateFacility(
  editedEntity,
  setResultMessage,
  refreshAction)
{
  const payload = 
  {
    facility: {
      facility_id: editedEntity.id,
      name: editedEntity.name,
      facility_type: editedEntity.facility_type,
      logo: editedEntity.logo,
      web_timeout_session: (editedEntity.web_timeout_session * MillisOnMinute ),
      mobile_timeout_session: (editedEntity.mobile_timeout_session * MillisOnMinute ),
      address_1: editedEntity.address_1,
      address_2: editedEntity.address_2,
      city: editedEntity.city,
      state: editedEntity.state,
      zip: editedEntity.zip,
      client_id: editedEntity.client_id,
      status : editedEntity.status
    }
  }

  await PutApi(UpdateAFacility, payload)
  .then(response => response.json())
  .then(output => WriteResultMessage(output, setResultMessage))
  .then(refreshAction);
}
export default UpdateFacility;