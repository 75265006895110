import { FormatMinAndSec } from '../../../utilities/FormatDate'
import ellipsis from '../../../utilities/Ellipsis'

export const FlagsSection = ({ flags = [] }) => {
    return (
        <div className="d-flex flex-column patient-card-flags-details-width">
            <p className="m-0">Flags</p>

            <div className="mt-3 d-flex w-fit">
                <div className="ms-1 flags-bar"></div>
                <div className="ms-3 d-flex flex-column position-relative">
                    {flags.map((flag) =>
                        <div className="mb-3" key={flag.timetick}>
                            <div className="flags-dot"></div>
                            <p className="text-base lh-sm font-bold mb-0">{FormatMinAndSec(flag.timetick)}</p>
                            <p className="text-base lh-sm fw-normal mb-0 text-truncate">{ ellipsis(flag.event_type, 11) || 'Flag' }</p>
                        </div>)
                    }

                    {/* <div className="mb-3">
                        <div className="flags-dot"></div>
                        <p className="text-muted text-base lh-sm font-semibold mb-0">08:37</p>
                        <p className="text-muted text-base lh-sm fw-normal mb-0 text-truncate">Other ( Des...</p>
                    </div>
                    <div className="mb-3">
                        <div className="flags-dot"></div>
                        <p className="text-muted text-base lh-sm font-semibold mb-0">08:37</p>
                        <p className="text-muted text-base lh-sm fw-normal mb-0 text-truncate">Drip/Drool</p>
                    </div>
                    <div className="mb-3">
                        <div className="flags-dot"></div>
                        <p className="text-muted text-base lh-sm font-semibold mb-0">08:37</p>
                        <p className="text-muted text-base lh-sm fw-normal mb-0 text-truncate">Gagging</p>
                    </div>
                    <div className="mb-3">
                        <div className="flags-dot"></div>
                        <p className="text-muted text-base lh-sm font-semibold mb-0">08:37</p>
                        <p className="text-muted text-base lh-sm fw-normal mb-0 text-truncate">Tachypneic</p>
                    </div>
                    <div className="mb-3">
                        <div className="flags-dot"></div>
                        <p className="text-muted text-base lh-sm font-semibold mb-0">08:37</p>
                        <p className="text-muted text-base lh-sm fw-normal mb-0 text-truncate">Out of mouth...</p>
                    </div>
                    <div className="">
                        <div className="flags-dot"></div>
                        <p className="text-muted text-base lh-sm font-semibold mb-0">08:37</p>
                        <p className="text-muted text-base lh-sm fw-normal mb-0 text-truncate">Other ( Des...</p>
                    </div> */}
                </div>

            </div>

        </div>
    )
}
