import './ValidatedFieldEntry.css';
import '../../styles/Shared.css'
import Row from 'react-bootstrap/Row';

import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";

import {useState} from 'react';

import { IsErrorState, GetInputValidationErrorText } from '../Modals/UpdateEntityModals/InputValidation';


function ValidatedFieldEntry(props) {

  const [hidePassword, setHidePassword] = useState(props.isPassword === true)

  
  function toggleHidePassword()
  {
    setHidePassword(!hidePassword);
  }

  function showPasswordIcon(targetHidePasswordState)
  {
    if (props.isPassword !== true)
    {
      return 'InvisibleCollapse';
    }

    if (hidePassword === targetHidePasswordState)
    {
      return 'ShowPasswordIcon'
    }
    return 'InvisibleCollapse';
  }

  return (
    <div>
      <Row className='AddEntityModalContainer'>
        <div>
          <label className="ValidatedFieldLabel">{props.labelName}</label>
          <label className={props.required === true ? 'RequiredStar' : 'InvisibleCollapse'}>*</label>
        </div>
        <span>
          <input className={IsErrorState(props.errorState) ? 'FieldEntryError' : 'FieldEntry'}
            // disabled={() => props.getTextEntryDisabled()}
            placeholder={props.placeholder}
            type={hidePassword === true ? "password": "text"}
            maxLength={props.entryMaxLength}
            minLength={props.entryMinLength}
            value={props.value ?? ""}
            onChange={e => props.inputChanged(e.target.value)}/>

          <AiOutlineEye
            className={showPasswordIcon(true)}
            onClick={() => toggleHidePassword()}
            size={20}/>

          <AiOutlineEyeInvisible
            className={showPasswordIcon(false)}
            onClick={() => toggleHidePassword()}
            size={20}/>
        </span>
        
        <label className={IsErrorState(props.errorState) ? 'InputValidationErrorMessage' : 'InvisibleCollapse'}>
          {GetInputValidationErrorText(props.errorState, props.labelName, null, null, props.entryMinLength, props.entryMaxLength)}
        </label>
      </Row>
    </div>
  );
}

export default ValidatedFieldEntry;