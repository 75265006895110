import { PaginatedTable } from '../PaginatedTable/PaginatedTable';
import { FaRegHospital } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { LuEye } from "react-icons/lu";
import { Link, useNavigate } from 'react-router-dom';
import { GetFacilityName } from '../../data_layer/repositories/Facilities/FacilityTypes';
import FormatDate from '../../utilities/FormatDate';
import { color } from 'chart.js/helpers';
import { useState } from 'react';



function FacilitiesTable(props) {

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => GetFacilityName(row.facility_type),
      sortable: true,
    },
    {
      name: 'Address',
      selector: row => `${row.address_1}, ${row.city}, ${row.state} ${row.zip}`,
      width: '350px',
      sortable: true,
    },
    {
      name: `Web Timeout (min)`,
      selector: row => row.web_timeout_session,
      sortable: true,
      width: '150px',
    },
    {
      name: `Mobile Timeout (min)`,
      selector: row => row.mobile_timeout_session,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Date created',
      selector: row => FormatDate(row.created_at),
      sortable: true,
      width: '170px',
    },
    {
      name: 'Date updated',
      selector: row => FormatDate(row.updated_at),
      sortable: true,
      width: '170px',
    },
    {
      name: 'Active',
      selector: row => row.status === 'active' ? 'Yes' : 'No', 
      sortable: true,
      width: '100px',
    },
    {
      name: '',
      selector: (row) => <Link className="neo-icon icon-link icon-link-hover"
        to={`${row.name}/${row.id}`}>
        <LuEye size={21} />
      </Link>,
      width: '55px',
    },
    {
      name: '',
      selector: (row) => <Link className="neo-icon icon-link icon-link-hover"
        onClick={() => props.onEditClicked(row)}>
        <FiEdit2 size={18} />
      </Link>,
      width: '50px',
    },
  ];

  const sortedData = props.data.sort((a, b) => {
    // Sort by status first ('active' before 'inactive')
    if (a.status === b.status) {
      // If statuses are the same, sort by name alphabetically
      return a.name.localeCompare(b.name);
    }
    // If statuses are different, sort 'active' before 'inactive'
    return a.status === 'active' ? -1 : 1;
  });

  

  return (
    <PaginatedTable
      name="Facilities"
      icon={<FaRegHospital size={25} />}
      data={sortedData}
      columns={columns}
      onEditClicked={props.onEditClicked}
      buttonLabel="Add facility"
      onButtonClick={props.addEntityClicked} 
      />
  )
}

export default FacilitiesTable;