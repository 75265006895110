import { GetApi, GetFlagsByFeedingSessionPath } from "../../apis/Api";

async function GetFlags(feeding_session_timestamp) {

  var flags = await GetApi(GetFlagsByFeedingSessionPath + "/" + feeding_session_timestamp)
    .then(response => response.json())
    .then(data => data.result );
 
  return flags;
};

export default GetFlags;