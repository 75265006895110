

export const BiomarkersSection = ({biomarkers = []}) => {

  const showBiomarker = (title, value) => {
    return <div className="w-100 py-1 mb-1">
          <p className="text-sm fw-semibold mb-0">{title}</p>
          <div className="d-flex align-items-center gap-2">
            <p className="mb-0 lh-sm fw-semibold text-base">{value}</p>
            {/* POSITIVE <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
              <path d="M11.8334 0L13.7417 1.90833L9.67502 5.975L6.34169 2.64167L0.166687 8.825L1.34169 10L6.34169 5L9.67502 8.33333L14.925 3.09167L16.8334 5V0H11.8334Z" fill="#429646" />
            </svg> */}
          </div>
        </div>

        {/* Negative
          
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
              <path d="M11.8334 10L13.7417 8.09167L9.67502 4.025L6.34169 7.35833L0.166687 1.175L1.34169 0L6.34169 5L9.67502 1.66667L14.925 6.90833L16.8334 5V10H11.8334Z" fill="#E95751" />
            </svg>
`
          */}
  }

  return (
    <div className="d-flex flex-column patient-card-biomarkers-details-width me-3 pe-1">
      <p className="m-0">Biomarkers</p>

      <div className="mt-3 d-flex flex-column w-fit">
        {showBiomarker( "Maturation",  biomarkers.find((b) => b.name === 'MATURATION')?.value)}
        {showBiomarker( "Pres. Max",  biomarkers.find((b) => b.name === 'PRESSURE-MAX')?.value)}
        {showBiomarker( "Ave # Sucks*",  biomarkers.find((b) => b.name === 'SUCKS-PER-BURST')?.value)}
        {showBiomarker( "Sucks/sec*",  biomarkers.find((b) => b.name === 'SUCKS-PER-SWALLOW')?.value)}
        {showBiomarker( "Breath/sec*",  biomarkers.find((b) => b.name === 'BREATHS-IN-BURSTS')?.value)}
        {showBiomarker( "Breath/sec**",  biomarkers.find((b) => b.name === 'BREATHS-IN-RESTS')?.value)}
      </div>
    </div>
  );
}
