import React, { useContext, useState } from 'react'
import { AuthContext } from '../../auth';
import PatientsTable from '../../components/Tables/PatientsTable';
import UpdatePatientModal from '../../components/Modals/UpdateEntityModals/UpdatePatientModal';
import { useGetPatientsByFaciltiy } from '../../components/CustomHooks/useGetPatientsByFacility';
import { Loading } from '../../components/Loading/Loading';
import { useParams } from 'react-router-dom';

export const PatientsAdminPage = () => {

  //const [patients, setPatients] = useState([]);
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [patientToUpdate, setPatientToUpdate] = useState({});

  const {name, facility_id} = useParams();

  const {facility} = useContext( AuthContext );

  const { isLoading, patients, reloadData } = useGetPatientsByFaciltiy(facility_id || facility.id);

  const getPatients = () => {
    reloadData();
  }

  const patientRowClicked = (patient) => {
    setPatientToUpdate(patient);
    setShowPatientModal(true)
  }

  const closeUpdatePatientModal = () => {
    setPatientToUpdate({});
    setShowPatientModal(false);
  }

  return (
    <>
      <div>
        <div className='neo_card_container neo_ut_mt_24_mob'>
          {
            isLoading && <Loading isLoading={isLoading}/>
          }
          {
            !isLoading && <PatientsTable
              onRowClicked={ patientRowClicked }
              data={ patients }
              facility={name}/>
          }
          
        </div>

        <UpdatePatientModal 
          show={showPatientModal}
          labelPrefix= 'Edit'
          close={closeUpdatePatientModal}
          entityToUpdate={patientToUpdate}
          refresh={getPatients}
        />
      </div>
    </>
  )
}
