import '../../styles/Shared.css';
import '../../styles/Pages.css';
import { useState, useEffect } from "react";

import FacilitiesTable from '../../components/Tables/FacilitiesTable';

import UpdateFacilityModal from '../../components/Modals/UpdateEntityModals/UpdateFacilityModal';

import GetFacilities from '../../data_layer/repositories/Facilities/GetFacilities';
import USStates from '../../utilities/USStates';
import FacilityTypes from '../../data_layer/repositories/Facilities/FacilityTypes';
import { useGetFacilities } from '../../components/CustomHooks/useGetFacilities';
import { Loading } from '../../components/Loading/Loading';



export const FacilitiesPage = () => {

    const [showUpdateFacilityModal, setShowUpdateFacilityModal] = useState(false);

    //const [facilities, setFacilities] = useState([]);

    const [facilityToUpdate, setFacilityToUpdate] = useState([]);

    const [labelPrefix, setLabelPrefix] = useState("");

    const { facilities, isLoading, reloadData } = useGetFacilities();

    function closeUpdateFacilityModal() {
        setLabelPrefix("");
        setFacilityToUpdate([]);
        setShowUpdateFacilityModal(false);
    }

    function refreshFacilities() {
        reloadData();
    }

    function facilityEditClicked(row) {
        setLabelPrefix("Edit ");
        setFacilityToUpdate(row);
        setShowUpdateFacilityModal(true);
    }

    function addFacilityClicked() {
        setLabelPrefix("Add ");
        const empty = getEmptyFacility();
        setFacilityToUpdate(empty);
        setShowUpdateFacilityModal(true);
    }

    function getEmptyFacility() {
        return {
            name: "",
            facility_type: "",
            logo: "",
            web_timeout_session: 5,
            mobile_timeout_session: 5,
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            zip: "",
            client_id: "55f9eb54-d7ed-4132-8314-8cdb749c8c43"
        };
    }

    return (
        <>
            <div className='neo_card_container neo_ut_mt_24_mob'>
                {
                    isLoading && <Loading isLoading={isLoading} />
                }

                {
                    facilities && !isLoading &&
                    <FacilitiesTable
                        onEditClicked={facilityEditClicked}
                        data={facilities}
                        addEntityClicked={addFacilityClicked} />
                }

            </div>


            <UpdateFacilityModal
                show={showUpdateFacilityModal}
                labelPrefix={labelPrefix}
                close={closeUpdateFacilityModal}
                entityToUpdate={facilityToUpdate}
                refresh={refreshFacilities}
                states={USStates}
                facilityTypes={FacilityTypes} />

        </>
    )
}
