import PatientExistsByEhr from "../../../data_layer/repositories/Patients/PatientExistsByEhr";
import { InputValidationErrorStates } from "../UpdateEntityModals/InputValidation";

async function CheckPatientExistsByEhr(ehr_patient_ref, facility_id, patient_id) {

  const result = await PatientExistsByEhr(ehr_patient_ref, facility_id, patient_id);

  if (result === true)
  {
    return InputValidationErrorStates.NotAvailable;
  }
  return InputValidationErrorStates.NoError;
}

export default CheckPatientExistsByEhr;