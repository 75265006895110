import { PaginatedTable } from '../PaginatedTable/PaginatedTable';
import { FiEdit2 } from "react-icons/fi";
import { TbBabyBottle } from "react-icons/tb";


function HandheldsTable(props) {

  const columns = [
    {
      name: 'Serial number',
      selector: row => row.serial_number,
      sortable: true,
    },
    {
      name: 'Friendly name',
      selector: row => row.friendly_name,
      sortable: true,
    },
    {
      name: 'Model',
      selector: row => row.model,
      sortable: true,
    },
    {
      name: 'Software version',
      selector: row => row.software_version,
      sortable: true,
    },
  ];

  if (props.isNeoneurAdmin) {
    columns.push(

      {
        name: 'Facility',
        selector: row => row.facility_name,
        sortable: true,
      },
      {
        name: 'Pairing code',
        selector: row => `****${row.pairing_code.substring(row.pairing_code.length - 10)}`,
        sortable: true,
        width: '200px',
      },
      {
        name: 'Active',
        selector: row => row.status === 'active' ? 'Yes' : 'No',
        sortable: true,
        width: '100px',
      },
      {
        name: '',
        selector: (row) => <a className="neo-icon icon-link icon-link-hover"
          onClick={() => props.onRowClicked(row)}
          href='#'>
          <FiEdit2 size={18} />
        </a>,
        width: '50px',
      },
    );
  }

  const sortedData = props.data.sort((a, b) => {
    // Sort by status first ('active' before 'inactive')
    if (a.status === b.status) {
      // If statuses are the same, sort by name alphabetically
      return a.serial_number.localeCompare(b.serial_number);
    }
    // If statuses are different, sort 'active' before 'inactive'
    return a.status === 'active' ? -1 : 1;
  });

  return (
    <PaginatedTable
      name="Handhelds"
      icon={<TbBabyBottle size={25} />}
      data={sortedData}
      columns={columns}
      onRowClicked={props.onRowClicked}
      buttonLabel={props.isNeoneurAdmin ? "Add handheld" : ""}
      onButtonClick={props.isNeoneurAdmin ? props.addEntityClicked : null}
    />
  )
}

export default HandheldsTable;