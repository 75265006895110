import { useContext, useEffect, useState } from "react";
import GetPatientList from "../../data_layer/repositories/Patients/GetPatientList";
import { AuthContext } from '../../auth';

export const useGetPatientList = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [patients, setPatients] = useState([]);

    const { facility } = useContext(AuthContext);

    useEffect(() => {
        getPatients();

    }, [])

    const getPatients = async () => {
        setIsLoading(true);
        const response = await GetPatientList(facility.id);

        setPatients(response.sort((a, b) => a.last_feeding < b.last_feeding ? 1 : -1));
        setIsLoading(false);
    }

    return {
        isLoading,
        patients,
    }
}
