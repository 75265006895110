import { PutApi, UpdateAPatient } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";
import CalculateDateOfConception from "../../../utilities/CalculateDateOfConception";

async function UpdatePatient(
  editedPatient,
  setResultMessage,
  refreshAction)
{
  const fullName = editedPatient.last_name + ", " + editedPatient.first_name;
  const calculateDateOfConception = CalculateDateOfConception(editedPatient.conceptual_age);

  const payload = 
  {
    patient: {
      patient_id: editedPatient.patient_id,
      ehr_patient_ref: editedPatient.ehr_patient_ref,
      name: fullName,
      gestational_age: editedPatient.gestational_age,
      birth_weight: editedPatient.birth_weight,
      weight_uom: editedPatient.weight_uom,  
      date_of_conception: calculateDateOfConception,
      user_id: editedPatient.user_id,
      facility_id: editedPatient.facility_id,
      mobile_serial_number: editedPatient.mobile_serial_number,
      admission_status: editedPatient.admission_status,
      note: editedPatient.note,
      patient_status: editedPatient.patient_status,
    }
  }

  PutApi(UpdateAPatient, payload)
    .then(response => response.json())
    .then(output => WriteResultMessage(output, setResultMessage))
    .then(refreshAction);
 
}
export default UpdatePatient;