import '../ReviewConflictsModal/ReviewConflictsModalDataLine.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiAlertCircle } from "react-icons/fi"

function ResolveMissingDataEditConfirmDataLine(props) {
  return (
    <Row className={props.dataWasMissing ? 'EditConfirmRow' : 'InvisibleCollapse'}>
      <Col className='col-sm-1'>
        <FiAlertCircle className='DataRowIcon ' />

      </Col>
      <Col className='col-sm-4'>
        <label className='HighlightedDataLabel col-form-label'>{props.label}</label>
      </Col>
      <Col className='col-sm-4'>
        <input className='CircledDataValue' defaultValue={props.patientEditedParameter} />
      </Col>
      <Col className='col-sm-2'>
        <label className='HighlightedDataValue col-form-label'>{props.units}</label>
      </Col>
    </Row>
  );
}

export default ResolveMissingDataEditConfirmDataLine;
