import { GetAllHandheldsPath, GetApi, GetHandheldsByFacilityPath } from "../../apis/Api";


async function GetHandheldsByFacility(facility_id) {

    const handhelds = await GetApi(GetHandheldsByFacilityPath(facility_id))
        .then(response => response.json())
        .then(data => data.result);

    return handhelds;

}

export default GetHandheldsByFacility;