import '../../styles/Shared.css';
import '../../styles/Pages.css';
import { useState } from "react";

import UsersTable from '../../components/Tables/UsersTable';

import UpdateUserModal from '../../components/Modals/UpdateEntityModals/UpdateUserModal';

import { ResetPasswordModal } from '../../components/Modals/ResetPasswordModal';
import { useGetFacilities } from '../../components/CustomHooks/useGetFacilities';
import { useGetUsers } from '../../components/CustomHooks/useGetUsers';
import { Loading } from '../../components/Loading/Loading';
import { Roles } from '../../data_layer/repositories/Users/UserRoles';

function UsersPage() {

  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [resetPasswordList, setResetPasswordList] = useState([]);
  //const [users, setUsers] = useState([]);
  const [userToUpdate, setUserToUpdate] = useState([]);
  const [labelPrefix, setLabelPrefix] = useState("");

  const { facilities } = useGetFacilities();

  const { users, isLoading, reloadData } = useGetUsers()


  function closeUpdateUserModal() {
    setLabelPrefix("");
    setUserToUpdate([]);
    setShowUpdateUserModal(false);
  }

  function refreshUsers() {
    reloadData();
  }

  function onEditUserClicked(user) {
    setLabelPrefix("Edit ");
    setUserToUpdate(user);
    setShowUpdateUserModal(true);
  }

  function onResetUserPasswordClicked(user) {
    setResetPasswordList(oldList => [...oldList, <ResetPasswordModal user={user} />]);
  }

  function addUserClicked() {
    setLabelPrefix("Add ");
    const empty = getEmptyUser();
    setUserToUpdate(empty);
    setShowUpdateUserModal(true);
  }

  function getEmptyUser() {
    return {
      username: "",
      user_id: "",
      login_id: "",
      role: "", //UserRolesNeoneurAdmin[0].value,
      email: "",
      passcode_before_encrypt: "",
      facility_name: "", //facilities[0].name,
      facility_id: "", //facilities[0].id,
    };
  }

  function getFacilitiesAsOptions() {
    const options = []
    facilities.filter((facility) => facility.status === 'active')
      .forEach((facility) => options.push({ "name": facility.name, "value": facility.id }));
    return options
  }

  function getUsers(){
    return users.filter((item) => item.role === Roles.ItAdmin || item.role == Roles.NeoneurAdmin)
  }

  return (
    <div>
      {resetPasswordList}
      <div className='neo_card_container neo_ut_mt_24_mob'>

        {
          isLoading && <Loading isLoading={isLoading} />
        }

        {
          !isLoading &&
          <UsersTable
            onEditUserClicked={onEditUserClicked}
            onResetUserPasswordClicked={onResetUserPasswordClicked}
            data={getUsers()}
            addEntityClicked={addUserClicked} />
        }

      </div>


      <UpdateUserModal
        show={showUpdateUserModal}
        labelPrefix={labelPrefix}
        close={closeUpdateUserModal}
        facilities={getFacilitiesAsOptions()}
        entityToUpdate={userToUpdate}
        users={users}
        refresh={refreshUsers} />
    </div>
  )
}

export default UsersPage;