import "../../../styles/Shared.css";
import "../../../styles/Modals.css";
import './ReviewConflictsModalDataLine.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FiAlertCircle} from "react-icons/fi"

import {ModalStates} from './ReviewConflictModalStates';
import { IsErrorState, GetInputValidationErrorText } from "../UpdateEntityModals/InputValidation";


function ReviewConflictsModalDataLine(props) {

  function getDataRowIconStyle(hasConflict, modalState)
  {
    switch(modalState) {
      case ModalStates.Review:
      case ModalStates.Merge:
        return hasConflict === true ? 'DataRowIcon' : 'InvisibleCollapse';
      case ModalStates.ConfirmMerge:
        return hasConflict === true ? 'DataRowIconDisabled' : 'InvisibleCollapse';
      case ModalStates.ConfirmEdit:
        return 'InvisibleCollapse';
      case ModalStates.Edit:
        return hasConflict === true ? 'InvisibleCollapse' : 'DataRowIcon';
      default:
        return 'InvisibleCollapse';
    }
  }

  function getDataLabelStyle(hasConflict, modalState)
  {

    switch(modalState) {
      case ModalStates.Review:
      case ModalStates.Merge:
        return hasConflict === true ? 'HighlightedDataLabel' : 'NormalDataLabel';
      case ModalStates.Edit:
      case ModalStates.ConfirmEdit:
        return hasConflict === true ? 'NormalDataLabel' : 'HighlightedDataLabel'; 
      case ModalStates.ConfirmMerge:
        return 'NormalDataLabel';
      default:
        return 'InvisibleCollapse';
    }
  }

  function getDataRowRadioButtonStyle(hasConflict, modalState)
  {
    if (hasConflict === false) {
      return 'InvisibleCollapse';
    }

    switch(modalState) {
      case ModalStates.Review:
      case ModalStates.Edit:
      case ModalStates.ConfirmEdit:
        return 'InvisibleCollapse';
      case ModalStates.Merge:
      case ModalStates.ConfirmMerge:
        return 'DataRowRadioButton';
      default:
        return 'InvisibleCollapse';
    }
  }

  function getDataValueStyle(hasConflict, modalState)
  {
    

    switch(modalState) {
      case ModalStates.Review:
      case ModalStates.Merge:
        return hasConflict === true ? 'HighlightedDataValue' : 'NormalDataValue';
      case ModalStates.Edit:
        return hasConflict === true ? 'NormalDataValue' : 'InvisibleCollapse'; 
      case ModalStates.ConfirmEdit:
        return hasConflict === true ? 'NormalDataValue' : 'HighlightedDataValue';
      case ModalStates.ConfirmMerge:
        return 'NormalDataValue';
      default:
        return 'InvisibleCollapse';
    }
  }

  function getTextEntryStyle(errorState)
  {
    if (getDataValueStyle(props.conflict, props.modalState) === 'InvisibleCollapse')
    {
      if (IsErrorState(errorState))
      {
        return 'TextEntryValueError';
      }
      return 'TextEntryValue';
    }
    return 'InvisibleCollapse';
  }

  function getErrorMessageStyle(errorState)
  {
    if (props.modalState !== ModalStates.Edit)
    {
      return 'InvisibleCollapse';
    }

    // TODO: also base on modal state.
    return IsErrorState(errorState) ? 'InputValidationErrorMessage' : 'InvisibleCollapse';
  }

  return (
    <Row>
      
      <Col className="col-sm-1">
        <FiAlertCircle className={getDataRowIconStyle(props.conflict, props.modalState)}/></Col>
      <Col className="col-sm-4">
        
        <label className={ getDataLabelStyle(props.conflict, props.modalState) }>{props.labelName}</label>
      </Col>
      <Col  className="col-sm-3">
        <input
          className={getTextEntryStyle(props.originalPatientErrorState)}
          type={ props.type ? props.type : 'text' }
          min={ props.minValue }
          max={ props.maxValue }
          maxLength={props.entryMaxLength}
          defaultValue={props.originalPatientParameter}
          onChange={e => props.textEntryChanged(true, props.parameterName, e.target.value)}/>

        <label className={  getDataValueStyle(props.conflict, props.modalState) }>
          <input className={getDataRowRadioButtonStyle(props.conflict, props.modalState)}
            disabled={props.modalState === ModalStates.ConfirmMerge}
            type="radio"
            name={props.labelName}
            defaultChecked
            value={props.originalPatientParameter}
            onChange={e => props.radioButtonChecked(props.parameterName, e.target.value)}>
          </input>
          { props.originalPatientParameter } { props.units }
        </label>

        {/* <label className={getDataValueStyle(props.conflict, props.modalState)/* === 'InvisibleCollapse' ?
           'TextEntryValue' : 'InvisibleCollapse'}
           >{props.units}
          </label> */}

        <label className={getErrorMessageStyle(props.originalPatientErrorState)}>
          {GetInputValidationErrorText(props.originalPatientErrorState, props.labelName)}
        </label>
      </Col>
      <Col  className="col-sm-3">
        <input
          className={getTextEntryStyle(props.patientErrorState)}
          type={ props.type ? props.type : 'text' }
          min={ props.minValue }
          max={ props.maxValue }
          maxLength={props.entryMaxLength}
          defaultValue={props.patientParameter}
          onChange={e => props.textEntryChanged(false, props.parameterName, e.target.value)}/>

        {/* <label className={getDataValueStyle(props.conflict, props.modalState)/* === 'InvisibleCollapse' ?
        'TextEntryValue' : 'InvisibleCollapse'}}>
          {props.units}
        </label> */}

        <label className={ getDataValueStyle(props.conflict, props.modalState) }>
          <input className={getDataRowRadioButtonStyle(props.conflict, props.modalState)}
            disabled={props.modalState === ModalStates.ConfirmMerge}
            type="radio"
            name={props.labelName}
            value={props.patientParameter}
            onChange={e => props.radioButtonChecked(props.parameterName, e.target.value)}>
          </input>
          {props.patientParameter}{props.units}
        </label>

        <label className={getErrorMessageStyle(props.patientErrorState)}>
          {GetInputValidationErrorText(props.patientErrorState, props.labelName)}
        </label>
      </Col>
    </Row>
  );
}

export default ReviewConflictsModalDataLine;