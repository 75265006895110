import '../../../styles/Shared.css'

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { useState, useEffect } from 'react'
import { FaRegHospital } from "react-icons/fa";

import { IsAddState, IsConfirmState, ModalStates } from './UpdateEntityModalStates';

import UpdateEntityModalDataLine from './UpdateEntityModalDataLine';
import UpdateEntityModalEditConfirmDataLine from './UpdateEntityModalEditConfirmDataLine';
import UpdateEntityModalHeader from './UpdateEntityModalHeader';
import UpdateEntityModalFooter from './UpdateEntityModalFooter';

import AddFacility from '../../../data_layer/repositories/Facilities/AddFacility';
import UpdateFacility from '../../../data_layer/repositories/Facilities/UpdateFacility';

import { InputValidationErrorStates, CheckIsMissing, HasErrors, CheckIsNumeric, CheckIsNumericAndMinMaxValue, CheckIsMissingAndLength } from './InputValidation';
import UpdateEntityModalDataPicker from './UpdateEntityModalDataPicker';
import USStates, { GetUSStateName } from '../../../utilities/USStates';
import FacilityTypes, { GetFacilityName } from '../../../data_layer/repositories/Facilities/FacilityTypes';
import { MinTimeoutSession, MaxTimeoutSession, FacilityNameMaxLength, FacilityAddressMaxLength, FacilityZipCodeMaxLength, FacilityZipCodeMinLength } from '../../../utilities/Constants';
import UpdateEntityModalDataSwitch from './UpdateEntityModalDataSwitch';


function UpdateFacilityModal(props) {

  const [modalState, setModalState] = useState(ModalStates.Edit);
  const [editedEntity, setEditedEntity] = useState([]);
  const [resultMessage, setResultMessage] = useState("Writing...");
  const [errorStates, setErrorStates] = useState([]);

  const [formEdited, setFormEdited] = useState(false);

  useEffect(() => {
    clearErrorStates();
    setEditedEntity({ ...props.entityToUpdate });
  }, [props.entityToUpdate]);

  useEffect(() => {
    if (props.labelPrefix === "Edit ") {
      setModalState(ModalStates.Edit);
    }

    if (props.labelPrefix === "Add ") {
      setModalState(ModalStates.Add);
    }
  }, [props.labelPrefix]);

  function clearErrorStates() {
    setErrorStates(
      {
        name: InputValidationErrorStates.NoError,
        address_1: InputValidationErrorStates.NoError,
        city: InputValidationErrorStates.NoError,
        zip: InputValidationErrorStates.NoError,
        client_id: InputValidationErrorStates.NoError,
        web_timeout_session: InputValidationErrorStates.NoError,
        mobile_timeout_session: InputValidationErrorStates.NoError,
        state: InputValidationErrorStates.NoError,
        facility_type: InputValidationErrorStates.NoError,
      }
    )
  }

  function validateInputs() {
    const nameErrorState = CheckIsMissingAndLength(editedEntity.name, null, FacilityNameMaxLength);
    const addressErrorState = CheckIsMissingAndLength(editedEntity.address_1, null, FacilityAddressMaxLength);
    const cityErrorState = CheckIsMissing(editedEntity.city);
    const zipErrorState = CheckIsMissingAndLength(editedEntity.zip, FacilityZipCodeMinLength, FacilityZipCodeMaxLength)

    const clientIdErrorState = CheckIsMissing(editedEntity.client_id);
    const webSessionTimeoutErrorState = CheckIsNumericAndMinMaxValue(editedEntity.web_timeout_session, MinTimeoutSession, MaxTimeoutSession);
    const mobileSessionTimeoutErrorState = CheckIsNumericAndMinMaxValue(editedEntity.mobile_timeout_session, MinTimeoutSession, MaxTimeoutSession);

    const stateErrorState = CheckIsMissing(editedEntity.state);

    const typeErrorState = CheckIsMissing(editedEntity.facility_type);

    setErrorStates({
      ...errorStates,
      name: nameErrorState,
      address_1: addressErrorState,
      city: cityErrorState,
      zip: zipErrorState,
      client_id: clientIdErrorState,
      web_timeout_session: webSessionTimeoutErrorState,
      mobile_timeout_session: mobileSessionTimeoutErrorState,
      state: stateErrorState,
      facility_type: typeErrorState,
    })

    return !HasErrors([
      nameErrorState,
      clientIdErrorState,
      webSessionTimeoutErrorState,
      mobileSessionTimeoutErrorState,
      addressErrorState,
      cityErrorState,
      zipErrorState,
      typeErrorState,
      stateErrorState
    ]);
  }

  function updateEntity() {
    UpdateFacility(editedEntity, setResultMessage, props.refresh);
  }

  function addEntity() {
    AddFacility(editedEntity, setResultMessage, props.refresh);
  }

  function updateEditedParameter(newValue, parameter) {
    setFormEdited(true);

    setErrorStates((error) => ({ ...error, [parameter]: InputValidationErrorStates.NoError }));
    setEditedEntity(editedEntity => ({ ...editedEntity, [parameter]: newValue }));
  }

  function closeModal() {
    setFormEdited(false);
    setResultMessage("Writing...");
    setEditedEntity([]);
    props.close();
  }

  // function getFacilityTye(facility_value){
  //   const type = FacilityTypes.find((type) => type.value === facility_value)
  //   if (type)
  //     return type.name
  //   return '';
  // }

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}>
      <Modal
        show={props.show}
        backdrop="static"
        centered
        size="xl">
        <UpdateEntityModalHeader
          icon={<FaRegHospital className='ModalHeaderIcon' size={20} />}
          headerLabel={props.labelPrefix + "facility"}
          close={closeModal} />
        <Modal.Body>
          <label className={modalState === ModalStates.ResultMessage ? 'ResultMessage' : 'InvisibleCollapse'}>{resultMessage}</label>
          <Container className={modalState === ModalStates.ResultMessage ? 'InvisibleCollapse' : 'null'}>

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="NAME"
              parameterName='name'
              value={editedEntity.name}
              inputChanged={updateEditedParameter}
              required
              errorState={errorStates.name}
              entryMaxLength={FacilityNameMaxLength} />

            <UpdateEntityModalDataPicker
              modalState={modalState}
              labelName="TYPE"
              parameterName='facility_type'
              required
              choices={FacilityTypes}
              value={editedEntity.facility_type}
              inputChanged={updateEditedParameter}
              errorState={errorStates.facility_type} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="ADDRESS"
              required
              parameterName='address_1'
              value={editedEntity.address_1}
              inputChanged={updateEditedParameter}
              entryMaxLength={FacilityAddressMaxLength}
              errorState={errorStates.address_1} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="ADDRESS 2"
              parameterName='address_2'
              value={editedEntity.address_2}
              inputChanged={updateEditedParameter} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="CITY"
              required
              parameterName='city'
              value={editedEntity.city}
              inputChanged={updateEditedParameter}
              errorState={errorStates.city} />

            <UpdateEntityModalDataPicker
              modalState={modalState}
              labelName="STATE"
              parameterName='state'
              required
              choices={USStates}
              value={editedEntity.state}
              inputChanged={updateEditedParameter}
              errorState={errorStates.state} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="ZIP"
              required
              parameterName='zip'
              value={editedEntity.zip}
              inputChanged={updateEditedParameter}
              errorState={errorStates.zip}
              entryMaxLength={FacilityZipCodeMaxLength}
              entryMinLength={FacilityZipCodeMinLength}
            />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="WEB TIMEOUT"
              helpText="(in minutes)"
              parameterName='web_timeout_session'
              required
              value={editedEntity.web_timeout_session}
              inputChanged={updateEditedParameter}
              errorState={errorStates.web_timeout_session}
              type='number'
              min={MinTimeoutSession}
              max={MaxTimeoutSession} />

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName="MOBILE TIMEOUT"
              helpText="(in minutes)"
              parameterName='mobile_timeout_session'
              required
              value={editedEntity.mobile_timeout_session}
              inputChanged={updateEditedParameter}
              errorState={errorStates.mobile_timeout_session}
              type='number'
              min={MinTimeoutSession}
              max={MaxTimeoutSession} />

            <div className={!IsAddState(modalState) ? 'null' : 'InvisibleCollapse'}>
              <UpdateEntityModalDataSwitch
                modalState={modalState}
                labelName="ACTIVE"
                parameterName='status'
                value={editedEntity.status}
                inputChanged={updateEditedParameter} />
            </div>

          </Container>
          <div className={IsConfirmState(modalState) ? 'null' : 'InvisibleCollapse'}>
            <hr className='ThickLine'></hr>
            <label className="ConfirmHeader">Are you sure you want to save your changes?</label>
            <div className={modalState === ModalStates.ConfirmAdd ? 'InvisibleCollapse' : 'null'}>

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"NAME"}
                originalParameter={props.entityToUpdate.name}
                updatedParameter={editedEntity.name} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"TYPE"}
                originalParameter={GetFacilityName(props.entityToUpdate.facility_type)}
                updatedParameter={GetFacilityName(editedEntity.facility_type)} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"WEB TIMEOUT"}
                originalParameter={props.entityToUpdate.web_timeout_session}
                updatedParameter={editedEntity.web_timeout_session} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"MOBILE TIMEOUT"}
                originalParameter={props.entityToUpdate.mobile_timeout_session}
                updatedParameter={editedEntity.mobile_timeout_session} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"ADDRESS 1"}
                originalParameter={props.entityToUpdate.address_1}
                updatedParameter={editedEntity.address_1} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"ADDRESS 2"}
                originalParameter={props.entityToUpdate.address_2}
                updatedParameter={editedEntity.address_2} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"CITY"}
                originalParameter={props.entityToUpdate.city}
                updatedParameter={editedEntity.city} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"STATE"}
                originalParameter={GetUSStateName(props.entityToUpdate.state)}
                updatedParameter={GetUSStateName(editedEntity.state)} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"ZIP"}
                originalParameter={props.entityToUpdate.zip}
                updatedParameter={editedEntity.zip} />

              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"CLIENT ID"}
                originalParameter={props.entityToUpdate.client_id}
                updatedParameter={editedEntity.client_id} />


              <UpdateEntityModalEditConfirmDataLine
                modalState={modalState}
                label={"STATUS"}
                originalParameter={props.entityToUpdate.status}
                updatedParameter={editedEntity.status} />

            </div>
          </div>
        </Modal.Body>
        <UpdateEntityModalFooter
          modalState={modalState}
          setState={setModalState}
          updateEntity={updateEntity}
          addEntity={addEntity}
          validateInputs={validateInputs}
          close={closeModal}
          refresh={props.refresh}
          active={formEdited} />
      </Modal>
    </div>
  );
};

export default UpdateFacilityModal;