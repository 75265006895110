import { GetApi, GetUserPath } from "../../apis/Api";

async function GetUser(username, facility_id) {

  var user = await GetApi(`${GetUserPath}/${username}/${facility_id}`)
    .then(response => response.json())
    .then(({result}) => result); 
 
  return user;

};

export default GetUser;