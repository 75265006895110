import { useContext, useEffect, useState } from "react";
import GetPatientByPatientId from "../../data_layer/repositories/Patients/GetPatientByPatientId";

export const useGetPatient = (patient_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [patient, setPatient] = useState([]);

    useEffect(() => {
        if (patient_id)
            getPatient();
    }, [patient_id])
    
    const getPatient = async () => {
        setIsLoading(true);
        const response = await GetPatientByPatientId(patient_id);
        setPatient(response);
        setIsLoading(false);
    }

    return {
        isLoading,
        patient,
    }
}
