import  '../../../styles/Modals.css'
import './UpdateEntityModals.css'
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { ModalStates } from './UpdateEntityModalStates';
import { IsErrorState, GetInputValidationErrorText } from './InputValidation';

function UpdateEntityModalDataLineAndPicker(props) {

  function getDisabled()
  {
    switch (props.modalState)
    {
      case ModalStates.Add:
      case ModalStates.Edit:
        return false;
      case ModalStates.ConfirmAdd:
      case ModalStates.ConfirmEdit:
      default:
        return true;
    }
  }

  // function getValue(i)
  // {
  //   if (IsNullOrEmpty(props.choiceSelectionParameter))
  //   {
  //     return i;
  //   }
  //   return i[props.choiceSelectionParameter];
  // }

  // function getDisplay(i)
  // {
  //   if (IsNullOrEmpty(props.choiceDisplayParameter))
  //   {
  //     return i;
  //   }
  //   return i[props.choiceDisplayParameter];
  // }

  return (
    <div>
      <Row className='AddEntityModalContainer'>
        <label className="AddEntityModalLabel col-form-label col-sm-4">{props.labelName}</label>
        <div className='col-sm-4'>
          <input className={IsErrorState(props.errorState) ? 'AddEntityModalEntryError' : 'AddEntityModalEntry'}
              id={`${props.labelName}-input`}
              disabled={ getDisabled() }
              type={props.type ? props.type : 'text'}
              maxLength={ props.entryMaxLength }
              minLength={ props.entryMinLength }
              min={ props.min }
              max={ props.max }
              value={ props.value ?? "" }
              onChange={e => props.inputChanged(e.target.value, props.parameterName)}/>
            <label className={IsErrorState(props.errorState) ? 'InputValidationErrorMessage' : 'InvisibleCollapse'}>
              {GetInputValidationErrorText(props.errorState, props.labelName, props.min, props.max, props.entryMinLength, props.entryMaxLength)}
          </label>
        </div>
        <div className="col-sm-4">
          <Form.Select className='AddEntityModalSelect'
              disabled={getDisabled()}
              value={props.valueForSelect}
              onChange={e => props.selectChange(e.target.value, props.selectName)}>
                {props.choices.map(option => 
                  <option key={option.value} value={option.value}>{option.name}</option>)}
          </Form.Select>

        </div>
      </Row>
    </div>
    
  );
}

export default UpdateEntityModalDataLineAndPicker;