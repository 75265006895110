import LocalStorage from "../../utilities/Storage";

const devUrl = process.env.REACT_APP_API_URL
const securityToken = process.env.REACT_APP_API_TOKEN
const accessToken = LocalStorage.getItem("accessToken");

// Patients
export const GetAllPatientConflicts = "/patients/conflict";
export const GetAllPatientsWithMissingData = "/patients/missing";
export const GetAllPatients="/patients";
export const GetAllPatientsByFacilityPath="/patients/list/facility/";
export const GetPatientByPatientIdPath="/patients";
export const MergeTwoPatientsWithConflict="/patients/merge";
export const UpdateAPatient="/patients";
export const CreateAPatient="/patients";
export const GetPatientByEhrAndFacility = "/patients/ehr"
export const GetPatientListPath = (facility_id) => `/facility/${facility_id}/patients/summary`
export const GetFavoritePatientsPath = (user_id) => `/patients/favorite/${user_id}`
export const UpdateFavoritePatientsPath = `/patients/favorite`

// Users
export const GetAllUsers = "/users";
export const UpdateAUser = "/users";
export const CreateANewUser = "/users";
export const GetUserPath = "/users";
export const VerifyUserPath = "/users/verify";
export const ResetUserPasswordPath = "/users/password/reset";

// Facilities
export const GetAllFacilities = "/facilities";
export const CreateANewFacility = "/facilities";
export const UpdateAFacility = "/facilities";
export const GetFacilitiesByStatePath = "/facilities/states";
export const GetAvailableStatesPath = "/facilities/states";

// Mobile devices
export const GetAllMobileDevices = "/mobile";
export const GetMobileDevicesByFacilityPath = "/mobile/facility";
export const RegisterNewMobileDevice = "/mobile";
export const UpdateAMobileDevice = "/mobile";

// Environments

export const GetAllEnvironments = "/env";
export const RegisterANewAppVersionEnvironment = "/env";
export const UpdateAnAppVersionEnvironment = "/env";

// Events/Flags
export const GetFlagsByFeedingSessionPath = "/events"

// Comments 
export const GetCommentsByPatientPath = "/comments"

// Measurements
export const GetMeasurementsCountPath = "/measurements-graph/count"
export const GetMeasurementsPath = "/measurements-graph"
export const GetLastTimetickPath = (feeding_timestamp, patient_id) => `/measurements/last-timetick/${feeding_timestamp}/${patient_id}`

export const GetMeasurementsByPaginationPath = (feeding_timestamp, patient_id, limit, offset) => {
  return `/measurements-graph/${feeding_timestamp}/${patient_id}/${limit}/${offset}`;
}

// Handhelds
export const GetHandheldsByFacilityPath = (facility_id) => `/handheld/facility/${facility_id}`
export const GetAllHandheldsPath = () => `/handheld`
export const RegisterNewHandheldPath = () => `/handheld`
export const UpdateHandheldPath = () => `/handheld`

// Biomarkers
export const GetAllBiomarkersByPatientIdPath = (patient_id) => `/biomarkers/list/${patient_id}`
export const CalculateBiomarkersByPatientPath = () => `/biomarkers/calculate`

export const GetFeedingSessionPath = (feeding_timestamp) => `/feeding/${feeding_timestamp}`


function GetApi(command)
{
  return fetch(devUrl + command,
    {
      headers: {
        'content-type':'application/json',
        'X-Amz-Security-Token': securityToken,
        'Authorization': `Bearer ${accessToken}`,
      }
    });
};

function PostApi(command, payload)
{
  const body = JSON.stringify(payload);

  return fetch(devUrl + command,
    {
      method: 'POST',
      headers: {
        'content-type':'application/json',
        'X-Amz-Security-Token': securityToken,
        'Authorization': `Bearer ${accessToken}`,
      },
      body: body,
    });
}

function PutApi(command, payload)
{
  const body = JSON.stringify(payload);

  return fetch(devUrl + command,
    {
      method: 'PUT',
      headers: {
        'content-type':'application/json',
        'X-Amz-Security-Token': securityToken,
        'Authorization': `Bearer ${accessToken}`,
      },
      body: body,
    });
}

export {GetApi, PostApi, PutApi};
