import IsNullOrEmpty from "../../utilities/IsNullOrEmpty";

function WriteResultMessage (response, writeMessageAction) {

  if (IsNullOrEmpty(response)) {
    writeMessageAction("Response is null");
    return;
  }

  if (response.message === "SUCCESS") {
    writeMessageAction("Success");
    return;
  }

  if (response.message === "ERROR") {
    writeMessageAction("Error: " + response.error);
  }
};

export default WriteResultMessage;