import React, { useEffect, useState } from 'react'
import { TimeOutModal } from './TimeOutModal';
import { DefauilWaitTimeAfterTimeout, DefaultTimeoutLimit } from '../../utilities/Constants';
import LocalStorage from '../../utilities/Storage';



export const TimeOutHandler = (props) => {
    let timer = undefined;
    let automaticTimer = undefined;

    const events = ['click', 'scroll', 'load', 'keydown'];

    const [showModal, setShowModal] = useState(false);
    const [isLogout, setIsLogout] = useState(false);

    const updateLastInteractionTime = () => {
        LocalStorage.setItem('lastInteractionTime', Date.now());
    };

    const getLastInteractionTime = () => {
        return parseInt(LocalStorage.getItem('lastInteractionTime')) || Date.now();
    };

    const getLastTimeoutSession = () => {
        return parseInt(LocalStorage.getItem('lastTimeoutSession'));
    };

    const setLastTimeoutSession = () => {
        const nextTimeout = Date.now() + props.timeOutInterval || DefaultTimeoutLimit
        LocalStorage.setItem('lastTimeoutSession', nextTimeout);
    };

    const clearLastInteractionTime = () => {
        LocalStorage.removeItem('lastInteractionTime');
    };


    const eventHandler = () => {

        if (!isLogout) {
            updateLastInteractionTime();

            if (timer) {
                props.onActive()
                startTimer();
            }
        }

    };

    useEffect(() => {

        const lastTimeoutSession = getLastTimeoutSession();
        if (lastTimeoutSession && lastTimeoutSession < Date.now()){
            handleLogout()
        }
        else{
            addEvents();
        }



        return (() => {
            removeEvents();
            clearTimeout(timer);
        });
    }, []);

    const addEvents = () => {
        events.forEach((eventName) => {
            window.addEventListener(eventName, eventHandler);
        });
        startTimer();
    }

    const removeEvents = () => {
        events.forEach((eventName) => {
            window.removeEventListener(eventName, eventHandler);
        });
    }

    const startTimer = () => {

        if (timer) {
            clearTimeout(timer);
        }

        if (automaticTimer)
            clearTimeout(automaticTimer);

        timer = setTimeout(() => {
            let lastInteractionTime = getLastInteractionTime();

            const diff = Date.now() - lastInteractionTime;

            const timeOutInterval = props.timeOutInterval || DefaultTimeoutLimit;

            console.log(`Last interaction: ${lastInteractionTime}, Now: ${Date.now()}, Diff: ${diff}, interval: ${timeOutInterval}`);

            if (isLogout) {
                clearTimeout(timer);
            }
            else {
                if (diff < timeOutInterval) {
                    startTimer();
                    props.onActive();
                }
                else {
                    props.onIdle();
                    setShowModal(true);
                    automaticTimer = setTimeout(() => {

                        handleLogout()
                    }, DefauilWaitTimeAfterTimeout);
                }
            }

        }, props.timeOutInterval || DefaultTimeoutLimit );
    }

    const handleContinueSession = () => {
        clearTimeout(automaticTimer);
        setShowModal(false);
        setIsLogout(false);
        updateLastInteractionTime();
        //startTimer();
        setLastTimeoutSession();
    }

    const handleLogout = () => {
        removeEvents();
        clearTimeout(timer);
        clearTimeout(automaticTimer);
        setIsLogout(true);
        clearLastInteractionTime()
        props.onLogout()
        setShowModal(false);
    }


    return (
        <div>
            <TimeOutModal
                showModal={showModal}
                handleContinue={handleContinueSession}
                handleLogout={handleLogout} />
        </div>
    )
}
