import { useEffect, useState } from "react";
import GetAllPatientsByFacility from "../../data_layer/repositories/Patients/GetAllPatientsByFacility";

export const useGetPatientsByFaciltiy = (facility_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [patients, setPatients] = useState([]);

    useEffect(() => {
        getPatients();

    }, [facility_id])

    const getPatients = async () => {
        setIsLoading(true);
        const response = await  GetAllPatientsByFacility(facility_id);

        setPatients(response);
        setIsLoading(false);
    }

    const reloadData = () => {
        getPatients()
    }

    return {
        isLoading,
        patients,
        reloadData,
    }
}
