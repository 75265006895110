import { PaginatedTable } from '../PaginatedTable/PaginatedTable';

import { LuBaby } from "react-icons/lu";
import FormatDate from '../../utilities/FormatDate';
import { useParams } from 'react-router-dom';
import { ExportFeedingSessionCSV } from '../Buttons/ExportFeedingSessionCSV';
import { ExportAllFeedingsZip } from '../Buttons/ExportAllFeedingsZip';
import { ExportBiomarkersCSV } from '../Buttons/ExportBiomarkersCSV';
import { CalculateBiomarkersButton } from '../Buttons/CalculateBiomarkersButton';


function FeedingsTable(props) {

  const { name: facility_name } = useParams();

  const feedingsColumns = [
    {
      name: 'Date',
      selector: row => FormatDate( row.timestamp ),
      sortable: true,
    },
    {
      name: 'Feed Duration',
      selector: row => row.duration,
      sortable: true
    },
    {
      name: 'Biomarkers',
      selector: row => row.has_biomarkers ? 'Yes' : 'No',
      sortable: true
    },
    {
      name: '',
      selector: (row) => <ExportFeedingSessionCSV feeding={row} patient={props.patient}/>,
      width: '100px'
    }
  ];

  return (
    <PaginatedTable
      name={facility_name ? `${facility_name} / Patients / ${props.patient.name}` : 'Patients'}
      icon={<LuBaby size={25} />}
      data={props.data}
      columns={feedingsColumns}
      specialButton={
        <>
          <CalculateBiomarkersButton patient_id={props.patient.patient_id} feedings={props.patient.feedings}/>
          <div className='small-spacer'></div>
          <ExportBiomarkersCSV patient_id={props.patient.patient_id} />
          <div className='small-spacer'></div>
          <ExportAllFeedingsZip patient={props.patient} feedings={props.patient.feedings}/> 
        </>
        
      } />
  )
}

export default FeedingsTable;