import { VersionNumber } from "../data_layer/data/VersionNumber";
import FormatDate from "./FormatDate";


function ConvertMeasurementsToString(feeding, measurements){
    // Convert the data array into a CSV string
    let csvString = `PatientId, ${feeding.patient_id}\n`;
    csvString += `UserId, ${feeding.user_id}\n`;
    csvString += `Start Time, ${FormatDate(feeding.timestamp)}\n`;
    csvString += `Model, NN200\n`;
    csvString += `SerialNumber, ${feeding.hh_serial_number}\n`;
    csvString += `Software Version, 50016-${VersionNumber}\n\n`;

    csvString += "Time Tick (5ms), Pressure (adc), Breathing (adc), Min Battery (mV), Max Battery (mV), Mark \n" // Specify your headers here

    let timetick = 0;
    measurements.forEach(item => {

        if (item.pressure_array.length > 0) {
            item.pressure_array.forEach(element => {
                csvString += `${timetick}, ${element}, ${item.breathing * 1000}, 0, 0, ${item.mark}\n`;
                timetick++;
            });
        }
        else{
            csvString += `${timetick}, ${item.pressure * 1000}, ${item.breathing * 1000}, 0, 0, ${item.mark}\n`;
            timetick++;
        }

    });

    csvString += ",4095,,,,";

    return csvString;
};

export default ConvertMeasurementsToString;