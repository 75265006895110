const FacilityTypes = [
    {
        "name": 'NICU I',
        "value": 'h-nicu-1'
    },
    {
        "name": 'NICU II',
        "value": 'h-nicu-2'
    },
    {
        "name": 'NICU III',
        "value": 'h-nicu-3'
    },
    {
        "name": 'NICU IV',
        "value": 'h-nicu-4'
    },
    {
        "name": 'PICU',
        "value": 'h-picu'
    },
    {
        "name": 'CICU',
        "value": 'h-cicu'
    },
    {
        "name": 'Step Down Unit',
        "value": 'h-step-down'
    },
    {
        "name": 'Outpatient',
        "value": 'outpatient-setting'
    },
    {
        "name": 'Home',
        "value": 'home'
    },
    {
        "name": 'Multiple',
        "value": 'multiple'
    },
    {
        "name": 'Other',
        "value": 'other'
    },
];

export default FacilityTypes;

export const GetFacilityName = (value) => {
    return FacilityTypes.find((type) => type.value === value)?.name
}