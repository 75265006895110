import { GetApi, GetAllPatientConflicts, GetAllPatientsWithMissingData } from "../../apis/Api";
import FormatDate from "../../../utilities/FormatDate";

async function GetAlertsByFacility(facility_id){
  var conflicts = await GetApi(`${GetAllPatientConflicts}/${facility_id}`)
    .then(response => response.json())
    .then(data => convertConflicts(data, 0));

  var indexOffset = conflicts.length;

  var missingData = await GetApi(`${ GetAllPatientsWithMissingData }/${facility_id}`)
    .then(response => response.json())
    .then(data => convertMissingData(data, indexOffset));

  return conflicts.concat(missingData);
};

function convertConflicts(response, indexOffset) {
  return response.result.map((item, index)=>convertEachConflict(item, index + indexOffset))
}

function convertMissingData(response, indexOffset) {
  return response.result.map((item, index)=>convertEachMissingData(item, index + indexOffset))
}

function convertEachConflict(item, index)
{
  return {
    id: index,
    date: FormatDate(item.created_at),
    type: "Data Conflict",
    issue: item.data_error.replace("Data Conflict: ", "").replaceAll(" + ", ", "),
    status: item.status,
    original_patient_id: item.original_patient.patient_id,
    patient_id: item.patient.patient_id,
    original_user_name: item.original_patient.user_name,
    user_name: item.patient.user_name,
  };
}

function convertEachMissingData(item, index) {
  
  // var issues = [];
  // if (Number(item.gestational_age) === 0) {
  //   issues.push("Gestational age");
  // }
  // if (Number(item.date_of_conception) === 0){
  //   issues.push("Conceptional age");
  // }
  // if (Number(item.birth_weight) === 0) {
  //   issues.push("Birth weight");
  // }
  const issue = item.message.replace("Missing data: ", "").replaceAll(" + ", ", ")

  return {
    id: index,
    date: FormatDate(item.created_at),
    type: "Missing data",
    //issue: issues.toString().replace(",",", "),
    issue: issue,
    user_name: item.user_name,
    status: item.status,
    patient_id: item.patient_id,
  };
}

export default GetAlertsByFacility;