import { GetApi, GetAllPatients } from "../../apis/Api";
import CalculateConceptionalAge from "../../../utilities/CalculateConceptionalAge";
import FormatDate, { FormatSimpleDate } from "../../../utilities/FormatDate";
import SplitName from "../../../utilities/SplitName";

async function GetPatientById(id) {

  var patient = await GetApi(GetAllPatients + "/" + id)
    .then(response => response.json())
    .then(data => convert(data.result[0])); // TODO: Add error checking for multiple responses.
 
  return patient;
};

function convert(item) {

  const names = SplitName(item.name);

  return {
    firstName: names.firstName,
    lastName: names.lastName,
    patientId: item.patient_id,
    ehrReference: item.ehr_patient_ref,
    gestationalAge: item.gestational_age,
    conceptionalAge: CalculateConceptionalAge(item.date_of_conception),
    dateOfConception: item.date_of_conception,
    dateCreated: FormatSimpleDate(item.created_at),
    user_id: item.user_id,
    birth_weight: item.birth_weight,
    weight_uom: item.weight_uom,
    facility_id: item.facility_id,
    mobile_serial_number: item.mobile_serial_number,
    admission_status: item.admission_status,
    note: item.note
  };
}

export default GetPatientById;