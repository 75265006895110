import { PutApi, UpdateAUser } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";

async function UpdateUser(
  editedUser,
  setResultMessage,
  refreshAction)
{
  const payload = 
  {
    user: {
        user_id: editedUser.user_id,
        name: editedUser.name,
        login_id: editedUser.login_id,
        user_role: editedUser.role,
        email: editedUser.email,
        phone: editedUser.phone,
        credential_status: editedUser.credential_status,
        facility_id: editedUser.facility_id,
        credential_status: editedUser.status
    }
  }

  await PutApi(UpdateAUser, payload)
    .then(response => response.json())
    .then(output => WriteResultMessage(output, setResultMessage))
    .then(refreshAction);
}
export default UpdateUser;