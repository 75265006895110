import { FirstNameMaxLength, LastNameMaxLength } from "./Constants";

function SplitName(fullName)
{
  const names = fullName.split(", ");
  const firstName = names[1].substring(0, FirstNameMaxLength);
  const lastName = names[0].substring(0, LastNameMaxLength);

  return {
    firstName: firstName,
    lastName: lastName,
  }
}

export default SplitName;