import '../../../styles/Shared.css'

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import {useState, useEffect} from 'react'
import {FaMobileAlt} from "react-icons/fa";

import { IsConfirmState, IsAddState, ModalStates } from './UpdateEntityModalStates';

import UpdateEntityModalDataLine from './UpdateEntityModalDataLine';
import UpdateEntityModalEditConfirmDataLine from './UpdateEntityModalEditConfirmDataLine';
import UpdateEntityModalDataPicker from './UpdateEntityModalDataPicker';
import UpdateEntityModalHeader from './UpdateEntityModalHeader';
import UpdateEntityModalFooter from './UpdateEntityModalFooter';

import AddMobileDevice from '../../../data_layer/repositories/Mobile/AddMobileDevice';
import UpdateMobileDevice from '../../../data_layer/repositories/Mobile/UpdateMobileDevice';


import { InputValidationErrorStates, CheckIsMissing, HasErrors, CheckIsMissingAndLength, CheckHasMaxLength } from './InputValidation';
import UpdateEntityModalDataSwitch from './UpdateEntityModalDataSwitch';
import { MobileAssetTagMaxLength, MobileDeviceNameMaxLength, MobileModelMaxLength, MobileSerialNumberMaxLength, MobileSerialNumberMinLength } from '../../../utilities/Constants';
import { useGetMobiles } from '../../CustomHooks/useGetMobiles';


function UpdateMobileDeviceModal(props) {

  const [modalState, setModalState] = useState(ModalStates.Edit);
  const [editedEntity, setEditedEntity] = useState([]);
  const [resultMessage, setResultMessage] = useState("Writing...");
  const [errorStates, setErrorStates] = useState([]);
  
  const [formEdited, setFormEdited] = useState(false);

  const { mobiles } = useGetMobiles(props.entityToUpdate.facility_id);

  useEffect(() => {
    clearErrorStates();
    setEditedEntity({...props.entityToUpdate});
  },[props.entityToUpdate]);

  useEffect(() => {
      if (props.labelPrefix === "Edit ") {
        setModalState(ModalStates.Edit);
      }

      if (props.labelPrefix === "Add ") {
        setModalState(ModalStates.Add);
      }
    },[props.labelPrefix]);


  function clearErrorStates()
  {
    setErrorStates(
      {
        mobile_serial_number: InputValidationErrorStates.NoError,
        mobile_device_name: InputValidationErrorStates.NoError,
        mobile_manufacture_model: InputValidationErrorStates.NoError,
        client_facility_asset_tag: InputValidationErrorStates.NoError,

      }
    )
  }

  function validateInputs()
  {
    let serialNumberErrorState = (modalState === ModalStates.Add) ?
      CheckIsMissingAndLength(editedEntity.mobile_serial_number, MobileSerialNumberMinLength, MobileSerialNumberMaxLength) :
      InputValidationErrorStates.NoError;

    if (serialNumberErrorState === InputValidationErrorStates.NoError && modalState === ModalStates.Add){
      serialNumberErrorState = isMobileSerialNumberRegistered(editedEntity.mobile_serial_number) ? 
                                  InputValidationErrorStates.AlreadyRegistered 
                                  : InputValidationErrorStates.NoError
    }

    const deviceNameErrorState = CheckIsMissingAndLength(editedEntity.mobile_device_name, null, MobileDeviceNameMaxLength);
    const modelErrorState = CheckIsMissingAndLength(editedEntity.mobile_manufacture_model,null, MobileModelMaxLength);
    const assetTagErrorState = CheckHasMaxLength(editedEntity.client_facility_asset_tag, MobileAssetTagMaxLength);


    setErrorStates({...errorStates, 
      mobile_serial_number: serialNumberErrorState,
      mobile_device_name: deviceNameErrorState,
      mobile_manufacture_model : modelErrorState,
      client_facility_asset_tag: assetTagErrorState,
    })

    return !HasErrors([serialNumberErrorState, deviceNameErrorState, modelErrorState, assetTagErrorState]);

  }

  function isMobileSerialNumberRegistered(serialNumber){
    return mobiles.filter((mobile) => mobile.mobile_serial_number === serialNumber).length > 0
  }

  function updateEntity()
  {
    UpdateMobileDevice(editedEntity, setResultMessage, props.refresh);
  }

  function addEntity()
  {
    AddMobileDevice(editedEntity, setResultMessage, props.refresh);
  }

  function updateEditedParameter(newValue, parameter)
  {
    setFormEdited(true);
    setErrorStates( ( error ) => ({...error, [parameter]: InputValidationErrorStates.NoError }));
    setEditedEntity(editedEntity => ({...editedEntity, [parameter]:newValue}));
  }


  function closeModal()
  {
    setFormEdited(false);
    setResultMessage("Writing...");
    setEditedEntity([]);
    props.close();
  }

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}>
      <Modal 
        show={props.show}
        backdrop="static"
        centered
        size="xl">
        <UpdateEntityModalHeader
          icon={<FaMobileAlt className='ModalHeaderIcon' size={20}/>}
          headerLabel={props.labelPrefix + "mobile device"}
          close={ closeModal }/>
        <Modal.Body>
          <label className={modalState === ModalStates.ResultMessage ? 'ResultMessage' : 'InvisibleCollapse'}>{resultMessage}</label>
          <Container className={modalState === ModalStates.ResultMessage ? 'InvisibleCollapse' : 'null'}>
            
            
            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName = "SERIAL NUMBER"
              parameterName = 'mobile_serial_number'
              value = {editedEntity.mobile_serial_number}
              inputChanged={updateEditedParameter}
              required
              errorState={errorStates.mobile_serial_number}
              disabled={!IsAddState(modalState)}
              entryMaxLength={ MobileSerialNumberMaxLength }
              entryMinLength={ MobileSerialNumberMinLength }/>

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName = "NAME"
              parameterName = 'mobile_device_name'
              value = {editedEntity.mobile_device_name}
              inputChanged={updateEditedParameter}
              required
              errorState={errorStates.mobile_device_name}
              entryMaxLength={ MobileDeviceNameMaxLength }/>

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName = "MODEL"
              parameterName = 'mobile_manufacture_model'
              value = {editedEntity.mobile_manufacture_model}
              required
              inputChanged={updateEditedParameter}
              errorState={errorStates.mobile_manufacture_model}
              entryMaxLength={ MobileModelMaxLength }/>

            {/* <UpdateEntityModalDataPicker
              modalState={modalState}
              labelName = "FACILITY"
              choices={props.facilities}
              parameterName = 'facility_id'
              choiceSelectionParameter='id'
              choiceDisplayParameter='name'
              value = {editedEntity.facility_id}
              inputChanged={updateFacility}/> */}

            <UpdateEntityModalDataLine
              modalState={modalState}
              labelName = "ASSET TAG"
              parameterName = 'client_facility_asset_tag'
              value = {editedEntity.client_facility_asset_tag}
              inputChanged={updateEditedParameter}
              errorState={errorStates.client_facility_asset_tag}
              entryMaxLength={ MobileAssetTagMaxLength } />

            {/* <UpdateEntityModalDataPicker
              modalState={modalState}
              labelName = "ENABLE MOBILE LOGGING"
              parameterName = 'mobile_log_flag'
              choices={TrueFalse}
              value = {editedEntity.mobile_log_flag}

              inputChanged={updateEditedParameter}/> */}

            <div className={!IsAddState(modalState) ? 'null': 'InvisibleCollapse'}>
              <UpdateEntityModalDataSwitch
                modalState={modalState}
                labelName = "ACTIVE"
                parameterName = 'status'
                value = {editedEntity.status}
                inputChanged={updateEditedParameter}/>
            </div>
          </Container>

          <div className={IsConfirmState(modalState) ? 'null': 'InvisibleCollapse'}>
            <hr className='ThickLine'></hr>
            <label className="ConfirmHeader">Are you sure you want to save your changes?</label>
            <div className={modalState === ModalStates.ConfirmAdd ? 'InvisibleCollapse' : 'null'}>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"SERIAL NUMBER"}
                originalParameter={props.entityToUpdate.mobile_serial_number}
                updatedParameter={editedEntity.mobile_serial_number}/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"NAME"}
                originalParameter={props.entityToUpdate.mobile_device_name}
                updatedParameter={editedEntity.mobile_device_name}/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"MODEL"}
                originalParameter={props.entityToUpdate.mobile_manufacture_model}
                updatedParameter={editedEntity.mobile_manufacture_model}/>

              {/* <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"FACILITY"}
                originalParameter={props.entityToUpdate.facility_name}
                updatedParameter={editedEntity.facility_name}/> */}

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"ASSET TAG"}
                originalParameter={props.entityToUpdate.client_facility_asset_tag}
                updatedParameter={editedEntity.client_facility_asset_tag}/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"ENABLE MOBILE LOGGING"}
                originalParameter={props.entityToUpdate.mobile_log_flag}
                updatedParameter={editedEntity.mobile_log_flag}/>

              <UpdateEntityModalEditConfirmDataLine 
                modalState={modalState}
                label={"ACTIVE"}
                originalParameter={props.entityToUpdate.status}
                updatedParameter={editedEntity.status}/>

             </div>
          </div>
        </Modal.Body>


        <UpdateEntityModalFooter
          modalState={modalState}
          setState={setModalState}
          updateEntity={updateEntity}
          addEntity={addEntity}
          validateInputs={validateInputs}
          close={closeModal}
          refresh={props.refresh}
          active = { formEdited }/>
      </Modal>
    </div>
  );

};

export default UpdateMobileDeviceModal;