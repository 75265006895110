import { GetApi, GetMeasurementsByPaginationPath } from "../../apis/Api";

async function GetMeasurementsByPagination(feeding_session_timestamp, patient_id, limit, offset) {

    // How many measurements has the feeding session
    const measurements = await GetApi(
            GetMeasurementsByPaginationPath(feeding_session_timestamp, patient_id, limit, offset)
        )
        .then(response => response.json())
        .then(data => convertData(data.result ? data.result : []));

    return measurements;
};

function convertData(elements){
    return elements?.map((item) => convertEachElement(item))
}

function convertEachElement(item){
    return {
        ...item,
        timetick : (item.timetick * 5) / 1000,
        pressure : (item.pressure) / 1000,
        breathing : (item.breathing) / 1000,
    }
}

export default GetMeasurementsByPagination;