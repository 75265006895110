import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

import {
    MaxValueBreathingGraph,
    MaxValuePressureGraph,
    MinValueBreathingGraph,
    MinValuePressureGraph
} from '../../utilities/Constants';
import { FormatMinAndSec } from '../../utilities/FormatDate';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin,
    zoomPlugin
);



export const LinearChart = ({ timeData, breathingData, pressureData, flags, isLiteVersion, granularity }) => {

    const maxFeedingSessionTime = timeData[timeData.length - 1];

    const getXAxisLimits = () => {
        switch (granularity) {
          case '5m':
            return { min: 0, max: 300 }; // 5 minutes in seconds (5 * 60 = 300s)
          case '1m':
            return { min: 0, max: 60 }; // 1 minute in seconds
          case '30s':
            return { min: 0, max: 30 }; // 30 seconds
          case '10s':
            return { min: 0, max: 10 }; // 10 seconds
          default:
            return { min: 0, max: maxFeedingSessionTime}; // Show full data for "full"
        }
      };

    return (
        <>
            {!isLiteVersion 
                
            }
            <div className={`${isLiteVersion ? 'chart-wrapper' : 'chart-wrapper-details'}`}>
                <Line
                    data={{
                        labels: timeData,
                        datasets: [
                            {
                                label: 'Suck & Swallow/sec',
                                data: pressureData,
                                borderWidth: 1,
                                borderColor: 'rgba(135, 193, 116,1)',
                                backgroundColor: 'rgba(135, 193, 116,1)',
                                yAxisID: 'y',
                            },
                            {
                                label: 'Breathing/sec',
                                data: breathingData,
                                borderWidth: 1,
                                borderColor: 'rgba(57, 110, 198,1)',
                                backgroundColor: 'rgba(57, 110, 198,1)',
                                yAxisID: 'y1',
                            },
                        ]
                    }}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                            point: {
                                radius: 0
                            }
                        },
                        interaction: {
                            mode: 'xy',
                        },
                        scales: {
                            x: {
                                type: 'linear',
                                ...getXAxisLimits(),
                                // title: {
                                //     display: true,
                                //     text: 'Time (seconds)',
                                // },
                                ticks: {
                                    // Include a dollar sign in the ticks
                                    callback: function (value, index, ticks) {
                                        return  FormatMinAndSec((value * 1000)/5);
                                    },
                                    font: {
                                        size: 8,
                                        weight: 'bold'
                                    }
                                }
                            },
                            y: {
                                type: 'linear',
                                position: 'right',
                                min: MinValuePressureGraph,
                                max: MaxValuePressureGraph,
                                gridLines: {
                                    zeroLineColor: "rgba(104,143,243,1)"
                                },
                                ticks: {
                                    // Include a dollar sign in the ticks
                                    callback: function (value, index, ticks) {
                                        return value + "k";
                                    },
                                    font: {
                                        size: 8,
                                        weight: 'bold'
                                    }
                                },
                                title: {
                                    display: false
                                }
                            },
                            y1: {
                                type: 'linear',
                                position: 'left',
                                min: MinValueBreathingGraph,
                                max: MaxValueBreathingGraph,
                                gridLines: {
                                    zeroLineColor: "rgba(48,183,159,1)"
                                },
                                ticks: {
                                    // Include a dollar sign in the ticks
                                    callback: function (value, index, ticks) {
                                        return value + "k";
                                    },
                                    font: {
                                        size: 8,
                                        weight: 'bold'
                                    }
                                },
                                title: {
                                    display: false
                                }
                            }

                        },
                        plugins: {
                            datalabels: {
                                display: false, // Hide default data labels
                            },
                            legend: {
                                display: false,
                            },
                            annotation: {
                                annotations: flags?.map((flag, index) => ({
                                    type: 'line',
                                    scaleID: 'x',
                                    value: (flag.timetick * 5) / 1000,
                                    borderColor: '#45367f',
                                    borderWidth: 2,
                                    borderDash: [6, 6],
                                }))
                            },
                            zoom: {
                                pan: {
                                  enabled: true, // Enable panning
                                  mode: 'x', // Allow panning along the x-axis only
                                },
                                zoom: {
                                  wheel: {
                                    enabled: false, // Disable zooming with the mouse wheel
                                  },
                                  pinch: {
                                    enabled: true, // Enable zooming with pinch gestures
                                  },
                                  mode: 'x', // Allow zooming along the x-axis only
                                  limits: {
                                    x: {minRange: 0, maxRange: maxFeedingSessionTime},
                                    y: {
                                        min: 0, // Prevent panning below y-axis 0 if desired
                                      }
                                  },
                                },
                              },
                        },
                    }}
                />
            </div>



        </>
    )
}
