import { format } from "date-fns";

export function FormatDate(millisString) {

  if (!millisString) {
    return "";
  }

  if (millisString === 0) {
    return "";
  }


  const date = new Date(Number(millisString) * 1000);
  return format(date, "MM/dd/yyyy hh:mm aaa");
};


export default function FormatDateWithMonth(millisString) {

  if (!millisString) {
    return "";
  }

  if (millisString === 0) {
    return "";
  }


  const date = new Date(Number(millisString) * 1000);
  return format(date, "MMM d, hh:mm aaa");
};

export function FormatDateForCard(millisString) {

  if (!millisString) {
    return "";
  }

  if (millisString === 0) {
    return "";
  }

  const date = new Date(Number(millisString) * 1000);
  return format(date, "MM/dd - HH:mm");
};

export function FormatDateForUniqueDate(millisString) {

  if (!millisString) {
    return "";
  }

  if (millisString === 0) {
    return "";
  }

  const date = new Date(Number(millisString) * 1000);
  return format(date, "MMM d, yyyy");
};


export const FormatDateForFileName = (millisString) => {
  if (!millisString) {
    return "";
  }

  if (millisString === 0) {
    return "";
  }


  const date = new Date(Number(millisString) * 1000);
  return format(date, "yyyyMMdd_HHmm");
}

export const FormatSimpleDate = (millisString) => {
  if (!millisString) {
    return "";
  }

  if (millisString === 0) {
    return "";
  }


  const date = new Date(Number(millisString) * 1000);
  return format(date, "MM/dd/yyyy");
}

export const FormatMinAndSec = (millisString) => {
  if (!millisString) {
    return "00:00";
  }

  if (millisString === 0) {
    return "00:00";
  }


  const date = new Date(Number(millisString) * 5);
  return format(date, "mm:ss");
}

export const FormatHoursMins = (millisString) => {
  if (!millisString) {
    return "";
  }

  if (millisString === 0) {
    return "";
  }


  const date = new Date(Number(millisString) * 1000);
  return format(date, "hh:mm aaa");
}