import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';

export const Loading = ({isLoading}) => { 

    return (
        <>
            <Modal show={isLoading} size='sm' centered={true} role="dialog">
                
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="p-2 spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className='p-2'>
                            Loading...
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            {isLoading && (
                <div className="modal-backdrop fade show"></div>
            )}
        </>
    );
}
