export function FormatPhoneNumber(phoneNumberString) {

    var cleaned = ('' + phoneNumberString).replace("+1", "").replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    else {
        if (cleaned.length <= 3)
            return cleaned
        if (cleaned.length <= 6)
            return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`
        if (cleaned.length < 10)
            return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`
    }
    return null;
}