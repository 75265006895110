
import '../../styles/Shared.css'
import Form from 'react-bootstrap/Form';

import { useState, useEffect, useContext } from "react";

import GetFacilities from '../../data_layer/repositories/Facilities/GetFacilities';

import './SelectFacilityPage.css';
import Row from 'react-bootstrap/Row';
import { AuthContext } from '../../auth';
import { useNavigate } from 'react-router-dom';
import { LoginTemplate } from '../../components/Templates/LoginTemplate';


const SelectFacilityPage = () => {

    const env = process.env.REACT_APP_ENV;


    const [facilities, setFacilities] = useState([])
    const [facilitySelelected, setFacilitySelelected] = useState('')
    const [facilityError, setFacilityError] = useState(false)

    const { saveFacility } = useContext( AuthContext );
    const navigate = useNavigate();


    const getFacilities = () => {
        GetFacilities().then(facilities => {
            const activeFacilities = facilities.filter((facility) => facility.status === 'active' && facility.name !== 'Neoneur')
            setFacilities(activeFacilities);
        });
    }

    const handleFacilityChange = ({target}) => {
        if (target.value){
            setFacilityError(false);
            setFacilitySelelected(target.value)
        }
    }

    const handleOnLoginClickButton = () => {

        // Validate fields

        if (!facilitySelelected){
            setFacilityError(true);
            return;
        }

        const facility = facilities.find(facility => facility.id === facilitySelelected);
        
        saveFacility(facility);
        navigate('/login', {
             state : { facility }
        });
    }
    
    useEffect(() => {
        getFacilities();
    },[]);

    return (
        <LoginTemplate>

            <center>
                <h4>Welcome to Neoneur</h4>
                <p>Get started by adding your facility.</p>
            
            </center>

            <Form >
                
                <Row className='SelectEntryContainer'>
                    <Form.Label className='ValidatedFieldLabel' htmlFor="select-facility">FACILITY</Form.Label>
                    <Form.Select 
                        className={ (facilityError ? 'FieldEntryError' : 'FieldEntry') }
                        id='select-facility'
                        value={facilitySelelected} 
                        onChange={handleFacilityChange}>
                        <option value=''>Select your facility</option>
                        { facilities.map( (facility) =>{
                            return <option value={facility.id} key={facility.id}>{facility.name}</option>
                        }) }
                    </Form.Select>
                    <Form.Label className={ (facilityError) ? 'InputValidationErrorMessage' : 'InvisibleCollapse'} >
                        Select your facility
                    </Form.Label>
                </Row>

                {/* <Form.Check 
                    type='checkbox'
                    id='remember-facility'
                    label='Remember facility'
                /> */}

                <Row className='d-grid gap-2 col-6 mx-auto'>

                    <button 
                        onClick={ handleOnLoginClickButton }
                        className="LoginButton neo_btn_primary">
                        Next
                    </button>
                </Row>
                
                {/* <Row>
                
                    <Button className="ForgotPasswordButton">Need help?</Button>
                </Row> */}
            </Form>

        </LoginTemplate>
    );

}

export default SelectFacilityPage;