import React from 'react'
import '../../styles/Shared.css';
import '../../styles/Pages.css';
import { useState, useContext } from "react";

import UsersTable from '../../components/Tables/UsersTable';
import UpdateUserModal from '../../components/Modals/UpdateEntityModals/UpdateUserModal';

import { Roles } from '../../data_layer/repositories/Users/UserRoles';
import { AuthContext } from '../../auth';
import { ResetPasswordModal } from '../../components/Modals/ResetPasswordModal';
import { useGetUsers } from '../../components/CustomHooks/useGetUsers';
import { useGetFacilities } from '../../components/CustomHooks/useGetFacilities';
import { Loading } from '../../components/Loading/Loading';
import { BasicModal } from '../../components/Modals/GenericModals/BasicModal';



export const UsersMainPage = () => {

  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState([]);
  const [resetPasswordList, setResetPasswordList] = useState([])
  const [labelPrefix, setLabelPrefix] = useState("");

  const { facility, user } = useContext(AuthContext);

  const { users, isLoading, reloadData } = useGetUsers(facility.id);

  const { facilities } = useGetFacilities();

  function closeUpdateUserModal() {
    setLabelPrefix("");
    setUserToUpdate([]);
    setShowUpdateUserModal(false);
  }

  function refreshUsers() {
    reloadData();
  }

  function onEditUserClicked(user) {
    user.facility_name = facility.name
    setLabelPrefix("Edit ");
    setUserToUpdate(user);
    setShowUpdateUserModal(true);
  }

  function onResetUserPasswordClicked(user) {
    setResetPasswordList(oldList => [...oldList, <ResetPasswordModal user={user} />]);
  }



  function addUserClicked() {
    setLabelPrefix("Add ");
    const empty = getEmptyUser();
    setUserToUpdate(empty);
    setShowUpdateUserModal(true);
  }

  function getEmptyUser() {
    return {
      name: "",
      user_id: "",
      login_id: "",
      role: "",
      email: "",
      phone: "",
      password: "",
      passcode_before_encrypt: "",
      facility_name: facility.name,
      facility_id: facility.id,
    };
  }

  function getUsers() {
    if (user.role !== Roles.NeoneurAdmin) {
      return users.filter((user) => user.role !== Roles.NeoneurAdmin);
    }

    return users
  }


  return (
    <>
      <div>



        {resetPasswordList}
        <div className='neo_card_container neo_ut_mt_24_mob'>

          {
            isLoading && <Loading isLoading={isLoading} />
          }

          {
            !isLoading && <UsersTable
              onEditUserClicked={onEditUserClicked}
              onResetUserPasswordClicked={onResetUserPasswordClicked}
              data={getUsers()}
              addEntityClicked={addUserClicked} />
          }

        </div>

        <UpdateUserModal
          show={showUpdateUserModal}
          labelPrefix={labelPrefix}
          close={closeUpdateUserModal}
          facilities={facilities}
          entityToUpdate={userToUpdate}
          users={users}
          refresh={refreshUsers} />

        
      </div>
    </>
  )
}
