import '../../styles/Shared.css';
import '../../styles/Pages.css';
import { useState, useEffect, useContext } from "react";

import MobileDevicesTable from '../../components/Tables/MobileDevicesTable';

import UpdateMobileDeviceModal from '../../components/Modals/UpdateEntityModals/UpdateMobileDeviceModal';

import { AuthContext } from '../../auth';
import { useGetMobiles } from '../../components/CustomHooks/useGetMobiles';
import { Loading } from '../../components/Loading/Loading';

export const MobilesPage = () => {

  const [showUpdateMobileDeviceModal, setShowUpdateMobileDeviceModal] = useState(false);

  const [mobileDeviceToUpdate, setMobileDeviceToUpdate] = useState([]);

  const [labelPrefix, setLabelPrefix] = useState("");

  const { facility, user } = useContext(AuthContext);

  const { isLoading, mobiles, reloadData } = useGetMobiles(user.facility_id);

  function closeUpdateMobileDeviceModal() {
    setLabelPrefix("");
    setMobileDeviceToUpdate([]);
    setShowUpdateMobileDeviceModal(false);
  }


  function refreshMobileDevices() {
    reloadData();
  }

  function mobileDeviceRowClicked(row) {
    setLabelPrefix("Edit ");
    setMobileDeviceToUpdate(row);
    setShowUpdateMobileDeviceModal(true);
  }

  function addMobileDeviceClicked() {
    setLabelPrefix("Add ");
    const empty = getEmptyMobileDevice();
    setMobileDeviceToUpdate(empty);
    setShowUpdateMobileDeviceModal(true);
  }

  function getEmptyMobileDevice() {
    return {
      mobile_serial_number: "",
      mobile_device_name: "",
      mobile_manufacture_model: "",
      client_facility_asset_tag: "",
      mobile_sw_version: "",
      mobile_log_flag: false,
      facility_name: facility.name,
      facility_id: facility.id,
      status: 'active',
    };
  }

  return (
    <>
      <div className='neo_card_container neo_ut_mt_24_mob'>

        {
          isLoading && <Loading isLoading={isLoading} />
        }
        {
          !isLoading && <MobileDevicesTable
            onRowClicked={mobileDeviceRowClicked}
            data={mobiles}
            addEntityClicked={addMobileDeviceClicked} />
        }

      </div>

      <UpdateMobileDeviceModal
        show={showUpdateMobileDeviceModal}
        labelPrefix={labelPrefix}
        close={closeUpdateMobileDeviceModal}
        entityToUpdate={mobileDeviceToUpdate}
        refresh={refreshMobileDevices} />

    </>
  )
}
