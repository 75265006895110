import { FormatMinAndSec } from "../../../utilities/FormatDate"

export const FeedingDetailsSection = ({ feeding }) => {

    return (
        <div className="d-flex flex-column border-end feeding-details-width">
            <p className="text-muted m-0 text-sm">Feeding details</p>
            <div className="mt-2 d-flex align-items-center">
                <div className="border-end w-50 pe-3">
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Paced</p>
                        <p className="ms-auto mb-0 text-sm">{feeding?.paced === 1 ? 'Yes' : 'No'} </p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Duration</p>
                        <p className="ms-auto mb-0 text-sm">{FormatMinAndSec(feeding?.duration)}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Weight at feed</p>
                        <p className="ms-auto mb-0 text-sm">{`${feeding?.weight} ${feeding?.weight_uom}`}</p>
                    </div>
                </div>
                <div className="border-end w-50 px-3">
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Vol. consumed</p>
                        <p className="ms-auto mb-0 text-sm">{`${feeding?.volume} ${feeding?.volume_uom}`}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Nipple</p>
                        <p className="ms-auto mb-0 text-sm">{feeding?.nipple || `--`}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Nutrient</p>
                        <p className="ms-auto mb-0 text-sm">{feeding?.nutrient || `--`}</p>
                    </div>
                </div>
                <div className="w-50 px-3">
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Position</p>
                        <p className="ms-auto mb-0 text-sm">{feeding?.position || `--`}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Readiness to feed</p>
                        <p className="ms-auto mb-0 text-sm">{feeding?.readiness_score}/5</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="m-0 text-sm">Quality of feed</p>
                        <p className="ms-auto mb-0 text-sm">{feeding?.quality_score}/5</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
