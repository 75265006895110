import '../../styles/Shared.css';
import '../../styles/Pages.css';
import { useState, useEffect, useContext } from "react";

import AlertsTable from '../../components/Tables/AlertsTable';
import ReviewConflictsModal from '../../components/Modals/ReviewConflictsModal/ReviewConflictsModal';
import ResolveMissingDataModal from '../../components/Modals/ResolveMissingDataModal/ResolveMissingDataModal';

import GetPatientById from '../../data_layer/repositories/Patients/GetPatientById';
import { AuthContext } from '../../auth';
import CreateConflictPatients from '../../data_layer/repositories/Debug/CreateConflictPatients';
import { useGetAlerts } from '../../components/CustomHooks/useGetAlerts';
import { Loading } from '../../components/Loading/Loading';


function NotificationsPage() {

  const [showReviewConflictsModal, setShowReviewConflictsModal] = useState(false); 
  const [showResolveMissingDataModal, setShowResolveMissingDataModal] = useState(false); 
  const [reviewAlert, setReviewAlert] = useState([]);
  const [originalPatient, setOriginalPatient] = useState([]);
  const [patient, setPatient] = useState([]);
  //const [alerts, setAlerts] = useState([]);
  const [conflicts, setConflicts] = useState([])
  const [dataWasMissing, setDataWasMissing] = useState([])

  const {facility} = useContext( AuthContext );

  const {isLoading, alerts, reloadData} = useGetAlerts(facility.id)

  function CloseModalDialog()
  { 
    setConflicts([]);
    setDataWasMissing([]);
    setPatient([]);
    setOriginalPatient([]);
    setReviewAlert([]);
    setShowReviewConflictsModal(false);
    setShowResolveMissingDataModal(false);
  }

  function OpenReviewConflictsModalDialog()
  {
    setShowReviewConflictsModal(true);
  } 

  function OpenResolveMissingDataModalDialog()
  {
    setShowResolveMissingDataModal(true);
  } 

  function updateAlerts()
  {
    reloadData()
  }

  useEffect(() => {
    setConflicts(
      {
        firstName: originalPatient.firstName !== patient.firstName,
        lastName: originalPatient.lastName !== patient.lastName,
        gestationalAge: originalPatient.gestationalAge !== patient.gestationalAge,
        dateOfConception: originalPatient.dateOfConception !== patient.dateOfConception,
        ehrReference: originalPatient.ehrReference !== patient.ehrReference,
        birth_weight: originalPatient.birth_weight !== patient.birth_weight  
      });
  },[patient, originalPatient]);

  useEffect(() => {
    setDataWasMissing(
      {
        firstName: patient.firstName === "",
        lastName: patient.lastName === "",
        ehrReference: patient.ehrReference === "",

        // eslint-disable-next-line
        birth_weight: patient.birth_weight == 0,
        
        // eslint-disable-next-line
        gestationalAge: patient.gestationalAge == 0,
        
        // eslint-disable-next-line
        dateOfConception: patient.dateOfConception == 0,
      }
    );
  },[patient]);

  const useAlertClicked = (row) => 
  {
    if (row.type === "Data Conflict")
    {
      // TODO: Add special handling if patient ids are the same.
      if (row.patient_id !== row.original_patient_id )
      {
        GetPatientById(row.original_patient_id).then(setOriginalPatient);
        GetPatientById(row.patient_id).then(setPatient);
        
        setReviewAlert(row);
        OpenReviewConflictsModalDialog();
      }
    }

    if (row.type === "Missing data")
    {
      GetPatientById(row.patient_id).then(setPatient);
      setReviewAlert(row);
      OpenResolveMissingDataModalDialog();
    }
  }

  function handleClick(event){
    event.preventDefault();

    CreateConflictPatients(5).then(updateAlerts());

  }

  return (
    <div>
      
      <div className="neo_card_container neo_ut_mt_24_mob">

        {
          isLoading && <Loading isLoading={isLoading} />
        }
        {
          !isLoading && <AlertsTable
            onRowClicked={useAlertClicked}
            alerts={alerts}/>
        }
        
      </div>
      <button onClick={handleClick}>Create conflics</button>

      <ReviewConflictsModal
        show={showReviewConflictsModal}
        close={CloseModalDialog}
        reviewAlert={reviewAlert}
        patient={patient}
        originalPatient={originalPatient}
        conflicts={conflicts}
        updateAlerts={updateAlerts}/>

      <ResolveMissingDataModal
        show={showResolveMissingDataModal}
        close={CloseModalDialog}
        reviewAlert={reviewAlert}
        patient={patient}
        dataWasMissing={dataWasMissing}
        updateAlerts={updateAlerts}/>
  </div>
  );
}

export default NotificationsPage;