import { PaginatedTable } from '../PaginatedTable/PaginatedTable';
import { FiUser, FiEdit2 } from "react-icons/fi";
import { MdOutlineLockReset } from "react-icons/md";
import FormatDate from '../../utilities/FormatDate';
import { GetRoleName, Roles } from '../../data_layer/repositories/Users/UserRoles';
import { useContext } from 'react';
import { AuthContext } from '../../auth';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatPhoneNumber } from '../../utilities/FormatPhoneNumber';
import DataTable from 'react-data-table-component';
import { ConvertToGrams } from '../../utilities/Converter';


function BiomarkersTable({ data }) {

  const columns = [
    {
      name: 'Date',
      selector: row => FormatDate(row.timestamp),
      sortable: true,
      width: '200px',

    },
    {
      name: 'Maturation',
      selector: row => row.biomarkers.find((biomarker) => biomarker.name === 'MATURATION').value || 0,
      sortable: true,

    },
    {
      name: 'Pres. Max',
      selector: row => row.biomarkers.find((biomarker) => biomarker.name === 'PRESSURE-MAX-RELATIVE-TO-BASELINE').value || 0,
      sortable: true,
    },
    {
      name: 'Ave # Sucks*',
      selector: row => row.biomarkers.find((biomarker) => biomarker.name === 'SUCKS-PER-BURST').value || 0,
      sortable: true,
    },
    {
      name: 'Sucks/m-b',
      selector: row => row.biomarkers.find((biomarker) => biomarker.name === 'SUCKS-PER-MIN-IN-BURST').value || 0,
      sortable: true,
    },
    {
      name: 'Breath/min*',
      selector: row => row.biomarkers.find((biomarker) => biomarker.name === 'BREATHS-IN-BURSTS').value || 0,
      sortable: true,
    },
    {
      name: 'Breath/min**',
      selector: row => row.biomarkers.find((biomarker) => biomarker.name === 'BREATHS-IN-RESTS').value || 0,
      sortable: true,
    },
    {
      name: 'Weight (g)',
      selector: row => ConvertToGrams(row.weight, row.weight_uom),
      sortable: true,
    },
    {
      name: 'Volume',
      selector: row => `${row.volume} ${row.volume_uom}`,
      sortable: true,
    }
  ];

  return (
    <table className="biomarkers-table w-100">
      <thead>
        <tr className="border-b border-color-gray border-bottom-width-table">
          <th className="border-e border-color-gray border-right-width-table">Date</th>
          <th>Maturation</th>
          <th>Pres. Max (mmHg)</th>
          <th>Avg. sucks</th>
          <th>Avg. Sucks/sec</th>
          <th>Breath/sec</th>
          <th>Bursts breath/sec</th>
          <th>Weight (g)</th>
          <th>Volume (ml)</th>
        </tr>
      </thead>
      <tbody>
        {
          data?.map((row, index) => (
            <tr className="border-b border-color-gray-100 border-bottom-width-table" key={index}>
              <td className="border-e border-color-gray border-right-width-table">{FormatDate(row.timestamp)}</td>
              <td>{ row.biomarkers.find((biomarker) => biomarker.name === 'MATURATION').value || 0}</td>
              <td>{ row.biomarkers.find((biomarker) => biomarker.name === 'PRESSURE-MAX-RELATIVE-TO-BASELINE').value || 0}</td>
              <td>{ row.biomarkers.find((biomarker) => biomarker.name === 'SUCKS-PER-BURST').value || 0}</td>
              <td>{ row.biomarkers.find((biomarker) => biomarker.name === 'SUCKS-PER-MIN-IN-BURST').value || 0}</td>
              <td>{ row.biomarkers.find((biomarker) => biomarker.name === 'BREATHS-IN-BURSTS').value || 0 }</td>
              <td>{ row.biomarkers.find((biomarker) => biomarker.name === 'BREATHS-IN-RESTS').value || 0 }</td>
              <td>{ ConvertToGrams(row.weight, row.weight_uom) }</td>
              <td>{ `${row.volume} ${row.volume_uom}` }</td>
            </tr>
          ))
        }

      </tbody>
    </table>
  )
}

export default BiomarkersTable;