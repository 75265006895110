import {PaginatedTable} from '../PaginatedTable/PaginatedTable';
import {FiEdit2} from "react-icons/fi";
import { LuBaby, LuEye } from "react-icons/lu";
import FormatDate from '../../utilities/FormatDate';
import { useContext } from 'react';
import { AuthContext } from "../../auth";
import { Roles } from '../../data_layer/repositories/Users/UserRoles';
import { Link } from 'react-router-dom';


function PatientsTable(props)
{

  const {user} = useContext(AuthContext);

  const patientsColumns = [
    {
      name: 'Patient ID',
      selector: row => row.ehr_patient_ref,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true
    },
    {
      name: 'Gestational Age at Birth (weeks)',
      selector: row => row.gestational_age,
      sortable: true,
    },
    {
      name: 'Birth Weight',
      selector: row => `${ row.birth_weight } ${row.weight_uom}`,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Feedings',
      selector: row => `${ row.total_feedings || 0 }`,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Date created',
      selector: row => FormatDate(row.created_at),
      sortable: true,
      width: '160px',
    },
    {
      name: 'Date updated',
      selector: row => FormatDate(row.updated_at),
      sortable: true,
      width: '160px',
    },
    {
      name: 'Active',
      selector: row => row.patient_status === 'active' ? 'Yes' : 'No',
      sortable: true,
      width: '100px'
    }
  ];

  if (user.role === Roles.NeoneurAdmin){
    patientsColumns.push({
      name: '',
      selector: (row) => <Link className="neo-icon icon-link icon-link-hover" 
                              to={`${row.patient_id}`}>
                              <LuEye  size={18} />
                            </Link> ,
      width: '50px'
    });
  }

  patientsColumns.push(
    {
      name: '',
      selector: (row) => <Link className="neo-icon icon-link icon-link-hover" 
                              onClick={() => props.onRowClicked(row)}>
                              <FiEdit2  size={18} />
                            </Link> ,
      width: '50px'
    });

    const sortedData = props.data.sort((a, b) => {
      // Sort by status first ('active' before 'inactive')
      if (a.patient_status === b.patient_status) {
        // If statuses are the same, sort by name alphabetically
        return a.ehr_patient_ref.localeCompare(b.ehr_patient_ref);
      }
      // If statuses are different, sort 'active' before 'inactive'
      return a.patient_status === 'active' ? -1 : 1;
    });

  return (
    <PaginatedTable
      name={ props.facility ? `${props.facility} / Patients` : 'Patients'}
      icon={<LuBaby size={25}/>}
      data={sortedData}
      columns={patientsColumns}
      onRowClicked={ props.onRowClicked }/>
  )
}

export default PatientsTable;