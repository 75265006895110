import './ReviewConflictsModalDataLine.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FiAlertCircle} from "react-icons/fi"

function MergeConfirmDataLine(props) {
  return (
    <Row className={props.conflict? 'MergeConfirmRow' : 'InvisibleCollapse'}>
      <Col className='col-sm-6'>
        <FiAlertCircle className='DataRowIcon'/>
        <label className='HighlightedDataLabel'>{props.label}</label>
      </Col>
      <Col className='col-sm-6'>
        <input className='DataRowRadioButtonUnclickable' type="radio" name={"Confirm Merge " + props.label} defaultChecked></input>
        <label className='HighlightedDataValue'>{props.selectedParameter} {props.units}</label>
      </Col>
      {/* <Col></Col>
      <Col></Col> */}
    </Row>
  );
}

export default MergeConfirmDataLine;
