import { useEffect, useState } from "react";
import GetAllHandhelds from "../../data_layer/repositories/Handhelds/GetAllHandhelds";
import GetHandheldsByFacility from "../../data_layer/repositories/Handhelds/GetHandheldsByFacility";
import { Roles } from "../../data_layer/repositories/Users/UserRoles";

export const useGetAllHandhelds = (role, facility_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [handhelds, setHandhelds] = useState([]);

    useEffect(() => {
        getHandhelds();
    }, [role, facility_id]);

    const getHandhelds = async () => {
        setIsLoading(true);

        let response = []

        if  (role == Roles.ItAdmin ){
            if (facility_id) {
                response = await GetHandheldsByFacility(facility_id);
            }
        }
        else{
            response = await GetAllHandhelds();
        }

        setHandhelds(response.sort((a, b) => a.serial_number > b.serial_number ? 1 : -1));

        setIsLoading(false);
    }

    const reloadData = () => {
        getHandhelds();
    }


    return {
        isLoading,
        handhelds,
        reloadData,
    }
}
