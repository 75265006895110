export function WeeksBetweenDates(conception, feeding){

    if (conception != 0){

        const conceptionDateMillis = conception > 9_999_999_999 ? conception : conception * 1000;
        const feedingDateMillis = feeding > 9_999_999_999 ? feeding : feeding * 1000;
        const millisPerWeek = 1000 * 60 * 60 * 24 * 7;
    
        const ageInMillis = feedingDateMillis - conceptionDateMillis;
        const ageInWeeks = ageInMillis / millisPerWeek;
    
        return ageInWeeks.toFixed(1);
      }
      return 0
}