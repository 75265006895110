import React from 'react'

export const Loading = ({ isLoading }) => {

    return (
        <>
            {
                isLoading && <div className="d-flex flex-column justify-content-center align-items-center neo_ut_mt_24">
                    <div className="p-2 spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className='p-2'>
                        Loading...
                    </div>
                </div>
            }
        </>
    )
}
