import { GetApi, GetMobileDevicesByFacilityPath } from "../../apis/Api";

async function GetMobileDevicesByFacility(facility_id) {

  var mobileDevices = await GetApi(`${GetMobileDevicesByFacilityPath}/${facility_id}`)
    .then(response => response.json())
    .then(data => convert(data)); 
 
  return mobileDevices;
};

function convert(response) {
  return response.result.map((item)=>convertEach(item))
}

function convertEach(item) {
  // no converting needed right now.
  return item;
}

export default GetMobileDevicesByFacility;