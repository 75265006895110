import { Link, useLocation } from "react-router-dom"
import { useContext, useState } from 'react';
import { AuthContext } from "../../auth";
import { Roles } from '../../data_layer/repositories/Users/UserRoles'
import { TimeOutHandler } from "../IdleTimeOutHandler/TimeOutHandler";
import { MillisOnMinute, isTimeoutActive } from "../../utilities/Constants";
import { ProfileDropdown } from "./ProfileDropdown";

export const Navbar = () => {

    const {logout, user, facility} = useContext( AuthContext );

    const [isActive, setIsActive] = useState(true);
    const [isLogout, setIsLogout] = useState(false);

    const { pathname } = useLocation();

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light neo_navbar_container">
            {
                isTimeoutActive && <TimeOutHandler 
                    onActive={() => { setIsActive(true) }} 
                    onIdle={() => { setIsActive(false) }}
                    onLogout={() => { 
                            setIsLogout(true) ;
                            logout();
                        }}
                    timeOutInterval={ facility.web_timeout_session * MillisOnMinute }/>
            }

            <div className="container-fluid neu_ut_gap_32 p-0">
                
                <Link to="/" className='navbar-brand neo_nav_brand'>        
                    <img 
                        src={require('../../assets/Neoneuro-FF.png')}
                        alt="Neoneur Logo"
                        className="w-100"
                    />
                </Link>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav neo_nav_item_container">

                        { (user.role === Roles.ClinicalAdmin || user.role === Roles.Clinician) && 
                            <li className="nav-item">
                                <Link to="patients" aria-current="page" className={`nav-link neo_nav_link ${pathname.includes("patients") ? "active": ""}`}>
                                    Patients
                                </Link>
                            </li>
                        }

                        { user.role === Roles.NeoneurAdmin && 
                            <li className="nav-item">
                                <Link to="facilities" aria-current="page" className={`nav-link neo_nav_link ${pathname.includes("facilities") ? "active": ""}`}>
                                    Facilities
                                </Link>
                            </li>
                        }

                        { (user.role === Roles.ItAdmin || user.role === Roles.NeoneurAdmin) && 
                            <li className="nav-item">
                        
                                <Link to="users" aria-current="page" className={`nav-link neo_nav_link ${pathname.includes("users") ? "active": ""}`}>
                                    Users
                                </Link>
                            </li>
                        }   

                        { user.role === Roles.ItAdmin && 
                            <li className="nav-item">
                                <Link to="mobiles" className={`nav-link neo_nav_link ${pathname.includes("mobiles") ? "active": ""}`}>
                                    Mobiles
                                </Link>
                            </li>
                        }

                        { (user.role === Roles.ItAdmin || user.role === Roles.NeoneurAdmin) && 
                            <li className="nav-item">
                                <Link to="handhelds" className={`nav-link neo_nav_link ${pathname.includes("handhelds") ? "active": ""}`}>
                                    Handhelds
                                </Link>
                            </li>
                        }
                    </ul>


                    <ul className="navbar-nav align-items-baseline neo_nav_item_container ms-auto">
                        { user.role === Roles.ClinicalAdmin && 
                            <li className="nav-item">
                                <Link to="notifications" className={`nav-link neo_nav_link ${pathname.includes("notifications") ? "active": ""}`}>
                                    Notifications
                                </Link>
                            </li>
                        }
                        
                        {/* <li className="nav-item">
                            <a className='neo_outline_btn' onClick={ logout }>Logout</a>

                        </li> */}

                        <li className="nav-item">


                            <ProfileDropdown user={user} facility={facility} logout={logout}/>
                        </li>
                    </ul>


                    
                </div>
            </div>
        </nav>
    )
}