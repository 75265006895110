import CryptoJS from "crypto-js";

function EncryptPasscode(passcode_before_encrypt, login_id) {

  const passcode = CryptoJS.PBKDF2(
    passcode_before_encrypt,
    login_id,
    {
      hasher: CryptoJS.algo.SHA512,
      keySize: 4,
      iterations: 1
    });

    return passcode.toString(CryptoJS.enc.Hex);
};

export default EncryptPasscode;