
function CalculateConceptionalAge(dateOfConceptionInSecs) {

  if (dateOfConceptionInSecs != 0){

    const dateOfConceptionInMillis = dateOfConceptionInSecs * 1000;
    const millisPerWeek = 1000 * 60 * 60 * 24 * 7;

    // TODO: Should remove the time component of the date
    var todayInMillis = Date.now();

    var ageInMillis = todayInMillis - dateOfConceptionInMillis;
    var ageInWeeks = ageInMillis / millisPerWeek;

    return ageInWeeks.toFixed(2);
  }
  return 0
};

export default CalculateConceptionalAge;