import {GetApi, GetFeedingSessionPath} from "../../apis/Api";

export async function GetFeedingSession(feeding_timestamp) {

    return await GetApi(GetFeedingSessionPath(feeding_timestamp))
        .then(response => response.json())
        .then(data => convert(data.result.feeding, data.result.measurements));
}

function convert(feeding, total_measurements) {
    return {
        ...feeding,
        patient_id : feeding.patient_id,
        timestamp : feeding.timestamp,
        session_index : feeding.session_index,
        feeding_name : feeding.feeding_name,
        calculate_age : feeding.calculate_age? Number(feeding.calculate_age).toFixed(1) : '--',
        weight : feeding.weight? Number(feeding.weight).toFixed(1) : '--',
        weight_uom : feeding.weight_uom,
        consumption : feeding.consumption,
        consumption_uom : feeding.consumption_uom,
        nipple : feeding.nipple,
        nutrient_type : feeding.nutrient_type,
        percent_nutrient : feeding.percent_nutrient,
        position : feeding.position,
        note : feeding.note,
        paced : feeding.paced,
        readiness_score : feeding.readiness_score,
        quality_score : feeding.quality_score,
        hh_serial_number : feeding.hh_serial_number,
        mobile_serial_number : feeding.mobile_serial_number,
        user_id : feeding.user_id,
        volume : feeding.volume ? Number(feeding.volume).toFixed(1) : '--',
        volume_uom : feeding.volume_uom,
        duration: total_measurements * 5
    };
}