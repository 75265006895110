import React, { useState } from 'react'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'
import { Modal } from 'react-bootstrap';

export const ResultModal = ({showModal, title, message, isSuccess, onHide}) => {
    
    return (
        <>
            <Modal show={showModal} 
                onHide={onHide} 
                size='sm' 
                centered={true} >
                
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="p-2">
                            {
                                isSuccess ?  
                                    <FiCheckCircle size={75}/> :
                                    <FiXCircle size={75}/>
                            }
                            
                        </div>
                        <div className='p-2 neo_header_center'>
                            <h4><center>{ title }</center></h4>
                        </div>
                        <div className='p-2 neo_header_center'>
                            <strong>{ message }</strong>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}
