import { PostApi, MergeTwoPatientsWithConflict } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";

async function MergePatients(
  patientToSave,
  patientToDelete,
  selectedPatientParameters,
  setResultMessage) {

  const fullName = selectedPatientParameters.lastName + ", " + selectedPatientParameters.firstName;

  const payload =
  {
    original_patient: {
      patient_id: patientToSave.patientId,
      ehr_patient_ref: patientToSave.ehrReference,
      name: fullName,
      gestational_age: selectedPatientParameters.gestationalAge,
      birth_weight: selectedPatientParameters.birth_weight,
      weight_uom: selectedPatientParameters.weight_uom,
      date_of_conception: selectedPatientParameters.dateOfConception,
      user_id: patientToSave.user_id,
      facility_id: patientToSave.facility_id,
      mobile_serial_number: patientToSave.mobile_serial_number,
      admission_status: patientToSave.admission_status,
      note: patientToSave.note
    },
    delete_patient_id: patientToDelete.patientId
  };

  await PostApi(MergeTwoPatientsWithConflict, payload)
    .then(response => response.json())
    .then(output => WriteResultMessage(output, setResultMessage));
};

export default MergePatients;