import { PostApi, CreateAPatient } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";
import CalculateDateOfConception from "../../../utilities/CalculateDateOfConception";
import { v4 as uuidv4 } from 'uuid';

async function CreateConflictPatients(numberToCreate)
{
  function getRandomNumber(length)
  {
    return Math.floor(Math.random() * length);
  }

  const sharedEhrReference = "3579"

  const possibleFirstNames = ["A", "B", "C", "D", "E"];
  const possibleLastNames = ["AAA","BBB","CCC","DDD","EEE"];
  const possibleGestationalAges = [34, 35, 36, 37, 38];
  const possibleConceptionalAgeFactors = [1, 2];

  for (var i = 0; i < numberToCreate; i++)
  {
    var firstName = possibleFirstNames[getRandomNumber(possibleFirstNames.length)];
    var lastName = possibleLastNames[getRandomNumber(possibleLastNames.length)];
    var gestationalAge = possibleGestationalAges[getRandomNumber(possibleGestationalAges.length)];
    var conceptionalAge = gestationalAge - possibleConceptionalAgeFactors[getRandomNumber(possibleConceptionalAgeFactors.length)];
  
    var dateOfConception = CalculateDateOfConception(conceptionalAge);

    var fullName =  lastName + ", " + firstName;
  
    var payload = 
    {
      patient: {
          patient_id: uuidv4(),
          ehr_patient_ref: sharedEhrReference,
          name: fullName,
          gestational_age: gestationalAge,
          birth_weight: "3.2",
          weight_uom: "kg",
          date_of_conception: dateOfConception,
          user_id: "97e3e8ab-71d9-4b3e-a475-717dd63edcb8",
          facility_id: "139b62ce-a531-4691-bc6b-d900637533e8",
          mobile_serial_number: "1",
          admission_status: "",
          note: "This is an autogenerated test patient"
      }
    }

    PostApi(CreateAPatient, payload)
    .then(response => response.json())
    .then(output => WriteResultMessage(output, ()=>{}));
  }
 
}
export default CreateConflictPatients;