import "../../../styles/Shared.css";
import "../../../styles/Modals.css";
import './ReviewConflictsModal.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FiAlertCircle} from "react-icons/fi"
import {useState, useEffect} from 'react'

import ReviewConflictsModalDataLine from './ReviewConflictsModalDataLine';
import {ModalStates} from './ReviewConflictModalStates';
import { PatientBirthWeightMinValue, PatientConceptualAgeMinValue, PatientEhrMaxLength, PatientFirstNameMaxLength, PatientGestationalAgeMaxValue, PatientGestationalAgeMinValue, PatientLastNameMaxLength } from "../../../utilities/Constants";
import EditConfirmDataLine from './EditConfirmDataLine';
import MergeConfirmDataLine from './MergeConfirmDataLine';
import MergePatients from "../../../data_layer/repositories/Patients/MergePatients";

import { InputValidationErrorStates, HasErrors } from '../UpdateEntityModals/InputValidation';
import CheckPatientExistsByEhr from "./CheckPatientExistsByEhr";
import ResolveMissingInformation from "../../../data_layer/repositories/Patients/ResolveMissingInformation";

function ReviewConflictsModal(props) {

  const defaultResultMessage = "Writing...";
  const defaultEntryMaxLength = 30;
  
  const firstNameLabel = "First initial";
  const lastNameLabel = "Last name (first three letters)";
  const ehrReferenceLabel = "Patient ID";
  const gestationalAgeLabel = "Gestational age";
  const conceptionalAgeLabel = "Conceptual age";
  const birthWeightLabel = "Birth weight";

  const [modalState, setModalState] = useState(ModalStates.Review);
  const [selectedPatientParameters, setSelectedPatientParameters] = useState([]);
  const [editedOriginalPatient, setEditedOriginalPatient] = useState([]);
  const [editedPatient, setEditedPatient] = useState([]);
  const [resultMessage, setResultMessage] = useState(defaultResultMessage);
  const [errorStates, setErrorStates] = useState([]);

  useEffect(() => {
    setSelectedPatientParameters(
      {
        firstName: props.originalPatient.firstName,
        lastName: props.originalPatient.lastName,
        gestationalAge: props.originalPatient.gestationalAge,
        dateOfConception: props.originalPatient.dateOfConception,
        ehrReference: props.originalPatient.ehrReference,
        conceptionalAge: props.originalPatient.conceptionalAge ,
        birth_weight: props.originalPatient.birth_weight ,
        weight_uom: props.originalPatient.weight_uom 
      });
  },[props.originalPatient]);

  useEffect(() => {
    clearErrorStates();
    setEditedOriginalPatient(props.originalPatient);

  },[props.originalPatient]);

  useEffect(() => {
    setEditedPatient(props.patient);
  },[props.patient]);

  function clearErrorStates()
  {
    setErrorStates(
      {
        patientEhrReference: InputValidationErrorStates.NoError,
        originalPatientEhrReference: InputValidationErrorStates.NoError,
      }
    )
  }

  async function validateInputs(errorsAction, noErrorsAction)
  {
    var originalPatientEhrErrorState;
    var patientEhrErrorState;

    if (editedPatient.ehrReference === editedOriginalPatient.ehrReference)
    {
      originalPatientEhrErrorState = InputValidationErrorStates.MustBeDifferent;
      patientEhrErrorState = InputValidationErrorStates.MustBeDifferent;
    }
    else
    {
      originalPatientEhrErrorState = await CheckPatientExistsByEhr(editedOriginalPatient.ehrReference, editedOriginalPatient.facility_id, editedOriginalPatient.patientId);
      patientEhrErrorState = await CheckPatientExistsByEhr(editedPatient.ehrReference, editedPatient.facility_id, editedPatient.patientId);  
    }

    setErrorStates({...errorStates, 
      patientEhrReference: patientEhrErrorState,
      originalPatientEhrReference: originalPatientEhrErrorState,
    })

    if (HasErrors([originalPatientEhrErrorState, patientEhrErrorState])) {
      errorsAction();
    } else {
      noErrorsAction();
    }
  }

  async function exitConfirmMerge()
  {
    await MergePatients(
        props.originalPatient,
        props.patient,
        selectedPatientParameters,
        setResultMessage
      )
      .then(props.updateAlerts);
  }

  async function exitConfirmEdit()
  {
    // TODO: display both results separately.
    await ResolveMissingInformation(editedOriginalPatient, setResultMessage)
      .then(await ResolveMissingInformation(editedPatient, setResultMessage))
      .then(props.updateAlerts);
  }

  function updateSelectedParameter(parameter, newValue)
  { 
    setSelectedPatientParameters(selectedPatientParameters => ({...selectedPatientParameters, [parameter]:newValue}));

    if (parameter === "conceptionalAge") // then also set dateOfConception
    {
      if (newValue === props.patient.conceptionalAge)
      {
        setSelectedPatientParameters(selectedPatientParameters => 
          ({...selectedPatientParameters, "dateOfConception":props.patient.dateOfConception}));
      }
      else
      {
        setSelectedPatientParameters(selectedPatientParameters => 
          ({...selectedPatientParameters, "dateOfConception":props.originalPatient.dateOfConception}));
      }
    }
  }

  function updateEditedParameter(isOriginalPatient, parameter, newValue)
  {
    if (isOriginalPatient === true)
    {
      setEditedOriginalPatient(editedOriginalPatient => ({...editedOriginalPatient, [parameter]:newValue}))
    }
    else
    {
      setEditedPatient(editedPatient => ({...editedPatient, [parameter]:newValue}))
    }
  }

  function getHeaderLabel()
  { 
    switch(modalState) {
      case ModalStates.Review:
        return "Review conflict";
      case ModalStates.Merge:
      case ModalStates.ConfirmMerge:
        return "Select correct data";
      case ModalStates.Edit:
      case ModalStates.ConfirmEdit:
        return "Edit conflict";
      case ModalStates.ResultMessage:
        return "Result"
      default:
        return "";
    }
  }

  function getPrimaryButtonText()
  {
    switch(modalState) {
      case ModalStates.Review:
        return "Edit Patient ID";
      case ModalStates.Merge:
      case ModalStates.Edit:
        return "Save";
      case ModalStates.ConfirmMerge:
      case ModalStates.ConfirmEdit:
        return "Yes";
      case ModalStates.ResultMessage:
        return "Close";
      default:
        return "";
    }
  }

  function getSecondaryButtonText()
  {
    switch(modalState) {
      case ModalStates.Review:
        return "Select data";
      case ModalStates.Merge:
      case ModalStates.Edit:
      case ModalStates.ResultMessage:
        return ""; // Don't care, will be invisible
      case ModalStates.ConfirmMerge:
      case ModalStates.ConfirmEdit:
        return "Edit";
      default:
        return "";
    }
  }

  function getSecondaryButtonStyle()
  {
    switch(modalState) {
      case ModalStates.Merge:
      case ModalStates.Edit:
      case ModalStates.ResultMessage:
        return 'Invisible'; 
      default:
        return 'ModalSecondaryButton neo_qv_btn';
    }
  }

  function getDismissButtonStyle()
  {
    switch(modalState) {
      case ModalStates.Review:
      case ModalStates.Merge:
      case ModalStates.ConfirmMerge:
      case ModalStates.Edit:
      case ModalStates.ConfirmEdit:
        return 'ModalDismissButton';
      default:
        return 'Invisible'; 
    }
  }

  async function primaryButtonAction()
  {
    switch(modalState) {
      case ModalStates.Review:
        setModalState(ModalStates.Edit);
        break;
      case ModalStates.Merge:
        setModalState(ModalStates.ConfirmMerge);
        break;
      case ModalStates.Edit:
        validateInputs(
          ()=>{},
          ()=>{setModalState(ModalStates.ConfirmEdit);}
        );
        break;
      case ModalStates.ConfirmMerge:
        exitConfirmMerge();
        //props.updateAlerts();
        setModalState(ModalStates.ResultMessage);
        break;
      case ModalStates.ConfirmEdit:
        exitConfirmEdit();
        //props.updateAlerts();
        setModalState(ModalStates.ResultMessage);
        break;
      case ModalStates.ResultMessage:
        props.close();
        setResultMessage(defaultResultMessage);
        setModalState(ModalStates.Review);
        break;
      default:
        break;
    }
  }

  function secondaryButtonAction()
  {
    switch(modalState) {
      case ModalStates.Review:
        setModalState(ModalStates.Merge);
        break;
      case ModalStates.ConfirmMerge:
        setModalState(ModalStates.Merge);
        break;
      case ModalStates.ConfirmEdit:
        setModalState(ModalStates.Edit);
        break;
      case ModalStates.Merge: 
      case ModalStates.Edit:
      case ModalStates.ResultMessage:
      default:
        break;
    }
  }

  function dismissButtonAction(closeAction)
  {
    closeAction();
    setModalState(ModalStates.Review);
  }

  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal 
        show={props.show}
        backdrop="static"
        centered
        size="xl">
        <Modal.Header
          closeButton='true'
          onHide={() => dismissButtonAction(props.close)}>
          <div className='Column'>
            <div className='Row'>
              <Modal.Title>
                <div className='ModalHeader'>
                  <FiAlertCircle className='ModalHeaderIcon' size={20}/>
                  <label className='ModalHeaderLabel'>{getHeaderLabel()}</label>
                </div>
              </Modal.Title>
            </div>
            {/* <div>
              <label className='ReviewConflictsSubheader'>{props.reviewAlert.date}</label>
              <label className='ReviewConflictsSubheader'>|</label>
              <label className='ReviewConflictsSubheader'>{props.reviewAlert.issue}</label>
            </div> */}
          </div>
        </Modal.Header>
        <Modal.Body>
          <label className={modalState === ModalStates.ResultMessage ? 'ResultMessage' : 'InvisibleCollapse'}>{resultMessage}</label>
          <Container className={modalState === ModalStates.ResultMessage ? 'InvisibleCollapse' : 'null'}>

            
          <ReviewConflictsModalDataLine 
              modalState={modalState}
              labelName={ehrReferenceLabel}
              parameterName='ehrReference'
              originalPatientParameter={editedOriginalPatient.ehrReference}
              patientParameter={editedPatient.ehrReference}
              conflict={props.conflicts.ehrReference}
              entryMaxLength={ PatientEhrMaxLength }
              radioButtonChecked={updateSelectedParameter}
              textEntryChanged={updateEditedParameter}
              units=""
              originalPatientErrorState={errorStates.originalPatientEhrReference}
              patientErrorState={errorStates.patientEhrReference}/>
            <hr className='HorizontalLine'></hr>

            <Row>
              <Col className="col-sm-1" ></Col>
              <Col className="col-sm-4">
                <label className='NormalDataLabel'>Date entered</label>
              </Col>
              <Col className='NormalDataValue col-sm-3'>{props.originalPatient.dateCreated}</Col>
              <Col className='NormalDataValue col-sm-3'>{props.patient.dateCreated}</Col>
            </Row>

            <hr className='HorizontalLine'></hr>
            
            <Row>
              <Col className="col-sm-1" ></Col>
              <Col className="col-sm-4">
                <label className='NormalDataLabel'>User</label>
              </Col>
              <Col className='NormalDataValue col-sm-3'>{props.reviewAlert.original_user_name}</Col>
              <Col className='NormalDataValue col-sm-3'>{props.reviewAlert.user_name}</Col>
            </Row>

            <hr className='HorizontalLine'></hr>
            <ReviewConflictsModalDataLine 
              modalState={modalState}
              labelName={firstNameLabel}
              parameterName='firstName'
              originalPatientParameter={editedOriginalPatient.firstName}
              patientParameter={editedPatient.firstName}
              conflict={props.conflicts.firstName}
              entryMaxLength={ PatientFirstNameMaxLength }
              radioButtonChecked={updateSelectedParameter}
              textEntryChanged={updateEditedParameter}
              units=""/>
            <hr className='HorizontalLine'></hr>
            <ReviewConflictsModalDataLine 
              modalState={modalState}
              labelName={lastNameLabel}
              parameterName='lastName'
              originalPatientParameter={editedOriginalPatient.lastName}
              patientParameter={editedPatient.lastName}
              conflict={props.conflicts.lastName}
              entryMaxLength={ PatientLastNameMaxLength }
              radioButtonChecked={updateSelectedParameter}
              textEntryChanged={updateEditedParameter}
              units=""/>
            <hr className='HorizontalLine'></hr>

            <ReviewConflictsModalDataLine 
              modalState={modalState}
              labelName={birthWeightLabel}
              parameterName='birth_weight'
              originalPatientParameter={editedOriginalPatient.birth_weight}
              patientParameter={editedPatient.birth_weight}
              conflict={ props.conflicts.birth_weight }
              entryMaxLength={ defaultEntryMaxLength }
              radioButtonChecked={updateSelectedParameter}
              textEntryChanged={updateEditedParameter}
              units={" " + editedPatient.weight_uom}
              type='number'
              minValue={ PatientBirthWeightMinValue }/>
            <hr className='HorizontalLine'></hr>

            <ReviewConflictsModalDataLine 
              modalState={modalState}
              labelName={gestationalAgeLabel}
              parameterName='gestationalAge'
              originalPatientParameter={editedOriginalPatient.gestationalAge}
              patientParameter={editedPatient.gestationalAge}
              conflict={props.conflicts.gestationalAge}
              entryMaxLength={defaultEntryMaxLength}
              radioButtonChecked={updateSelectedParameter}
              textEntryChanged={updateEditedParameter}
              units=" weeks"
              type='number'
              minValue={ PatientGestationalAgeMinValue }
              maxValue={ PatientGestationalAgeMaxValue }/>
            <hr className='HorizontalLine'></hr>

            <ReviewConflictsModalDataLine 
              modalState={modalState}
              labelName={conceptionalAgeLabel}
              parameterName='conceptionalAge'
              originalPatientParameter={editedOriginalPatient.conceptionalAge}
              patientParameter={editedPatient.conceptionalAge}
              conflict={props.conflicts.dateOfConception}
              entryMaxLength={defaultEntryMaxLength}
              radioButtonChecked={updateSelectedParameter}
              textEntryChanged={updateEditedParameter}
              units=" weeks"
              type='number'
              minValue={ PatientConceptualAgeMinValue }/>

          </Container>
          <div className={modalState === ModalStates.ConfirmMerge ? 'null' : 'InvisibleCollapse'}>
            <hr className='ThickLine'></hr>
            <label className="ConfirmHeader">Are you sure you want to save your changes?</label>
            <MergeConfirmDataLine 
              conflict={props.conflicts.ehrReference}
              label={ehrReferenceLabel}
              selectedParameter={selectedPatientParameters.ehrReference}
              units=""/>
            <MergeConfirmDataLine 
              conflict={props.conflicts.firstName}
              label={firstNameLabel}
              selectedParameter={selectedPatientParameters.firstName}
              units=""/>
            <MergeConfirmDataLine 
              conflict={props.conflicts.lastName}
              label={lastNameLabel}
              selectedParameter={selectedPatientParameters.lastName}
              units=""/>
            <MergeConfirmDataLine 
              conflict={props.conflicts.birth_weight}
              label={birthWeightLabel}
              selectedParameter={selectedPatientParameters.birth_weight}
              units={" " + selectedPatientParameters.weight_uom}/>
            <MergeConfirmDataLine 
              conflict={props.conflicts.gestationalAge}
              label={gestationalAgeLabel}
              selectedParameter={selectedPatientParameters.gestationalAge}
              units=" weeks"/>
            <MergeConfirmDataLine 
              conflict={props.conflicts.dateOfConception}
              label={conceptionalAgeLabel}
              selectedParameter={selectedPatientParameters.conceptionalAge}
              units=" weeks"/>
          </div>
          <div className={modalState === ModalStates.ConfirmEdit ? 'null' : 'InvisibleCollapse'}>
            <hr className='ThickLine'></hr>
            <label className="ConfirmHeader">Are you sure you want to save your changes?</label>
            <EditConfirmDataLine 
              label={ehrReferenceLabel}
              originalPatientEditedParameter={editedOriginalPatient.ehrReference}
              patientEditedParameter={editedPatient.ehrReference}
              conflict={props.conflicts.ehrReference}
              units=""/>
            <EditConfirmDataLine 
              label={firstNameLabel}
              originalPatientEditedParameter={editedOriginalPatient.firstName}
              patientEditedParameter={editedPatient.firstName}
              conflict={props.conflicts.firstName}
              units=""/>
            <EditConfirmDataLine 
              label={lastNameLabel}
              originalPatientEditedParameter={editedOriginalPatient.lastName}
              patientEditedParameter={editedPatient.lastName}
              conflict={props.conflicts.lastName}
              units=""/>
            <EditConfirmDataLine 
              label={birthWeightLabel}
              originalPatientEditedParameter={editedOriginalPatient.birth_weight}
              patientEditedParameter={editedPatient.birth_weight}
              conflict={props.conflicts.birth_weight}
              units={" " + editedPatient.weight_uom}/>  
            <EditConfirmDataLine 
              label={gestationalAgeLabel}
              originalPatientEditedParameter={editedOriginalPatient.gestationalAge}
              patientEditedParameter={editedPatient.gestationalAge}
              conflict={props.conflicts.gestationalAge}
              units=" weeks"/>
            <EditConfirmDataLine 
              label={conceptionalAgeLabel}
              originalPatientEditedParameter={editedOriginalPatient.conceptionalAge}
              patientEditedParameter={editedPatient.conceptionalAge}
              conflict={props.conflicts.dateOfConception}
              units=" weeks"/>
          </div>
        </Modal.Body>

        <Modal.Footer className='ModalFooter'>
          {/* <Button onClick={() => dismissButtonAction(props.close)} className={getDismissButtonStyle()}>Go back</Button>
          <div className='Spacer'></div> */}
          <Button onClick={() => secondaryButtonAction()} className={getSecondaryButtonStyle()}>{getSecondaryButtonText()}</Button>
          <Button onClick={() => primaryButtonAction()} className="ModalPrimaryButton neo_btn_primary">{getPrimaryButtonText()}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReviewConflictsModal;