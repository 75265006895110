import { useContext } from "react"
import { AuthContext } from "../auth"
import { Navigate } from "react-router-dom";
import UsersPage from "../pages/UsersPage/UsersPage";


export const PublicRoute = ({ children }) => {

    const { logged } = useContext( AuthContext );

    return (!logged)
        ? children
        : <Navigate to='/dashboard' /> // Change this for admin

}