import { GetApi, GetPatientByEhrAndFacility } from "../../apis/Api";

async function PatientExistsByEhr(ehr_patient_ref, facility_id, patient_id) {

  var count = await GetApi(GetPatientByEhrAndFacility + "/" + ehr_patient_ref + "/" + facility_id)
  .then(response => response.json())
  .then(data => parse(data, patient_id));
 
  return count;
};

function parse(data, patient_id) {

  if (data.result.length === 0)
  {
    return false;
  }
  
  const element = data.result.find((element) => element.patient_id === patient_id);
  
  return !element;
}

export default PatientExistsByEhr;