import React, { useEffect } from 'react'
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';

import { useGetMeasurements } from '../CustomHooks/useGetMeasurements'
import FormatDate from '../../utilities/FormatDate';
import ConvertMeasurementsToString from '../../utilities/ConvertMeasurementsToString';

export const ExportFeedingSessionCSV = (props) => {

    const { measurements, isLoading, getData: getMeasurementsData } = useGetMeasurements(props.feeding.timestamp, props.feeding.patient_id, false);

    const getMeasurements = (event) => {
        event.preventDefault();

        getMeasurementsData();
    }

    useEffect(() => {
        downloadCSV();
    }, [measurements]);

    const downloadCSV = () => {

        if (measurements.length > 0) {
            
            const csvString = ConvertMeasurementsToString(props.feeding, measurements);

            // Create a Blob from the CSV string
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

            let fileName = `AWS ${props.patient.ehr_patient_ref} ${props.patient.name.replace(", ","-")}_`;
            fileName += `${FormatDate(props.feeding.timestamp).replace("/","-").replace(":", "-")}.csv`

            // Generate a download link and initiate the download
            // const url = URL.createObjectURL(blob);
            // const link = document.createElement('a');
            // link.href = url;
            // link.download = fileName;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
            // URL.revokeObjectURL(url);
            saveAs(blob, fileName);
        }
    };


    return (
        <>

            {isLoading &&
                <div className="p-1 spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            }
            {!isLoading && <Link className="neo-icon icon-link icon-link-hover"
                onClick={getMeasurements} >
                <FiDownload size={18} />
            </Link>
            }
        </>
    )
}
