import { GetApi, GetAllMobileDevices } from "../../apis/Api";

async function GetMobileDevices() {

  const mobileDevices = await GetApi(GetAllMobileDevices)
    .then(response => response.json())
    .then(data => convert(data)); 
 
  return mobileDevices;
};

function convert(response) {
  return response.result?.map((item)=>convertEach(item))
}

function convertEach(item) {
  // no converting needed right now.
  return item;
}

export default GetMobileDevices;