import React, { useState } from 'react'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'
import { Button, Modal } from 'react-bootstrap';

export const BasicModal = ({ showModal, title, message, successFunction, onHide }) => {

    return (
        <>
            <Modal show={showModal}
                onHide={onHide}
                size='lg'
                centered={true} >

                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className='p-2'>
                            <h4>{title}</h4>
                        </div>
                        <div className='p-2'>
                            {message}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={onHide} className="ModalSecondaryButton neo_qv_btn">Cancel</Button>
                    <Button onClick={() => successFunction()} className="ModalPrimaryButton neo_btn_primary">Reset</Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}
