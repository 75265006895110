function CalculateDateOfConception(conceptionalAgeInWeeks) {

  const millisPerWeek = 1000 * 60 * 60 * 24 * 7;
  const conceptionalAgeInMillis = conceptionalAgeInWeeks * millisPerWeek;
  const todayInMillis = Date.now();
  const dateOfConceptionInMillis = todayInMillis - conceptionalAgeInMillis;
 
  const dateOfConceptionInSecs = (dateOfConceptionInMillis / 1000).toFixed(0);

  return dateOfConceptionInSecs;
};

export default CalculateDateOfConception;