/**
 * Truncate a string and add ellipsis if it exceeds the specified length.
 * @param {string} text - The input string to truncate.
 * @param {number} maxLength - The maximum length of the string before truncation.
 * @returns {string} - The truncated string with ellipsis (if necessary).
 */

export default function ellipsis(text, maxLength) {
    if (!text || text.length <= maxLength) {
        return text; // Return the original text if no truncation is needed.
    }
    return text.substring(0, maxLength).trim() + '...';
}