import { GetApi, GetAllPatients, GetFavoritePatientsPath } from "../../apis/Api";
import CalculateConceptionalAge from "../../../utilities/CalculateConceptionalAge";
import FormatDate, { FormatSimpleDate } from "../../../utilities/FormatDate";
import SplitName from "../../../utilities/SplitName";

async function GetFavoritePatients(user_id) {

  var patients = await GetApi(GetFavoritePatientsPath(user_id))
    .then(response => response.json())
    .then(data => data.result); // TODO: Add error checking for multiple responses.
 
  return patients;
};

export default GetFavoritePatients;