import { Route, Routes } from "react-router-dom";
import { Navbar } from "../components/Navbar/Navbar";
import { UsersMainPage } from "../pages/UsersPage/UsersMainPage";
import { MobilesPage } from "../pages/MobilesPage/MobilesPage";
import { HandheldsPage } from "../pages/Handhelds/HandheldsPage";
//import Footer from "../components/Footer/Footer";


export const ITAdminRoutes = () => {
    return (
      <div className='neo_container'>
        <Navbar />
        <main>
          <Routes>
            <Route path="/mobiles" element={<MobilesPage />}/>
            <Route path="/users" element={<UsersMainPage />}/>
            <Route path="/handhelds" element={<HandheldsPage />}/>
            <Route path="/*" element={<UsersMainPage />}/>
          </Routes>
  
        </main>
        {/* <Footer /> */}
      </div>
      
    )
  }