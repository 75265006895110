import { MillisOnMinute } from "../../../utilities/Constants";
import { PostApi, CreateANewFacility, RegisterNewHandheldPath } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";

async function RegisterHandheld(
  editedEntity,
  setResultMessage,
  refreshAction)
{

  await PostApi(RegisterNewHandheldPath(), editedEntity)
    .then(response => response.json())
    .then(output => WriteResultMessage(output, setResultMessage))
    .then(refreshAction);
}
export default RegisterHandheld;