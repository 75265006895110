import "../../../styles/Shared.css";
import '../ReviewConflictsModal/ReviewConflictsModalDataLine.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiAlertCircle } from "react-icons/fi"

import { ModalStates } from './ResolveMissingDataModalStates';

function ResolveMissingDataModalDataLine(props) {

  function getDataRowIconStyle(dataWasMissing, modalState) {
    switch (modalState) {
      case ModalStates.Review:
      case ModalStates.Edit:
        return dataWasMissing === true ? 'DataRowRadioButton ' : 'InvisibleCollapse';
      case ModalStates.ConfirmEdit:
      default:
        return 'InvisibleCollapse';
    }
  }

  function getDataLabelStyle(dataWasMissing, modalState) {
    return dataWasMissing === true ? 'HighlightedDataLabel col-form-label' : 'NormalDataLabel col-form-label';
  }

  function getDataValueStyle(dataWasMissing, modalState) {
    switch (modalState) {
      case ModalStates.Review:
      case ModalStates.ConfirmEdit:
        return dataWasMissing === true ? 'HighlightedDataValue' : 'NormalDataValue';
      case ModalStates.Edit:
        return dataWasMissing === true ? 'InvisibleCollapse' : 'NormalDataValue';
      default:
        return 'InvisibleCollapse';
    }
  }

  return (
    <Row>
      <Col className="col-sm-1">
        <FiAlertCircle className={getDataRowIconStyle(props.dataWasMissing, props.modalState)} />
      </Col>
      <Col className="col-sm-4">
        <label className={getDataLabelStyle(props.dataWasMissing, props.modalState)}>{props.labelName}
          <label className={props.required === true ? 'RequiredStar' : 'InvisibleCollapse'}>*</label>
        </label>
      </Col>
      <Col className="col-sm-4">
        <label className={getDataValueStyle(props.dataWasMissing, props.modalState)}>
          {props.patientParameter}{props.units}
        </label>
        <input
          className={getDataValueStyle(props.dataWasMissing, props.modalState) === 'InvisibleCollapse' ? 'AddEntityModalEntry' : 'InvisibleCollapse'}
          type={props.entryType ? props.entryType : "text"}
          maxLength={props.entryMaxLength}
          defaultValue={props.patientParameter}
          onChange={e => props.textEntryChanged(props.parameterName, e.target.value)}
          min={props.minValue}
          max={props.maxValue}
          required={props.required} />

        {
          props.showError
          && <label className={'InputValidationErrorMessage'}>
            {props.showError}
          </label>
        }

      </Col>
      <Col className="col-sm-2">
        <label className={getDataValueStyle(props.dataWasMissing, props.modalState) === 'InvisibleCollapse' ? 'TextEntryValue' : 'InvisibleCollapse'}>
          {props.units}
        </label>
      </Col>
    </Row>
  );
}

export default ResolveMissingDataModalDataLine;