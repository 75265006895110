export const UserRoles = [
    {
        "name": "Clinician",
        "value": "clinician"
    },
    {
        "name": "Clinical Admin",
        "value": "clinical-admin"
    },
    {
        "name": "IT Admin",
        "value": "it-admin"
    },
    {
        "name": "Neoneur Admin",
        "value": "nn-admin"
    },
]

export const UserRolesNeoneurAdmin = [
    {
        "name": "IT Admin",
        "value": "it-admin"
    },
    {
        "name": "Neoneur Admin",
        "value": "nn-admin"
    },
];

export const Roles = {
    Clinician: 'clinician',
    ClinicalAdmin: 'clinical-admin',
    ItAdmin: 'it-admin',
    NeoneurAdmin: 'nn-admin',
}

export const GetRoleName = (key) => UserRoles.find((role) => role.value === key)?.name;
