import { useEffect, useRef, useState } from "react";
import { FormatDate, FormatSimpleDate } from "../../../utilities/FormatDate";

export const FeedingSessionDropdown = ({
    options,
    feedingTimestampsForComparison,
    setFeedingTimestampsForComparison
}) => {

    const [searchTerm, setSearchTerm] = useState("");
    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);


    const filteredOptions = options.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleCheckboxChange = (option) => {
        setFeedingTimestampsForComparison(prevState =>
            prevState.includes(option)
                ? prevState.filter(item => item !== option)
                : [...prevState, option]
        );
    };

    const cleanSelection = () => {
        setFeedingTimestampsForComparison([]);
        setIsOpen(false);
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false); // Close the dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="dropdown-container " ref={dropdownRef}>
            <div className="d-flex align-items-center">
                <div className="dropdown-header" onClick={toggleDropdown}>
                    {feedingTimestampsForComparison.length > 0
                        ? <div className="d-flex  gap-2 me-3">
                            <p className="fw-semibold text-green mb-0">{`Comparing ${feedingTimestampsForComparison.length + 1} feeding sessions`}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="black" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
                            </svg>

                        </div>
                        : <div className="d-flex  gap-2 me-3">
                            <p className="fw-semibold text-color mb-0">Compare Sessions</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <path d="M6.33335 17.5L2.16669 13.3333L6.33335 9.16667L7.52085 10.3333L5.35419 12.5H18V14.1667H5.35419L7.52085 16.3333L6.33335 17.5ZM14.6667 10.8333L13.4792 9.66667L15.6459 7.5H3.00002V5.83333H15.6459L13.4792 3.66667L14.6667 2.5L18.8334 6.66667L14.6667 10.8333Z" fill="#5F2E86" />
                            </svg>
                        </div>
                    }
                </div>
                {feedingTimestampsForComparison.length > 0 &&
                    <svg className="date-selector" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" onClick={cleanSelection}>
                        <path fill="#e95751" d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8" />
                    </svg>
                }
            </div>

            {isOpen && (
                <div className="dropdown-menu">
                    <input
                        type="text"
                        className="dropdown-search"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <ul className="dropdown-list">
                        {filteredOptions.map(option => (
                            <li key={option} className="dropdown-list-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={feedingTimestampsForComparison.includes(option)}
                                        onChange={() => handleCheckboxChange(option)}
                                    />
                                    {FormatDate(option)}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
