import { PostApi, UpdateFavoritePatientsPath } from "../../apis/Api";

async function UpdateFavoritePatient(patient_id, user_id, refreshFavorites) {

  const payload = { patient_id: patient_id, user_id: user_id }

  const response = await PostApi(UpdateFavoritePatientsPath, payload)
    .then(response => response.json())
    .then(output => output.message)
    .then(() => refreshFavorites());

}
export default UpdateFavoritePatient;