export const ModalStates = {
  Add: 'add',
  Edit: 'edit',
  ConfirmAdd: 'confirmAdd',
  ConfirmEdit: 'confirmEdit',
  ResultMessage: 'resultMessage',
}

export function IsAddState(modalState)
{
  switch(modalState) {
    case ModalStates.ConfirmAdd:
    case ModalStates.Add:
      return true;
    case ModalStates.Edit:
    case ModalStates.ConfirmEdit:
    case ModalStates.ResultMessage:
    default:
      return false;
  }
}

export function IsConfirmState(modalState)
{
  switch(modalState) {
    case ModalStates.ConfirmAdd:
    case ModalStates.ConfirmEdit:
      return true;
    case ModalStates.Add:
    case ModalStates.Edit:
    case ModalStates.ResultMessage:
    default:
      return false;
  }
}
