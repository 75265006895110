import './ReviewConflictsModalDataLine.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FiAlertCircle} from "react-icons/fi"

function EditConfirmDataLine(props) {
  return (
    <Row className={props.conflict? 'InvisibleCollapse' : 'EditConfirmRow'}>
    <Col className='col-sm-4'>
      <FiAlertCircle className='DataRowIcon'/>
      <label className='HighlightedDataLabel'>{props.label}</label>
    </Col>
    <Col className='col-sm-4'>
      <input className='CircledDataValue' defaultValue={props.originalPatientEditedParameter}/>
      <label className='HighlightedDataValue'>{props.units}</label>
    </Col>
    <Col className='col-sm-4'>
      <input className='CircledDataValue' defaultValue={props.patientEditedParameter}/>
      <label className='HighlightedDataValue'>{props.units}</label>
    </Col>
  </Row>
  );
}

export default EditConfirmDataLine;
