import "../../../styles/Shared.css";
import '../ReviewConflictsModal/ReviewConflictsModalDataLine.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiAlertCircle } from "react-icons/fi"

import { ModalStates } from './ResolveMissingDataModalStates';
import { Form } from "react-bootstrap";

function ResolveMissingDataModalDataLineAndPicker(props) {

  function getDataRowIconStyle(dataWasMissing, modalState) {
    switch (modalState) {
      case ModalStates.Review:
      case ModalStates.Edit:
        return dataWasMissing === true ? 'DataRowRadioButton' : 'InvisibleCollapse';
      case ModalStates.ConfirmEdit:
      default:
        return 'InvisibleCollapse';
    }
  }

  function getDataLabelStyle(dataWasMissing, modalState) {
    return dataWasMissing === true ? 'HighlightedDataLabel' : 'NormalDataLabel';
  }

  function getDataValueStyle(dataWasMissing, modalState) {
    switch (modalState) {
      case ModalStates.Review:
      case ModalStates.ConfirmEdit:
        return dataWasMissing === true ? 'HighlightedDataValue' : 'NormalDataValue';
      case ModalStates.Edit:
        return dataWasMissing === true ? 'InvisibleCollapse' : 'NormalDataValue';
      default:
        return 'InvisibleCollapse';
    }
  }

  return (
    <Row>
      <Col className="col-sm-1">
        <FiAlertCircle className={getDataRowIconStyle(props.dataWasMissing, props.modalState)} />
      </Col>
      <Col className="col-sm-4">
        <label className={getDataLabelStyle(props.dataWasMissing, props.modalState)}>{props.labelName}</label>
      </Col>
      <Col className="col-sm-3">
        <label className={getDataValueStyle(props.dataWasMissing, props.modalState)}>
          {props.patientParameter} {props.units}
        </label>
        <input
          className={getDataValueStyle(props.dataWasMissing, props.modalState) === 'InvisibleCollapse' ? 'AddEntityModalEntry' : 'InvisibleCollapse'}
          type={props.entryType ? props.entryType : "text"}
          maxLength={props.entryMaxLength}
          defaultValue={props.patientParameter}
          onChange={e => props.textEntryChanged(props.parameterName, e.target.value)}
          min={props.minValue}
          max={props.maxValue} />

      </Col>
      <Col className="col-sm-3">
        {
          props.modalState === ModalStates.Edit
            // ? <label className={'TextEntryValue'/*getDataValueStyle(props.dataWasMissing, props.modalState) === 'InvisibleCollapse' ? 'TextEntryValue' : 'InvisibleCollapse'*/}>
            //   {props.units}
            // </label>
            // : 
            && <Form.Select className='AddEntityModalSelect'
              disabled={!props.dataWasMissing}
              value={props.valueForSelect}
              onChange={e => props.selectChange(e.target.value, props.selectName)}>
              {props.choices.map(option =>
                <option key={option.value} value={option.value}>{option.name}</option>)}
            </Form.Select>
        }



      </Col>
    </Row>
  );
}

export default ResolveMissingDataModalDataLineAndPicker;