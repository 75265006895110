import { PutApi, UpdateAPatient } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";
import CalculateDateOfConception from "../../../utilities/CalculateDateOfConception";

async function ResolveMissingInformation(
  editedPatient,
  setResultMessage)
{
  const fullName = editedPatient.lastName + ", " + editedPatient.firstName;
  const calculateDateOfConception = CalculateDateOfConception(editedPatient.conceptionalAge);

  const payload = 
  {
    patient: {
      patient_id: editedPatient.patientId,
      ehr_patient_ref: editedPatient.ehrReference,
      name: fullName,
      gestational_age: editedPatient.gestationalAge,
      birth_weight: editedPatient.birth_weight,
      weight_uom: editedPatient.weight_uom,  
      date_of_conception: calculateDateOfConception,
      user_id: editedPatient.user_id,
      facility_id: editedPatient.facility_id,
      mobile_serial_number: editedPatient.mobile_serial_number,
      admission_status: editedPatient.admission_status,
      note: editedPatient.note
    }
  }

  PutApi(UpdateAPatient, payload)
    .then(response => response.json())
    .then(output => WriteResultMessage(output, setResultMessage));
 
}
export default ResolveMissingInformation;