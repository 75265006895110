import { useContext, useEffect, useState } from "react";
import GetMeasurements from "../../data_layer/repositories/Measurements/GetMeasurements";
import GetMeasurementsByPagination from "../../data_layer/repositories/Measurements/GetMeasurementsByPagination";

export const useGetMeasurements = (feeding_session_timestamp, patient_id, isLightGraph) => {

    const [isLoading, setIsLoading] = useState(false);
    const [measurements, setMeasurements] = useState([]);

    const getMeasurements = async () => {
        if (feeding_session_timestamp && patient_id){
            setIsLoading(true);
            const response = isLightGraph
                                ? await GetMeasurementsByPagination(feeding_session_timestamp, patient_id, 3600, 0)
                                : await GetMeasurements(feeding_session_timestamp, patient_id);
            setMeasurements(response);
            setIsLoading(false);
        }
    }

    const getData = () => {
        getMeasurements()
    }

    return {
        isLoading,
        measurements,
        getData
    }
}
