import React from 'react'

export const StarRating = ({ title, rating, maxRating = 5 }) => {
  const stars = [];
  const fullStars = Math.floor(rating);
  const decimalPart = rating - fullStars;
  const emptyStars = maxRating - Math.ceil(rating);

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <div key={`full-${i}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
        >
          <path
            d="M3.35419 17.5L5.29169 11.1667L0.166687 7.50001H6.50002L8.50002 0.833344L10.5 7.50001H16.8334L11.7084 11.1667L13.6459 17.5L8.50002 13.5833L3.35419 17.5Z"
            fill="#1E1E1E"
          />
        </svg>
      </div>
    );
  }

  // Add half star if necessary
  if (decimalPart > 0) {
    stars.push(
      <div key="half">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
        >
          <path
            d="M3.35419 17.5L5.29169 11.1667L0.166687 7.50001H6.50002L8.50002 0.833344L10.5 7.50001H16.8334L11.7084 11.1667L13.6459 17.5L8.50002 13.5833L3.35419 17.5Z"
            fill="url(#halfGradient)"
          />
          <defs>
            <linearGradient id="halfGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="50%" stopColor="#1E1E1E" />
              <stop offset="50%" stopColor="transparent" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    );
  }

  // Add empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <div key={`empty-${i}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
        >
          <path
            d="M3.35419 17.5L5.29169 11.1667L0.166687 7.50001H6.50002L8.50002 0.833344L10.5 7.50001H16.8334L11.7084 11.1667L13.6459 17.5L8.50002 13.5833L3.35419 17.5Z"
            stroke="#1E1E1E"
            strokeWidth="1"
            fill="none"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className="col-12 py-1 mt-2">
      <p className="text-sm lh-1 fw-normal mb-0">{title}</p>
      <div className="align-items-end d-flex gap-3 mt-1">
        {stars}
        <div className="lh-sm fw-semibold">{rating}/{maxRating}</div>
      </div>
    </div>
  );
}
