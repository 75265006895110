import "../../../styles/Shared.css";
import "../../../styles/Modals.css";
import '../ReviewConflictsModal/ReviewConflictsModal.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiAlertCircle } from "react-icons/fi"
import { useState, useEffect } from 'react'

import ResolveMissingDataModalDataLine from './ResolveMissingDataModalDataLine';
import { ModalStates } from './ResolveMissingDataModalStates';
import { FirstNameMaxLength, LastNameMaxLength, PatientBirthWeightMaxValue, PatientBirthWeightMinValue, PatientConceptualAgeMaxValue, PatientGestationalAgeMaxValue, PatientGestationalAgeMinValue } from "../../../utilities/Constants";
import ResolveMissingDataEditConfirmDataLine from './ResolveMissingDataEditConfirmDataLine';
import ResolveMissingInformation from "../../../data_layer/repositories/Patients/ResolveMissingInformation";
import UpdateEntityModalDataLineAndPicker from "../UpdateEntityModals/UpdateEntityModalDataLineAndPicker";
import { WeightUnits } from "../../../utilities/WeightUnits";
import ResolveMissingDataModalDataLineAndPicker from "./ResolveMissingDataModalDataLineAndPicker";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";

function ResolveMissingDataModal(props) {

  const defaultResultMessage = "Writing...";
  const defaultEntryMaxLength = 30;

  const [modalState, setModalState] = useState(ModalStates.Review);
  const [editedPatient, setEditedPatient] = useState([]);
  const [resultMessage, setResultMessage] = useState(defaultResultMessage);

  const [isPrimaryButtonEnable, setIsPrimaryButtonEnable] = useState(true);

  const [conceptualAgeError, setConceptualAgeError] = useState(null);
  const [gestationalAgeError, setGestationalAgeError] = useState(null);

  useEffect(() => {
    setEditedPatient(props.patient);
  }, [props.patient]);

  function updateEditedParameter(parameter, newValue) {
    setIsPrimaryButtonEnable(true);
    if (parameter === 'gestationalAge')
      setGestationalAgeError(null)

    if (parameter === 'conceptionalAge')
      setConceptualAgeError(null);

    setEditedPatient(editedPatient => ({ ...editedPatient, [parameter]: newValue }));
  }

  function getHeaderLabel() {
    switch (modalState) {
      case ModalStates.Review:
        return "Review Missing Data";
      case ModalStates.Edit:
      case ModalStates.ConfirmEdit:
        return "Update Missing Data";
      case ModalStates.ResultMessage:
        return "Result"
      default:
        return "";
    }
  }

  function getPrimaryButtonText() {
    switch (modalState) {
      case ModalStates.Review:
        return "Edit";
      case ModalStates.Edit:
        return "Save";
      case ModalStates.ConfirmEdit:
        return "Yes";
      case ModalStates.ResultMessage:
        return "Close";
      default:
        return "";
    }
  }

  function getSecondaryButtonText() {
    switch (modalState) {
      case ModalStates.ConfirmEdit:
        return "Edit";
      default:
        return "";
    }
  }

  function getSecondaryButtonStyle() {
    switch (modalState) {
      case ModalStates.ConfirmEdit:
        return "ModalSecondaryButton neo_qv_btn";
      default:
        return 'Invisible';
    }
  }

  function getDismissButtonStyle() {
    switch (modalState) {
      case ModalStates.Review:
      case ModalStates.Edit:
      case ModalStates.ConfirmEdit:
        return 'ModalDismissButton';
      default:
        return 'Invisible';
    }
  }

  async function exitConfirmEdit() {
    await ResolveMissingInformation(editedPatient, setResultMessage)
      .then(props.updateAlerts);
  }

  function primaryButtonAction() {
    switch (modalState) {
      case ModalStates.Review:
        setIsPrimaryButtonEnable(false);
        setModalState(ModalStates.Edit);
        break;
      case ModalStates.Edit:
        if (fieldsAreValid())
          setModalState(ModalStates.ConfirmEdit);
        break;
      case ModalStates.ConfirmEdit:
        exitConfirmEdit();
        //props.updateAlerts();
        setModalState(ModalStates.ResultMessage);
        break;
      case ModalStates.ResultMessage:
        props.close();
        setResultMessage(defaultResultMessage);
        setModalState(ModalStates.Review);
        break;
      default:
        break;
    }
  }

  function secondaryButtonAction() {
    switch (modalState) {
      case ModalStates.ConfirmEdit:
        setModalState(ModalStates.Edit);
        break;
      default:
        break;
    }
  }

  function dismissButtonAction(closeAction) {
    setIsPrimaryButtonEnable(true)
    setGestationalAgeError(null);
    setConceptualAgeError(null);
    closeAction();
    setModalState(ModalStates.Review);
  }

  function onSelectChanged(value, parameter) {
    setIsPrimaryButtonEnable(true);
    setEditedPatient(editedPatient => ({ ...editedPatient, [parameter]: value }));
  }

  function fieldsAreValid() {
    if (editedPatient.gestationalAge == 0 || !editedPatient.gestationalAge) {
      setGestationalAgeError('This field is required')
      return false
    }

    if (editedPatient.gestationalAge < PatientGestationalAgeMinValue || editedPatient.gestationalAge > PatientGestationalAgeMaxValue) {
      setGestationalAgeError(`Gestational Age must be between  ${PatientGestationalAgeMinValue} and ${PatientGestationalAgeMaxValue} weeks`)
      return false
    }

    if (editedPatient.conceptionalAge === 0 || !editedPatient.conceptionalAge) {
      setConceptualAgeError('This field is required')
      return false
    }

    if (editedPatient.conceptionalAge > PatientConceptualAgeMaxValue) {
      setConceptualAgeError(`Conceptual age must be less than ${PatientConceptualAgeMaxValue} weeks`);
      return false
    }

    if (editedPatient.conceptionalAge < editedPatient.gestationalAge) {
      setConceptualAgeError('Conceptual age must be greater than gestational age');
      return false
    }

    return true
  }


  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal
        show={props.show}
        backdrop="static"
        centered
        size="xl">
        <Modal.Header
          closeButton='true'
          onHide={() => dismissButtonAction(props.close)}>
          <div className='Column'>
            <div className='Row'>
              <Modal.Title>
                <div className='ModalHeader'>
                  <FiAlertCircle className='ModalHeaderIcon' size={20} />
                  <label className='ModalHeaderLabel'>{getHeaderLabel()}</label>
                </div>
              </Modal.Title>
            </div>
            {/* <div>
              <label className='ReviewConflictsSubheader'>{props.reviewAlert.date}</label>
              <label className='ReviewConflictsSubheader'>|</label>
              <label className='ReviewConflictsSubheader'>{props.reviewAlert.issue}</label>
            </div> */}
          </div>
        </Modal.Header>
        <Modal.Body>
          <label className={modalState === ModalStates.ResultMessage ? 'ResultMessage' : 'InvisibleCollapse'}>{resultMessage}</label>
          <Container className={modalState === ModalStates.ResultMessage ? 'InvisibleCollapse' : 'null'}>
            <ResolveMissingDataModalDataLine
              modalState={modalState}
              labelName="Patient ID"
              parameterName='ehrReference'
              patientParameter={editedPatient.ehrReference}
              dataWasMissing={props.dataWasMissing.ehrReference}
              entryMaxLength={defaultEntryMaxLength}
              textEntryChanged={updateEditedParameter}
              units="" />
            <hr className='HorizontalLine'></hr>

            <Row>
              <Col className="col-sm-1"></Col>
              <Col className="col-sm-4">
                <label className='NormalDataLabel'>Date entered</label>
              </Col>
              <Col className='NormalDataValue'>{props.patient.dateCreated}</Col>
            </Row>
            <hr className='HorizontalLine'></hr>


            <Row>
              <Col className="col-sm-1"></Col>
              <Col className="col-sm-4">
                <label className='NormalDataLabel'>User</label>
              </Col>
              <Col className='NormalDataValue'>{props.reviewAlert.user_name}</Col>
            </Row>
            <hr className='HorizontalLine'></hr>

            <ResolveMissingDataModalDataLine
              modalState={modalState}
              labelName="First initial"
              parameterName='firstName'
              patientParameter={editedPatient.firstName}
              dataWasMissing={props.dataWasMissing.firstName}
              entryMaxLength={FirstNameMaxLength}
              textEntryChanged={updateEditedParameter}
              units="" />
            <hr className='HorizontalLine'></hr>
            <ResolveMissingDataModalDataLine
              modalState={modalState}
              labelName="Last name"
              parameterName='lastName'
              patientParameter={editedPatient.lastName}
              dataWasMissing={props.dataWasMissing.lastName}
              entryMaxLength={LastNameMaxLength}
              textEntryChanged={updateEditedParameter}
              units="" />
            <hr className='HorizontalLine'></hr>

            <ResolveMissingDataModalDataLine
              modalState={modalState}
              labelName="Gestational age"
              parameterName='gestationalAge'
              patientParameter={editedPatient.gestationalAge == 0 ? '-' : editedPatient.gestationalAge}
              dataWasMissing={props.dataWasMissing.gestationalAge}
              entryMaxLength={defaultEntryMaxLength}
              textEntryChanged={updateEditedParameter}
              units=" weeks"
              entryType="number"
              minValue={PatientGestationalAgeMinValue}
              maxValue={PatientGestationalAgeMaxValue}
              required
              showError={gestationalAgeError} />
            <hr className='HorizontalLine'></hr>

            <ResolveMissingDataModalDataLine
              modalState={modalState}
              labelName="Conceptual age"
              parameterName='conceptionalAge'
              patientParameter={editedPatient.conceptionalAge == 0 ? '-' : editedPatient.conceptionalAge}
              dataWasMissing={props.dataWasMissing.dateOfConception}
              entryMaxLength={defaultEntryMaxLength}
              textEntryChanged={updateEditedParameter}
              units=" weeks"
              entryType="number"
              minValue={editedPatient.gestationalAge}
              required
              showError={conceptualAgeError} />
            <hr className='HorizontalLine'></hr>

            <ResolveMissingDataModalDataLineAndPicker
              modalState={modalState}
              labelName="Birth weight"
              parameterName='birth_weight'
              patientParameter={editedPatient.birth_weight == 0 ? '-' : editedPatient.birth_weight}
              dataWasMissing={props.dataWasMissing.birth_weight}
              entryMaxLength={defaultEntryMaxLength}
              textEntryChanged={updateEditedParameter}
              units={" " + editedPatient.weight_uom}
              entryType="number"
              minValue={0}
              choices={WeightUnits}
              selectChange={onSelectChanged}
              selectName={'weight_uom'}
              valueForSelect={editedPatient.weight_uom} />

          </Container>
          <div className={modalState === ModalStates.ConfirmEdit ? 'null' : 'InvisibleCollapse'}>
            <hr className='ThickLine'></hr>
            <label className="ConfirmHeader">Are you sure you want to save your changes?</label>
            <ResolveMissingDataEditConfirmDataLine
              label={"First name"}
              patientEditedParameter={editedPatient.firstName}
              dataWasMissing={props.dataWasMissing.firstName}
              units="-" />
            <ResolveMissingDataEditConfirmDataLine
              label={"Last name"}
              patientEditedParameter={editedPatient.lastName}
              dataWasMissing={props.dataWasMissing.lastName}
              units="-" />
            <ResolveMissingDataEditConfirmDataLine
              label={"ID number (EHR)"}
              patientEditedParameter={editedPatient.ehrReference}
              dataWasMissing={props.dataWasMissing.ehrReference}
              units="" />
            <ResolveMissingDataEditConfirmDataLine
              label={"Gestational age"}
              patientEditedParameter={editedPatient.gestationalAge}
              dataWasMissing={props.dataWasMissing.gestationalAge}
              units=" weeks" />
            <ResolveMissingDataEditConfirmDataLine
              label={"Conceptional age"}
              patientEditedParameter={editedPatient.conceptionalAge}
              dataWasMissing={props.dataWasMissing.dateOfConception}
              units=" weeks" />
            <ResolveMissingDataEditConfirmDataLine
              label={"Birth weight"}
              patientEditedParameter={editedPatient.birth_weight}
              dataWasMissing={props.dataWasMissing.birth_weight}
              units={editedPatient.weight_uom} />
          </div>
        </Modal.Body>

        <Modal.Footer className='ModalFooter'>
          <center>

            <Button onClick={() => secondaryButtonAction()} className={getSecondaryButtonStyle()}>{getSecondaryButtonText()}</Button>

            <Button onClick={() => primaryButtonAction()}
              className="ModalPrimaryButton neo_btn_primary"
              disabled={!isPrimaryButtonEnable}>
              {getPrimaryButtonText()}
            </Button>
            {/* <div className='Spacer'></div> */}
          </center>
          {/* <Button onClick={() => dismissButtonAction(props.close)} className={getDismissButtonStyle()}>Dismiss</Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ResolveMissingDataModal;