import { Button, Spinner } from 'react-bootstrap'

export const LoadingButton = ({loading, onClick, title}) => {


    return (
        <Button className={`neo_qv_btn w-250`}  disabled={loading} onClick={onClick}>

            <div className="neo_header_center">
                {loading &&
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <div className='xsmall-spacer'></div>
                        <span className="sr-only">Loading...</span>
                    </>
                }

                {!loading &&
                    <span className="sr-only">{title}</span>
                }
            </div>

        </Button>
    )
}
