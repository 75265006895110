import { Route, Routes } from "react-router-dom";
import UsersPage from "../pages/UsersPage/UsersPage";
import Footer from "../components/Footer/Footer";
import { Navbar } from "../components/Navbar/Navbar";
import { FacilitiesPage } from "../pages/Facilities/FacilitiesPage";
import { PatientsAdminPage } from "../pages/Patients/PatientsAdminPage"
import { HandheldsPage } from "../pages/Handhelds/HandheldsPage";
import { PatientDetails } from "../pages/Patients/PatientDetails";


export const NeoneurAdminRoutes = () => {
    return (
        <div className="neo_container">
            <Navbar />

            <main className="PageContainer">

                <Routes>
                    <Route path="/facilities" element={<FacilitiesPage />}/>
                    <Route path="/facilities/:name/:facility_id" element={<PatientsAdminPage />}/>
                    <Route path="/facilities/:name/:facility_id/:patient_id" element={<PatientDetails />}/>
                    <Route path="/handhelds" element={<HandheldsPage />}/>
                    <Route path="/users" element={<UsersPage />}/>
                    <Route path="/*" element={<UsersPage />}/>
                </Routes>
            </main>
            <Footer />
        
        </div>
    );
}