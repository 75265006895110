import { Route, Routes } from "react-router-dom"
import { Navbar } from "../components/Navbar/Navbar"
import { ClinicalAdmin } from '../pages/ClinicalAdmin/ClinicalAdmin'
import NotificationsPage from "../pages/NotificationsPage/NotificationsPage"
import { PatientsAdminPage } from "../pages/Patients/PatientsAdminPage"

export const ClinicalAdminRoutes = () => {
  return (
    <div className="neo_container">
      <Navbar />
      <main>
        <Routes>

          <Route path="/notifications" element={<NotificationsPage />}/>
          <Route path="/patients" element={<PatientsAdminPage />}/>
          <Route path="/*" element={<NotificationsPage />}/>
        </Routes>

      </main>
    </div>
  )
}