import { useEffect, useState } from "react";
import GetFacilities from "../../data_layer/repositories/Facilities/GetFacilities";

export const useGetFacilities = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        getFacilities();
    }, [])
    
    const getFacilities = async () => {
        setIsLoading(true);
        const response = await GetFacilities();
        setFacilities(response);
        setIsLoading(false);
    }

    const reloadData = () => {
        getFacilities();
    }

    return {
        isLoading,
        facilities,
        reloadData,
    }
}
