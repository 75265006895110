import { PatientsPerPage } from "../../../utilities/Constants";
import { GetAllPatientsByFacilityPath, GetApi, GetPatientListPath } from "../../apis/Api";

async function GetPatientList(facility_id, page = 0, order = 'id') {

  const offset = page * PatientsPerPage;

  const patients = await GetApi(GetPatientListPath(facility_id))
    .then(response => response.json())
    .then(data => transformPatients(data.result)); // TODO: Add error checking for multiple responses.
 
  return patients;
};

function transformPatients(patients){
  const list = patients?.map((patient) => transformEachPatient(patient));
  return list.filter((patient) => patient.feedings.length > 0);
}

function transformEachPatient(patient){

  const nameArray = patient.name.replace(" ", "").split(",");
  const initials = `${nameArray[0][0]}${nameArray[1][0]}`

  return {
    ...patient,
    initials: initials,
    gestational_age: Number(patient.gestational_age).toFixed(1),
    birth_weight : Number(patient.birth_weight).toFixed(1),
  }
}

export default GetPatientList;