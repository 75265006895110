

export const BiomarkersSection = ({ biomarkers }) => {

    return (


        <div className="d-flex flex-column px-3 biomarks-details-width">
            {biomarkers?.length > 0 &&
                <>
                    <p className="text-muted m-0 text-sm">Biomarkers (* in bursts, ** resting)</p>
                    <div className="mt-2 d-flex align-items-center">
                        <div className="border-end w-50 pe-3">
                            <p className="m-0 text-sm">{ biomarkers.find((b) => b.name === 'MATURATION')?.value }  Maturation</p>
                            <p className="m-0 text-sm">{ biomarkers.find((b) => b.name === 'PRESSURE-MAX')?.value }  Pres. Max</p>
                            <p className="m-0 text-sm">{ biomarkers.find((b) => b.name === 'SUCKS-PER-BURST')?.value } Ave # Sucks*</p>
                        </div>
                        <div className="w-50 px-3">
                            <p className="m-0 text-sm">{ biomarkers.find((b) => b.name === 'SUCKS-PER-SWALLOW')?.value }  Sucks/sec*</p>
                            <p className="m-0 text-sm">{ biomarkers.find((b) => b.name === 'BREATHS-IN-BURSTS')?.value }  Breath/sec*</p>
                            <p className="m-0 text-sm">{ biomarkers.find((b) => b.name === 'BREATHS-IN-RESTS')?.value }  Breath/sec**</p>
                        </div>
                    </div>
                </>

            }
                  
        </div>
    )
}
