import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY;

export default class LocalStorage {

    static setItem(key, value){
        const stringValue = value.toString();
        const encryptedValue = CryptoJS.AES.encrypt(stringValue, secretKey).toString();
        sessionStorage.setItem(key, encryptedValue);
    }
    
    static getItem(key){
        const encryptedValue = sessionStorage.getItem(key);
        if (!encryptedValue){
            return null
        }
        const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
        const originalValue = bytes.toString(CryptoJS.enc.Utf8);

        if (isNumericString(originalValue))
            return Number(originalValue);
        else
            return originalValue;
    }
    
    static removeItem(key){
        window.sessionStorage.removeItem(key);
    }
    
    static clear(){
        window.sessionStorage.clear();
    } 

    
}

function isNumericString(str){
    return /^-?\d+(\.\d+)?$/.test(str);
}