import { useState } from "react"
import { FormatDateForCard } from "../../../utilities/FormatDate"
import { PatientCardMaxSessions } from "../../../utilities/Constants";


export const SessionSelectorSection = ({ feedings, currentTimestamp, onDateClick }) => {

    const [index, setIndex] = useState(0);


    function handleLeftClick(){
        if (index - PatientCardMaxSessions >= 0){
            setIndex(index - PatientCardMaxSessions );
        }
    }


    function handleRightClick(){
        if (index + PatientCardMaxSessions < feedings.length){
            setIndex(index + PatientCardMaxSessions);
        }
    }

    return (
        <div className="border-top d-flex sub-header-card-padding">
            <div className="px-4 w-100 d-flex align-items-center">
                <div className="align-items-center d-flex gap-1">
                    <div className="align-items-center d-flex pe-2 border-end">
                        <div className="px-2 py-1 rounded-4 bg-olive">
                            <p className="text-white text-sm mb-0">{feedings.length} Sessions</p>
                        </div>
                    </div>

                    { index - PatientCardMaxSessions >= 0   
                        ?<i className='bx bx-chevron-left text-3xl card-icon' onClick={handleLeftClick}></i>
                        : <i className="blank-icon"></i>
                    }

                    {feedings.slice(index, index + PatientCardMaxSessions).map(feeding =>
                        <div className={`px-2 date-selector py-1 rounded-4 ${currentTimestamp === feeding?.timestamp ? 'bg-color' : 'bg-gray'}`}
                            key={feeding?.timestamp}
                            onClick={() => onDateClick(feeding?.timestamp)}>
                            <p className={`text-sm mb-0 ${currentTimestamp === feeding?.timestamp ? 'text-selected' : 'text-secondary'}`}>
                                {FormatDateForCard(feeding?.timestamp)}
                            </p>

                        </div>
                    )}

                    { feedings.length > index + PatientCardMaxSessions && 
                        <i className='bx bx-chevron-right text-3xl card-icon' onClick={handleRightClick}></i>
                    }

                </div>
            </div>
        </div>
    )
}
