import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './styles/Neo.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";

import { AuthProvider } from "./auth";
import { AppRouter } from "./router/AppRouter";

function App() {

  const [token, setToken] = useState();

  // if (!token)
  // {
  //   return <SelectFacilityPage />
  //   // return <LoginPage
  //   //   setToken={setToken} />
  // }

  return (
    <BrowserRouter>

      <AuthProvider>

        <AppRouter setToken={setToken}/>
      </AuthProvider>
      {/* <Routes>
        <Route></Route>
        <Route path="/" element={<Layout setToken={setToken}/>}>
          <Route index element={<UsersPage/>}/>
          <Route path="notifications" element={<NotificationsPage/>}/>
        </Route>
      </Routes> */}
    </BrowserRouter>
  )
}

export default App;
