import React, { useEffect } from 'react'
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';

import FormatDate from '../../utilities/FormatDate';
import { useGetBiomarkersListByPatientId } from '../CustomHooks/useGetBiomarkersListByPatientId';
import ConvertBiomarkersToString from '../../utilities/ConvertBiomarkersToString';
import { LoadingButton } from '../Loading/LoadingButton';

export const ExportBiomarkersCSV = (props) => {

    const { biomarkers, isLoading, getBiomarkers } = useGetBiomarkersListByPatientId(props.patient_id);

    const getData = (event) => {
        event.preventDefault();

        getBiomarkers();
    }

    useEffect(() => {
        if (biomarkers){
            downloadCSV();
        }
    }, [biomarkers]);

    const downloadCSV = () => {

        if (biomarkers.patient_name) {
            
            const csvString = ConvertBiomarkersToString(biomarkers);

            // Create a Blob from the CSV string
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

            let fileName = `${biomarkers.patient_ehr}_${biomarkers.patient_name.replace(", ","-")}_`;
            fileName += `${FormatDate(Date.now()).replace("/","-").replace(":", "-")}.csv`

            saveAs(blob, fileName);
        }
    };


    return (

        <LoadingButton 
            title="Download Biomarkers"
            onClick={ getData }
            loading={ isLoading }
        />
        // <>

        //     {isLoading &&
        //         <div className="p-1 spinner-border" role="status">
        //             <span className="visually-hidden">Loading...</span>
        //         </div>
        //     }
        //     {!isLoading && <Link className="neo_qv_btn"
        //         onClick={getData} >
        //         Download Biomarkers
        //     </Link>
        //     }
        // </>
    )
}
