import {useEffect, useState} from "react";
import {GetFeedingSession} from "../../data_layer/repositories/FeedingSessions/GetFeedingSession";

export const useGetFeedingDetails = (feeding_timestamp) => {

    const [isLoading, setIsLoading] = useState(true);
    const [feeding, setFeeding] = useState();

    useEffect(() => {
        if (feeding_timestamp)
            getFeedingSession();
    }, [feeding_timestamp]);
    
    const getFeedingSession = async () => {
        setIsLoading(true);
        const response = await GetFeedingSession(feeding_timestamp);
        setFeeding(response);
        setIsLoading(false);
    }

    return {
        isLoading,
        feeding: feeding,
    }
}
