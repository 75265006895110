import { useEffect, useState } from "react";
import GetUsers from "../../data_layer/repositories/Users/GetUsers";
import GetUsersByFacility from "../../data_layer/repositories/Users/GetUsersByFacility";
import GetAlertsByFacility from "../../data_layer/repositories/Alerts/GetAlertsByFacility";

export const useGetAlerts = (facility_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        getAlerts();
    }, [facility_id])
    
    const getAlerts = async () => {
        setIsLoading(true);
        const response = facility_id
            ? await GetAlertsByFacility(facility_id)
            : []//await GetUsers();
        setAlerts(response);
        setIsLoading(false);
    }

    const reloadData = () => {
        getAlerts();
    }

    return {
        isLoading,
        alerts,
        reloadData,
    }
}
