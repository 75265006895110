import { FormatMinAndSec } from "../../../utilities/FormatDate";
import { GetApi, GetPatientByPatientIdPath } from "../../apis/Api";

async function GetPatientByPatientId(patient_id) {

  const patient = await GetApi(GetPatientByPatientIdPath + "/" + patient_id)
    .then(response => response.json())
    .then(data => { 
      return data.result[0] && convertData(data.result[0])
    });

  return patient;
};

function convertData(item){
  return {
    ...item,
    feedings : item.feedings && item.feedings?.map((session) =>{
      return {
        ...session,
        has_biomarkers: session.has_biomarkers || false,
        max_time_tick: session.max_time_tick || 0,
        duration: FormatMinAndSec(session.max_time_tick)
      }
    }) 
  };
}

export default GetPatientByPatientId;