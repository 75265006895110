import  '../../../styles/Modals.css'
import  '../../../styles/Shared.css'
import './UpdateEntityModals.css'
import Row from 'react-bootstrap/Row';
import { v4 as uuidv4 } from 'uuid';

import {FiRefreshCcw, FiCopy} from "react-icons/fi";

import { ModalStates } from './UpdateEntityModalStates';
import { IsErrorState, GetInputValidationErrorText } from './InputValidation';
import { useEffect, useState } from 'react';

function UpdateEntityModalDataLineUUIDGenerator(props) {

  const [value, setValue] = useState("")

  useEffect(() => {
    setValue(props.value);
  }, [props]);
  



  function getTextEntryDisabled()
  {
    if (props.disabled)
      return true;


    switch (props.modalState)
    {
      case ModalStates.Add:
      case ModalStates.Edit:
        return false;
      case ModalStates.ConfirmAdd:
      case ModalStates.ConfirmEdit:
      default:
        return true;
    }
  }

  function onInputChange(value, parameterName){
    const env = process.env.REACT_APP_ENV;
    const code = env[0].toLowerCase() + value.slice(1 - value.length)
    setValue(code);
    props.inputChanged(code, parameterName);
  }



  return (
    <div>
      <Row className='AddEntityModalContainer form-group'>
        <div className='col-sm-4 col-form-label'>
          <label className="AddEntityModalLabel" htmlFor={`${props.labelName}-input`}>{props.labelName}
          <label className={props.required === true ? 'RequiredStar' : 'InvisibleCollapse'}>*</label>
          </label>
          <p className={ !props.helpText? 'InvisibleCollapse' : ''}><small>{ props.helpText }</small></p>
        </div>
        <div className='col-sm-6'>
          <input className={IsErrorState(props.errorState) ? 'AddEntityModalEntryError ' : 'AddEntityModalEntryUUID'}
              id={`${props.labelName}-input`}
              disabled={ getTextEntryDisabled() }
              type={props.type ? props.type : 'text'}
              maxLength={ props.entryMaxLength }
              minLength={ props.entryMinLength }
              min={ props.min }
              max={ props.max }
              value={ props.value ?? "" }
              step={props.step}
              onChange={e => onInputChange(e.target.value, props.parameterName)}/>
          <label className={IsErrorState(props.errorState) ? 'InputValidationErrorMessage' : 'InvisibleCollapse'}>
            { GetInputValidationErrorText(props.errorState, props.labelName, props.min, props.max, props.entryMinLength, props.entryMaxLength) }
          </label>

        </div>
        <div className='col-sm-1  col-form-label'>
          <a className="neo-icon icon-link icon-link-hover"
            onClick={() => onInputChange(uuidv4(), props.parameterName)}
            href='#'>
            <FiRefreshCcw size={18} />
          </a>
        </div>
        
        <div className='col-sm-1  col-form-label'>
          <a className="neo-icon icon-link icon-link-hover"
            onClick={() => {navigator.clipboard.writeText(value)}}
            href='#'>
            <FiCopy size={18} />
          </a>
        </div>
        
      </Row>
    </div>
  );
}

export default UpdateEntityModalDataLineUUIDGenerator;