import { useEffect, useState } from 'react'
import GetFlags from '../../data_layer/repositories/Flags/GetFlags';

export const useGetFlags = (feeding_session_timestamp) => {


    const [isLoading, setIsLoading] = useState(true);
    const [flags, setFlags] = useState([]);


    useEffect(() => {
        if (feeding_session_timestamp)
            getFlags();
    }, [feeding_session_timestamp])
    
    const getFlags = async () => {
        setIsLoading(true);
        const response = await GetFlags(feeding_session_timestamp);
        setFlags(response);
        setIsLoading(false);
    }

    return {
        flags,
        isLoading,
    }
}
