import '../../../styles/Modals.css'
import '../../../styles/Shared.css'
import './UpdateEntityModals.css'
import Row from 'react-bootstrap/Row';

import { ModalStates } from './UpdateEntityModalStates';
import { IsErrorState, GetInputValidationErrorText } from './InputValidation';

function UpdateEntityModalDataLine(props) {



  function getTextEntryDisabled() {
    if (props.disabled)
      return true;


    switch (props.modalState) {
      case ModalStates.Add:
      case ModalStates.Edit:
        return false;
      case ModalStates.ConfirmAdd:
      case ModalStates.ConfirmEdit:
      default:
        return true;
    }
  }

  function onInputChange(value, parameterName) {
    props.inputChanged(value, parameterName);
  }

  return (
    <div>
      <Row className='AddEntityModalContainer form-group'>
        <div className='col-sm-4 col-form-label'>
          <label className="AddEntityModalLabel" htmlFor={`${props.labelName}-input`}>{props.labelName}
            <label className={props.required === true ? 'RequiredStar' : 'InvisibleCollapse'}>*</label>
          </label>
          <p className={!props.helpText ? 'InvisibleCollapse' : ''}><small>{props.helpText}</small></p>
        </div>
        <div className='col-sm-8'>

          <input className={IsErrorState(props.errorState) ? 'AddEntityModalEntryError ' : 'AddEntityModalEntry'}
            id={`${props.labelName}-input`}
            disabled={getTextEntryDisabled()}
            type={props.type ? props.type : 'text'}
            maxLength={props.entryMaxLength}
            minLength={props.entryMinLength}
            min={props.min}
            max={props.max}
            value={props.value ?? ""}
            step={props.step}
            onChange={e => onInputChange(e.target.value, props.parameterName)}
            placeholder={props.placeholder ?? ""} />

          <label className={IsErrorState(props.errorState) ? 'InputValidationErrorMessage' : 'InvisibleCollapse'}>
            {GetInputValidationErrorText(props.errorState, props.labelName, props.min, props.max, props.entryMinLength, props.entryMaxLength)}
          </label>

        </div>

      </Row>
    </div>
  );
}

export default UpdateEntityModalDataLine;