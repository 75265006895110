import  '../../../styles/Modals.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ModalStates } from './UpdateEntityModalStates';

function UpdateEntityModalFooter(props) {

  async function primaryButtonAction()
  {
    switch(props.modalState) {
      case ModalStates.Add:
        var inputsValid = props.validateInputs();
        if (inputsValid)
        {
          props.setState(ModalStates.ConfirmAdd);
        }
        break;
      case ModalStates.Edit:
        inputsValid = props.validateInputs();
        if (inputsValid)
        {
          props.setState(ModalStates.ConfirmEdit);
        }
        break;
      case ModalStates.ConfirmAdd:
        await props.addEntity();
        await props.setState(ModalStates.ResultMessage);
        break;
      case ModalStates.ConfirmEdit:
        await props.updateEntity();
        await props.setState(ModalStates.ResultMessage);
        break;
      case ModalStates.ResultMessage:
        props.setState(ModalStates.Edit);
        props.close();
        break;
      default:
        break;
    }
  }

  function secondaryButtonAction()
  {
    switch(props.modalState) {
      case ModalStates.ConfirmEdit:
        props.setState(ModalStates.Edit);
        break;
      case ModalStates.ConfirmAdd:
        props.setState(ModalStates.Add);
        break;  
      case ModalStates.Add:
      case ModalStates.Edit:
      case ModalStates.ResultMessage:
      default:
        break;
    }
  }

  function getPrimaryButtonText()
  {
    switch(props.modalState) {
      case ModalStates.Add:
      case ModalStates.Edit:
        return "Save";
      case ModalStates.ConfirmAdd:
      case ModalStates.ConfirmEdit:
        return "Yes";
      case ModalStates.ResultMessage:
        return "Close";
      default:
        return "";
    }
  }

  function getSecondaryButtonText()
  {
    switch(props.modalState) {
      case ModalStates.ConfirmAdd:
      case ModalStates.ConfirmEdit:
        return "Cancel";
      case ModalStates.Add:
      case ModalStates.Edit:
      case ModalStates.ResultMessage:
      default:
        return "";
    }
  }

  function getSecondaryButtonStyle()
  {
    switch(props.modalState) {
      case ModalStates.Add:
      case ModalStates.Edit:
      case ModalStates.ResultMessage:
        return 'Invisible'; 
      default:
        return 'ModalSecondaryButton neo_qv_btn';
    }
  }

  return (

    <Modal.Footer className='UpdateModalFooter'>
      <center>
      
        <Button onClick={() => secondaryButtonAction()} className={getSecondaryButtonStyle()}>{getSecondaryButtonText()}</Button>
        <Button onClick={() => primaryButtonAction()} className="ModalPrimaryButton neo_btn_primary" disabled={!props.active}>{getPrimaryButtonText()}</Button>
      </center>

    </Modal.Footer>
  );
}

export default UpdateEntityModalFooter;