import { useEffect, useState } from 'react'

export const PaginationPage = ({ currentPage, totalItems, itemsPerPage, onPageNumberClick }) => {

  const [pages, setPages] = useState([])

  const setItemsForPagination = () => {
    const items = []
    for (let item = 1; item <= Math.ceil(totalItems / itemsPerPage); item++) {
      items.push(
        <li className={item === currentPage ? "page-item active" : "page-item"} key={item} >
          <a className="page-link" href="#" onClick={() => onPageNumberClick(item)}>
            {item}
          </a>
        </li>);
    }
    setPages(items);
  }

  useEffect(() => {
    setItemsForPagination();
  }, [currentPage, totalItems])



  return (
    <div className="d-flex flex-column justify-content-center align-items-center neo_ut_mt_24">
      <ul className="pagination">
        {pages}
      </ul>
    </div>
  )
}
