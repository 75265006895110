import { useContext } from "react"
import { AuthContext } from "../auth"
import { useLocation, Navigate } from "react-router-dom";
import { MillisOnMinute } from "../utilities/Constants";
import LocalStorage from "../utilities/Storage";


export const PrivateRoute = ({ children }) => {

    const { logged, facility, logout } = useContext( AuthContext );
    const { pathname, search } = useLocation();

    // const lastPath = pathname + search;
    // LocalStorage.setItem('lastPath', lastPath);

    // const lastInteractionTime = LocalStorage.getItem('lastInteractionTime');
    // console.log(`lastInteractionTime: ${lastInteractionTime}, Interval: ${Date.now() - lastInteractionTime}, timeout: ${facility.timeout_session * MillisOnMinute} `)
    // console.table(facility);

    // const timeout = (Date.now() - lastInteractionTime) > facility.timeout_session * MillisOnMinute;

    // console.log(`Is timeout? ${timeout}`)

    // if (timeout){
    //     logout();
    //     return <Navigate to="/" />
    // }


    return (logged)
        ? children
        : <Navigate to="/" />


}