import { useContext, useEffect, useRef, useState } from 'react'
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { PatientContext } from '../../../data_layer/context/Patient/PatientContext';




export const PatientDropdown = ({ options = [] }) => {

    const {patients, addPatient, removePatient} = useContext(PatientContext);

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        if (selectedValue) {
            removePatient(options.find((option) => option.ehr_patient_ref === selectedValue).patient_id);
            setSelectedValue(null); // Clear selection
        } else {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionClick = (value) => {
        setSelectedValue(value);
        addPatient(options.find((option) => option.ehr_patient_ref === value));
        setIsOpen(false); // Close dropdown
    };


    // Close dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const filteredOptions = options.filter((option) =>
        option.ehr_patient_ref.toLowerCase().includes(searchTerm.toLowerCase())
      );
    



    return (
        <div className="position-relative" ref={dropdownRef}>
            {/* Select box */}
            <div
                className="d-flex justify-content-between align-items-center p-2 rounded-4 border border-2 border-dark"
                style={{ width: "180px", cursor: "pointer" }}
                onClick={toggleDropdown}
            >
                <span>{selectedValue ? `ID: ${selectedValue}` : "Compare patient"}</span>
                <span>
                    {selectedValue ? (
                        <span style={{ cursor: "pointer" }}><MdClose /></span>
                    ) : (
                        <span><IoIosArrowDown /></span>
                    )}
                </span>
            </div>

            {/* Dropdown menu */}
            {isOpen && (
                <div
                    className="position-absolute mt-2 p-3 border rounded shadow bg-white"
                    style={{ width: "180px", zIndex: 1000 }}
                >
                    {/* Search bar */}
                    <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Search patient"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    {/* Options list */}
                    <ul className="list-unstyled mb-0">
                        {
                            filteredOptions.filter((opt) => !patients.some(e => e.id === opt.patient_id)).map((option, index) => (
                                <li
                                    key={index}
                                    className="p-2 hover-effect"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleOptionClick(option.ehr_patient_ref)}
                                >
                                    {`ID: ${option.ehr_patient_ref}`}

                                </li>
                            ))
                        }

                        {options.length === 0 && (
                            <li className="text-muted">No options found</li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}
