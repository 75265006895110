import { PutApi, UpdateAMobileDevice } from "../../apis/Api";
import WriteResultMessage from "../../apis/WriteResultMessage";

async function UpdateMobileDevice(
  editedEntity,
  setResultMessage,
  refreshAction)
{
  const payload = 
  {
    mobile_serial_number: editedEntity.mobile_serial_number,
    mobile_device_name: editedEntity.mobile_device_name,
    mobile_manufacture_model: editedEntity.mobile_manufacture_model,
    client_facility_asset_tag: editedEntity.client_facility_asset_tag,
    mobile_sw_version: editedEntity.mobile_sw_version,
    mobile_log_flag: editedEntity.mobile_log_flag,
    facility_id: editedEntity.facility_id,
    status: editedEntity.status
  }

  await PutApi(UpdateAMobileDevice, payload)
  .then(response => response.json())
  .then(output => WriteResultMessage(output, setResultMessage))
  .then(refreshAction);
}
export default UpdateMobileDevice;