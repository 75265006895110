export const WeightUnits = [
    {
        "name": "Kg",
        "value": "kg"
    },
    {
        "name": "g",
        "value": "g"
    },
    {
        "name": "lb",
        "value": "lb"
    },
    {
        "name": "oz",
        "value": "oz"
    },
];