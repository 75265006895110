import { useContext, useEffect, useState } from "react";
import GetPatientByPatientId from "../../data_layer/repositories/Patients/GetPatientByPatientId";
import GetFavoritePatients from "../../data_layer/repositories/Patients/GetFavoritePatients";

export const useGetFavoritePatients = (user_id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [patients, setPatients] = useState([]);

    useEffect(() => {
        if (user_id)
            getPatients();
    }, [user_id]);
    
    const getPatients = async () => {
        setIsLoading(true);
        const response = await GetFavoritePatients(user_id);
        setPatients(response);
        setIsLoading(false);
    }

    const refreshFavorites = () => {
        getPatients();
    }

    return {
        isLoading,
        patients,
        refreshFavorites
    }
}
