import FormatDate from "./FormatDate";


function ConvertBiomarkersToString(biomarkers){
    if (biomarkers){
        // Convert the data array into a CSV string
        let csvString = `Patient Id, ${biomarkers.patient_ehr}\n`;
        csvString += `Patient Name, ${biomarkers.patient_name}\n`;
        csvString += `Internal ID, ${biomarkers.patient_id}\n\n`;

        // Header with all params
        csvString += "date,sucks,swallows,breaths,feedingDuration,sRate,bursts,rests,BSR,bPerMin,avgrDuration,avgbDuration,pkPerBurst,pMax,stdPMax,adjPMax,stdAdjPMax,AISWid,stdISWid,ASWid,stdSWid,breathinB,breathinR,BSwR,swpB,pMin,stdPMin,SwRate,ampRatio,sPerSw,maturation\n" // Specify your headers here

        // Biomarkers values
        biomarkers.feedings.forEach(session => {
            csvString += FormatDate(session.timestamp);
            session.biomarkers.forEach(element => {
                csvString += `,${element.value}`;
            });
            csvString += "\n"
        });

        return csvString;
    }
    else {
        return "";
    }
};

export default ConvertBiomarkersToString;