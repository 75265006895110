import { GetApi, GetAllPatients, GetAllPatientsByFacilityPath } from "../../apis/Api";
import CalculateConceptionalAge from "../../../utilities/CalculateConceptionalAge";
import FormatDate, { FormatSimpleDate } from "../../../utilities/FormatDate";
import SplitName from "../../../utilities/SplitName";

async function GetAllPatientsByFacility(facility_id) {

  var patients = await GetApi(GetAllPatientsByFacilityPath + facility_id)
    .then(response => response.json())
    .then(data => convert(data.result)); // TODO: Add error checking for multiple responses.
 
  return patients;
};

function convert(patients){
  return patients.map((patient) => convertEach(patient));
}

function convertEach(item) {
  const names = SplitName(item.name);
  const conceptual_age = CalculateConceptionalAge(item.date_of_conception);

  return {
    ...item,
    first_name: names.firstName,
    last_name: names.lastName ? names.lastName : '',
    conceptual_age: Number(conceptual_age).toFixed(1),
    gestational_age: Number(item.gestational_age).toFixed(1),
    birth_weight: Number(item.birth_weight).toFixed(1),
    date_created: FormatSimpleDate(item.created_at),
    status: item.patient_status,
  };
}

export default GetAllPatientsByFacility;