import  '../../../styles/Modals.css'
import './UpdateEntityModals.css'
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { ModalStates } from './UpdateEntityModalStates';

function UpdateEntityModalDataSwitch(props) {

  function getDisabled()
  {
    switch (props.modalState)
    {
      case ModalStates.Add:
      case ModalStates.Edit:
        return false;
      case ModalStates.ConfirmAdd:
      case ModalStates.ConfirmEdit:
      default:
        return true;
    }
  }

  function handleOnCheck(event, parameterName){
    props.inputChanged(event.target.checked ? 'active': 'inactive', parameterName)
  }

  // function getValue(i)
  // {
  //   if (IsNullOrEmpty(props.choiceSelectionParameter))
  //   {
  //     return i;
  //   }
  //   return i[props.choiceSelectionParameter];
  // }

  // function getDisplay(i)
  // {
  //   if (IsNullOrEmpty(props.choiceDisplayParameter))
  //   {
  //     return i;
  //   }
  //   return i[props.choiceDisplayParameter];
  // }

  return (
    <div>
      <Row className='AddEntityModalContainer'>
        <label className="AddEntityModalLabel col-form-label col-sm-4">{props.labelName}</label>
        <div className="col-sm-8">
          <Form.Switch className='AddEntityModalSwitch'
              disabled={getDisabled()}
              checked={props.value == 'active' ? true : false }
              onChange={e => handleOnCheck(e, props.parameterName)} >
          </Form.Switch>

        </div>
      </Row>
    </div>
    
  );
}

export default UpdateEntityModalDataSwitch;