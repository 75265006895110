import {PaginatedTable} from '../PaginatedTable/PaginatedTable';
import {FiUser, FiEdit2} from "react-icons/fi";
import {MdOutlineLockReset} from "react-icons/md";
import FormatDate from '../../utilities/FormatDate';
import { GetRoleName, Roles } from '../../data_layer/repositories/Users/UserRoles';
import { useContext } from 'react';
import { AuthContext } from '../../auth';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatPhoneNumber } from '../../utilities/FormatPhoneNumber';


function UsersTable(props)
{

  const { user } = useContext( AuthContext );

  const usersColumns = [
    {
      name: 'Full Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      grow: 2
    },
    {
      name: 'Phone number',
      selector: row => row.phone,
      sortable: true,
      grow: 2
    },
    {
      name: 'Username',
      selector: row => row.login_id.toLowerCase(),
      sortable: true,
    },
    {
      name: 'Role',
      selector: row => GetRoleName( row.role ),
      sortable: true,
    },
    {
      name: 'Date created',
      selector: row => FormatDate(row.created_at),
      sortable: true,
      width: '200px',
    },
    {
      name: 'Date updated',
      selector: row => FormatDate(row.updated_at),
      sortable: true,
      width: '200px',
    },
    {
      name: 'Last Login',
      selector: row => FormatDate(row.last_login),
      sortable: true,
      width: '200px',
    },
    {
      name: 'Active',
      selector: row => row.status === 'active' ? 'Yes' : 'No',
      sortable: true,
      width: '100px'
    },
    {
      name: '',
      selector: (row) => (row.user_id !== user.user_id) && 
                          <OverlayTrigger 
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Edit user
                              </Tooltip>
                            }>
                            <a className="neo-icon icon-link icon-link-hover" 
                              onClick={() => props.onEditUserClicked(row)}
                              href='#'>
                              <FiEdit2  size={18} />
                            </a>
                          </OverlayTrigger> ,
      width: '50px'
    },
    {
      name: '',
      selector: (row) => (row.user_id !== user.user_id) && 
                          <OverlayTrigger 
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Reset password
                              </Tooltip>
                            }>
                            <a className="neo-icon icon-link icon-link-hover" 
                              onClick={() => props.onResetUserPasswordClicked(row)}
                              href='#'>
                              <MdOutlineLockReset  size={25} />
                            </a>
                          </OverlayTrigger> ,
      width: '60px'
    },
  ];

  if (user.role === Roles.NeoneurAdmin){
    // Adding facility name to the table
    usersColumns.splice(4, 0, {
      name: 'Facility',
      selector: row => row.facility_name,
      sortable: true,
    });
  }

  const sortedData = props.data.sort((a, b) => {
    // Sort by status first ('active' before 'inactive')
    if (a.status === b.status) {
      // If statuses are the same, sort by name alphabetically
      return a.name.localeCompare(b.name);
    }
    // If statuses are different, sort 'active' before 'inactive'
    return a.status === 'active' ? -1 : 1;
  });

  return (
    <PaginatedTable
      name="Users" 
      icon={<FiUser size={25}/>}
      data={sortedData}
      columns={usersColumns}
      onEditUserClicked={ props.onEditUserClicked }
      onResetUserPasswordClicked={ props.onResetUserPasswordClicked }
      buttonLabel="Add user"
      onButtonClick={props.addEntityClicked}/>
  )
}

export default UsersTable;