import React, { useContext, useEffect, useState } from 'react'
import '../../styles/Neo.css';
import { PatientCard } from '../../components/Cards/PatientCard';
import { useGetPatientList } from '../../components/CustomHooks/useGetPatientList';
import { Loading } from '../../components/Loading/Loading';
import { PaginationPage } from '../../components/PaginationPage/PaginationPage';
import { PatientsPerPage } from '../../utilities/Constants';
import { PatientCardV2 } from '../../components/Cards/PatientCardV2';
import { useGetFavoritePatients } from '../../components/CustomHooks/useGetFavoritePatients';
import { AuthContext } from '../../auth';

export const PatientsForClinicianPage = ({
    search, 
    sort, 
    showChart, 
    showDetails, 
    showSaved, 
    setShowSaved, 
    setPatientGlobalList
}) => {

    const [searchInput, setSearchInput] = useState('');
    const [order, setOrder] = useState('most-recent');

    const { patients, isLoading } = useGetPatientList();

    const [patientList, setPatientList] = useState([]);


    const { user } = useContext(AuthContext);

    const { patients: favoritePatients, refreshFavorites } = useGetFavoritePatients(user.user_id);


    // Pagination items

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPatientsPagination, setTotalPatientsPagination] = useState(0)

    useEffect(() => {
        setSearchInput(search);
        onSearchChange(search);
    }, [search]);

    useEffect(() => {
        onSelectOrderChanged(sort);
    }, [sort]);

    useEffect(() => {
        // filter all the patients by saved
        if (showSaved){
            const savedPatients = patients.filter((patient) => favoritePatients.find((fp) => fp.patient_id === patient.patient_id));
            setPatientList(savedPatients);
            setTotalPatientsPagination(savedPatients.length);
        }
        else{
            resetPagination();
        }

    }, [showSaved]);

    useEffect(() => {
        resetPagination();
        setPatientGlobalList(patients);
    }, [patients, currentPage]);

    const resetPagination = () => {
        setPatientsOrder(order);
        setTotalPatientsPagination(patients.length);
    }


    const onPageNumberClick = (page) => {
        setCurrentPage(page);
    }

    const onSelectOrderChanged = (sort) => {
        setShowSaved(false);
        setSearchInput('');
        setPatientList([]);
        setCurrentPage(1);
        setOrder(sort);
        setPatientsOrder(sort);
    }

    const onSearchChange = (search) => {
        //setSearchInput(target.value)
        setShowSaved(false);
        setSearchInput('');
        setCurrentPage(1);
        const list = patients.filter((patient) => patient.name.includes(search.toUpperCase()) || patient.ehr_patient_ref.includes(search.toUpperCase()))
        setPatientList(list);
        setTotalPatientsPagination(list.length);
    }

    const setPatientsOrder = (order) => {

        const indexOfLastPatient = currentPage * PatientsPerPage;
        const indexOfFirstPatient = indexOfLastPatient - PatientsPerPage;

        if (patients) {
            let list = [];
            if (order === 'id')
                list = patients.sort((a, b) => a.ehr_patient_ref > b.ehr_patient_ref ? 1 : -1);
            else if (order === 'a-z')
                list = patients.sort((a, b) => a.name > b.name ? 1 : -1);
            else if (order === 'z-a')
                list = patients.sort((a, b) => a.name < b.name ? 1 : -1);
            else // most recent
                list = patients.sort((a, b) => a.last_feeding < b.last_feeding ? 1 : -1);
                

            setPatientList(list.slice(indexOfFirstPatient, indexOfLastPatient));
        }
    }

    return (
        <div className='neo_content'>

            <Loading isLoading={isLoading} />


            <div className='row'>
                {
                    // Patient Card
                    patientList && !isLoading && patientList.map((patient) =>

                        <PatientCardV2
                            patient={patient}
                            key={patient.patient_id}
                            showDetails={showDetails}
                            showChart={showChart}
                            user={user}
                            favoritePatients={favoritePatients}
                            refreshFavorites={refreshFavorites} />

                    )
                }
            </div>

            {
                // Pagination
                patientList && !showSaved && totalPatientsPagination > PatientsPerPage && <PaginationPage
                    currentPage={currentPage}
                    totalItems={totalPatientsPagination}
                    itemsPerPage={PatientsPerPage}
                    onPageNumberClick={onPageNumberClick}
                />
            }

        </div>
    )
}
