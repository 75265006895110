import { useState } from "react"
import { LoadingButton } from "../Loading/LoadingButton";
import CalculateBiomarkers from "../../data_layer/repositories/Biomarkers/CalculateBiomarkers";
import { ResultModal } from "../Modals/GenericModals/ResultModal";


export const CalculateBiomarkersButton = (props) => {

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [result, setResult] = useState()

    function onButtonClick(event) {
        event.preventDefault();

        calculateBiomarkers();        
    }

    async function calculateBiomarkers(){
        setLoading(true);
        const response = await CalculateBiomarkers(props.patient_id, props.feedings);
        setResult(response);
        setShowModal(true);
        setLoading(false);
    }

    function hideModal(){
        setShowModal(false);
    }


    return (
        <>
            <ResultModal 
                showModal={showModal}
                isSuccess={result === "SUCCESS"}
                title={ result === "SUCCESS" ? "Biomarkers generated successfully" : "Error generating biomarkers"}
                onHide={ hideModal }/>

            <LoadingButton
                title="Generate biomarkers"
                onClick={onButtonClick}
                loading={loading}
            />
        </>
    )
}
