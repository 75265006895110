import  '../../../styles/Modals.css'
import './UpdateEntityModals.css'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ModalStates } from './UpdateEntityModalStates';

function UpdateEntityModalEditConfirmDataLine(props) {

  function getRowStyle()
  {
    // Show all rows when adding an entity.
    if (props.modalState === ModalStates.ConfirmAdd)
    {
      return 'null';
    }

    // When editing, only show if the value has changed.
    return props.originalParameter === props.updatedParameter ? 'InvisibleCollapse' : 'null';
  }

  return (
    <div className={getRowStyle()}>
      <Row className='AddEntityConfirmContainer'>
        <Col className={props.modalState === ModalStates.ConfirmAdd ? 'InvisibleCollapse': 'null'}>
          <label className="AddEntityModalLabel">{props.label}</label>
        </Col>
        <Col>

          <label className="AddEntityModalLabel">{`NEW ${props.label}`}</label>

        </Col>
      </Row>
      <Row>
        <Col className={props.modalState === ModalStates.ConfirmAdd ? 'InvisibleCollapse': 'null'}>
          <input className='AddEntityConfirmOriginal' defaultValue={props.originalParameter}/>
        </Col>
        <Col>
          <input className='AddEntityConfirmUpdated' defaultValue={props.updatedParameter}/>
        </Col>
      </Row>
    </div>
  );
}

export default UpdateEntityModalEditConfirmDataLine;