import React, { useEffect, useState } from 'react'
import { BiomarkersChart } from '../Charts/BiomarkersChart';
import BiomarkersTable from '../Tables/BiomarkersTable';
import { PatientHeader } from './PatientFullDetailsCardComponents/PatientHeader';


export const BiomarkersCard = ({
    patient,
    biomarkers,
    currentFeedingSelected,
    setCurrentFeedingSelected,
    feedingTimestampsForComparison,
    setFeedingTimestampsForComparison
}) => {

    return (
        <div className="container-fluid mt-2">
            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="w-100 rounded-4 bg-white px-0">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <!-- Header --> */}
                                <PatientHeader
                                    index={0}
                                    feedingTimestampsForComparison={feedingTimestampsForComparison}
                                    setFeedingTimestampsForComparison={setFeedingTimestampsForComparison} />
                                {/* <!-- Content --> */}
                                {
                                    feedingTimestampsForComparison.length === 0 &&

                                    <div className="d-flex border-top pb-3">
                                        <div className="px-4 pt-3 pb-1 w-100 d-flex align-items-stretch">
                                            <div className="chart-biomarkers-box border rounded me-3 overflow-hidden p-1 flex align-items-center justify-content-center">
                                                <BiomarkersChart feedings={biomarkers?.feedings} conceptionDate={patient?.date_of_conception} isSquare={true} />
                                            </div>
                                            <div className="flex-1 border rounded table-biomarkers-box">
                                                <BiomarkersTable data={biomarkers?.feedings} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
