import  '../../../styles/Modals.css'
import './UpdateEntityModals.css'
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { ModalStates } from './UpdateEntityModalStates';
import { GetInputValidationErrorText, IsErrorState } from './InputValidation';

function UpdateEntityModalDataPicker(props) {

  function getDisabled()
  {
    if (props.disabled === true)
      return true;

    switch (props.modalState)
    {
      case ModalStates.Add:
      case ModalStates.Edit:
        return false;
      case ModalStates.ConfirmAdd:
      case ModalStates.ConfirmEdit:
      default:
        return true;
    }
  }


  return (
    <div>
      <Row className='AddEntityModalContainer'>

        <div className='col-form-label col-sm-4'>
          <label className="AddEntityModalLabel">{props.labelName}</label>
          <label className={props.required === true ? 'RequiredStar' : 'InvisibleCollapse'}>*</label>
        </div>
        <div className="col-sm-8">
          <Form.Select className={ IsErrorState(props.errorState) ? 'AddEntityModalSelectError' : 'AddEntityModalSelect'}
              disabled={getDisabled()}
              value={props.value}
              onChange={e => props.inputChanged(e.target.value, props.parameterName)}>
                <option value=''>{ `Select ${ props.labelName.toLowerCase() }` }</option>
                {props.choices.map(option => 
                  <option key={option.value} value={option.value}>{option.name}</option>)}
          </Form.Select>
          <label className={IsErrorState(props.errorState) ? 'InputValidationErrorMessage' : 'InvisibleCollapse'}>
            { GetInputValidationErrorText(props.errorState, props.labelName, props.min, props.max, props.entryMinLength, props.entryMaxLength) }
          </label>
        </div>

      </Row>
    </div>
    
  );
}

export default UpdateEntityModalDataPicker;